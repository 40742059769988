// Import React
import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Import Utils
import PrivateRoute from './components-new/authentication/private-route/PrivateRoute';

// Import Constants
import { LOADING_TYPES } from './common/constants/loading/loadingTypes';

// Import Components
import Loading from './components-new/loading/Loading';
import NotFound from './pages-new/error-pages/not-found/NotFound';
import UserNotFound from './pages-new/error-pages/user-not-found/UserNotFound';
import InvalidAuthorization from './pages-new/error-pages/invalid-authorization/InvalidAuthorization';
import OrganisationNotFound from './pages-new/error-pages/organisation-not-found/OrganisationNotFound';

// Import Routes
import { routes } from './routes/routes';

// Import Ant
import { ConfigProvider } from 'antd';
import trTR from 'antd/lib/locale/tr_TR';
import enUs from 'antd/lib/locale/en_US';

// i18n Import
import i18n from './common/locales/i18n';

// Import Styles
import './assets/styles/App.scss';
import 'antd/dist/antd.css';
import './assets/styles/_global.scss';
import './assets/styles/_base.scss';
// Import Assets
import './assets/styles/homePage.scss';

function App() {
  return (
    <ConfigProvider locale={i18n?.language === 'tr' ? trTR : enUs}>
      <Suspense fallback={<Loading loading={true} loadingType={LOADING_TYPES.FULLSCREEN} />}>
        <Switch>
          {/* Page url's */}
          {routes?.map((route, index) => (
            <PrivateRoute
              key={`route_${index}`}
              exact
              path={route?.path}
              hasProfile={route?.hasProfile}
              hasRight={route?.hasRight}
              redirectUrl={route?.redirectUrl}
              component={route?.component}
            />
          ))}
          {/* Sistemin health check urldir */}
          <Route exact path="/hc">
            <div>OK</div>
          </Route>
          {/* Sistemin notfound urldir */}
          <Route path="/kullanici-bulunamadi">
            <UserNotFound />
          </Route>
          {/* Sistemin yetkisiz kullanıcı urldir */}
          <Route path="/yetkisiz-kullanici">
            <InvalidAuthorization />
          </Route>
          {/* Sistemin gerçersiz organizasyonlu kullanıcı urldir */}
          <Route path="/gecersiz-organizasyon">
            <OrganisationNotFound />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>{' '}
    </ConfigProvider>
  );
}
export default withTranslation()(App);
