import * as types from 'src/store/actions/types';
import { SERVICES } from 'src/store/constants/serviceConstants';
import { api } from 'src/store/actions/api';

export const announcementList = (values?, pageNumber?) => (dispatch) => {
  const body = {
    ...values,
    sortBy: 'id',
    sortDirection: 'DESC',
    pageNumber: pageNumber
  };

  dispatch(
    api('post', SERVICES.NOTIFIER_ADMIN_SERVICE, `/notifier/admin/announcement/filter`, body, types.ANNOUNCEMENT_LIST)
  );
};
