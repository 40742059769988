import authUser from 'src/store/reducers/auth';
import globalLoading from 'src/store/reducers/globalLoading';
import contractList from 'src/store/reducers/contractList';
import createBatchOrder from 'src/store/reducers/createBatchOrder';
import getPositionLimit from 'src/store/reducers/getPositionLimit';
import globalReducer from 'src/store/reducers/globalReducer';
import indicatorPriceSimulate from 'src/store/reducers/indicatorPriceSimulate';
import marketPositionLimit from 'src/store/reducers/marketPositionLimit';
import matchSearch from 'src/store/reducers/matchSearch';
import objectionSuitableMatching from 'src/store/reducers/objectionSuitableMatching';
import orderSearch from 'src/store/reducers/orderSearch';
import getIsVisibleOrderPopUp from 'src/store/reducers/getIsVisibleOrderPopUp';
import getSettlementDetailFinanceResultFiltered from 'src/store/reducers/getSettlementDetailFinanceResultFiltered';
import getSettlementGDDKResultFiltered from 'src/store/reducers/getSettlementGDDKResultFiltered';
import getSettlementLinesResultFiltered from 'src/store/reducers/getSettlementLinesResultFiltered';
import getSettlementNoticeResultFiltered from 'src/store/reducers/getSettlementNoticeResultFiltered';
import getVersion from 'src/store/reducers/getVersion';
import getCurrentDateReference from 'src/store/reducers/getCurrentDateReference';
import dailyParametersList from 'src/store/reducers/dailyParametersList';
import dailyParameterUpdate from 'src/store/reducers/dailyParameterUpdate';
import globalParametersList from 'src/store/reducers/globalParametersList';
import globalParameterUpdate from 'src/store/reducers/globalParameterUpdate';

const reducers = {
  authUser,
  globalLoading,
  contractList,
  createBatchOrder,
  getPositionLimit,
  globalReducer,
  indicatorPriceSimulate,
  marketPositionLimit,
  matchSearch,
  objectionSuitableMatching,
  orderSearch,
  getIsVisibleOrderPopUp,
  getSettlementDetailFinanceResultFiltered,
  getSettlementGDDKResultFiltered,
  getSettlementLinesResultFiltered,
  getSettlementNoticeResultFiltered,
  getVersion,
  getCurrentDateReference,
  dailyParametersList,
  dailyParameterUpdate,
  globalParametersList,
  globalParameterUpdate
};

export default reducers;
