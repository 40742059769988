// export const AUTH = 'AUTH';
export const TABLE_DATA = 'TABLE_DATA';
export const CONTRACT_LIST = 'CONTRACT_LIST';
export const CONTRACT_LIST_LOADING = 'CONTRACT_LIST_LOADING';
export const CONTRACT_LIST_SUCCESS = 'CONTRACT_LIST_SUCCESS';
export const CONTRACT_LIST_UPDATE = 'CONTRACT_LIST_UPDATE';
export const CONTRACT_LIST_FAIL = 'CONTRACT_LIST_FAIL';
export const SESSION_CHANGE_UPDATE = 'SESSION_CHANGE_UPDATE';
export const CONTRACT_LIST_BY_SELECTION = 'CONTRACT_LIST_BY_SELECTION';
export const CONTRACT_LIST_BY_SELECTION_LOADING = 'CONTRACT_LIST_BY_SELECTION_LOADING';
export const CONTRACT_LIST_BY_SELECTION_SUCCESS = 'CONTRACT_LIST_BY_SELECTION_SUCCESS';
export const CONTRACT_LIST_BY_SELECTION_FAIL = 'CONTRACT_LIST_BY_SELECTION_FAIL';
export const OBJECTION_SUCCESS_MY_MATCHING_UPDATE = 'OBJECTION_SUCCESS_MY_MATCHING_UPDATE';
export const PERIOD_QUERY = 'PERIOD_QUERY';
export const GET_PERIOD_QUERY = 'GET_PERIOD_QUERY';
export const GET_DETAIL = 'GET_DETAIL';
export const ORDER_VERSION = 'ORDER_VERSION';
export const ORDER_CREATE = 'ORDER_CREATE';
export const ORDER_CREATE_TIME_SELECT = 'ORDER_CREATE_TIME_SELECT';
export const ORDER_CREATE_OFFERS_SELECT = 'ORDER_CREATE_OFFERS_SELECT';
export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_PERIOD = 'GET_PERIOD';
export const SETTLEMENT_AND_BILLING_GET_BILLING_QUERY = 'SETTLEMENT_AND_BILLING_GET_BILLING_QUERY';
export const SETTLEMENT_AND_BILLING_GET_CALCULATE_QUERY = 'SETTLEMENT_AND_BILLING_GET_CALCULATE_QUERY';
export const SETTLEMENT_AND_BILLING_GET_OPEN_PARTICIPANT_QUERY = 'SETTLEMENT_AND_BILLING_GET_OPEN_PARTICIPANT_QUERY';
export const SETTLEMENT_AND_BILLING_GET_LIST_VERSIONS_QUERY = 'SETTLEMENT_AND_BILLING_GET_LIST_VERSIONS_QUERY';
export const SETTLEMENT_AND_BILLING_PUT_FINANCE_CONTROL_QUERY = 'SETTLEMENT_AND_BILLING_PUT_FINANCE_CONTROL_QUERY';
export const SETTLEMENT_AND_BILLING_PUT_FINALIZATION = 'SETTLEMENT_AND_BILLING_PUT_FINALIZATION';
export const NOTICE_CONCILIATION_PAGE_GET_ORGANISATION_SELECTBOX =
  'NOTICE_CONCILIATION_PAGE_GET_ORGANISATION_SELECTBOX';
export const NOTICE_CONCILIATION_PAGE_GET_DETAILS_SELECTBOX = 'NOTICE_CONCILIATION_PAGE_GET_DETAILS_SELECTBOX';
export const NOTICE_CONCILIATION_PAGE_GET_GDDK_DETAILS = 'NOTICE_CONCILIATION_PAGE_GET_GDDK_DETAILS';
export const NOTICE_CONCILIATION_PAGE_GET_PIU_GDDK_DETAILS = 'NOTICE_CONCILIATION_PAGE_GET_PIU_GDDK_DETAILS';
export const GET_FILTER_CONTRACT_TYPE_COMBO = 'GET_FILTER_CONTRACT_TYPE_COMBO';
export const GET_FILTER_CONTRACT_TYPE_ALL_COMBO = 'GET_FILTER_CONTRACT_TYPE_ALL_COMBO';
export const GET_FILTER_CONTRACT_TYPE = 'GET_FILTER_CONTRACT_TYPE';
export const GET_MARKET_SUMMARY = 'GET_MARKET_SUMMARY';
export const GET_SUMMARY_MARKET = 'GET_SUMMARY_MARKET';
export const GET_ORGANIZATION_SUMMARY = 'GET_ORGANIZATION_SUMMARY';
export const NOTICE_CONCILIATION_PAGE_GET_GDDK_HISTORY = 'NOTICE_CONCILIATION_PAGE_GET_GDDK_HISTORY';
export const NOTICE_CONCILIATION_PAGE_GET_GDDK_REJECTED = 'NOTICE_CONCILIATION_PAGE_GET_GDDK_REJECTED';
export const NOTICE_CONCILIATION_PAGE_GET_GDDK_APPROVED = 'NOTICE_CONCILIATION_PAGE_GET_GDDK_APPROVED';
export const NOTICE_CONCILIATION_PAGE_GET_GDDK_CREATE = 'NOTICE_CONCILIATION_PAGE_GET_GDDK_CREATE';
export const NOTICE_CONCILIATION_PAGE_GET_GDDK_UPDATE = 'NOTICE_CONCILIATION_PAGE_GET_GDDK_UPDATE';
export const MATCH_DETAIL = 'MATCH_DETAIL';
export const FINANCE_CONTROL_PAGE_GET_DETAILS = 'FINANCE_CONTROL_PAGE_GET_DETAILS';
export const FINANCE_CONTROL_PAGE_APPROVE = 'FINANCE_CONTROL_PAGE_APPROVE';
export const FINANCE_CONTROL_PAGE_INQUIRY = 'FINANCE_CONTROL_PAGE_INQUIRY';
export const FINANCE_CONTROL_PAGE_PROVIDING = 'FINANCE_CONTROL_PAGE_PROVIDING';
export const FINANCE_CONTROL_PAGE_REJECT = 'FINANCE_CONTROL_PAGE_REJECT';
export const TAKASBANK_NOTIFICATION_PAGE_ACTION_HISTORY = 'TAKASBANK_NOTIFICATION_PAGE_ACTION_HISTORY';
export const TAKASBANK_NOTIFICATION_PAGE_ACTION_SEND_NOTIFICATION =
  'TAKASBANK_NOTIFICATION_PAGE_ACTION_SEND_NOTIFICATION';
export const TAKASBANK_NOTIFICATION_PAGE_ACTION_APPROVE_NOTIFICATION =
  'TAKASBANK_NOTIFICATION_PAGE_ACTION_APPROVE_NOTIFICATION';
export const COLLETERAL_DETAIL = 'COLLETERAL_DETAIL';
export const COLLETERAL_SIMULATION = 'COLLETERAL_SIMULATION';
export const COLLETERAL_POSITIONS = 'COLLETERAL_POSITIONS';
export const COLLETERAL_POSITION_DETAIL = 'COLLETERAL_POSITION_DETAIL';
export const GET_FILTER_ORGANIZATION_LIST = 'GET_FILTER_ORGANIZATION_LIST';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_ACTIVE = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_ACTIVE';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_PASSIVE = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_PASSIVE';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_UPDATE = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_UPDATE';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DEFAULTER = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DEFAULTER';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_CALCULATED = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_CALCULATED';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_SAVE = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_SAVE';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_DELETE_TGH = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_DELETE_TGH';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DETAIL_DEFAULTER =
  'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DETAIL_DEFAULTER';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DETAIL_CALCULATE =
  'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DETAIL_CALCULATE';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_REFUND = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_REFUND';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_ACTIVE_REFUND =
  'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_ACTIVE_REFUND';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_PASSIVE_REFUND =
  'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_PASSIVE_REFUND';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DEFAULTER_REFUND =
  'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DEFAULTER_REFUND';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_CALCULATED_REFUND =
  'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_CALCULATED_REFUND';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_SAVE_REFUND =
  'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_SAVE_REFUND';
export const WITHDRAW_FROM_TGH_ACCOUNT_PAGE_DELETE_TGH_REFUND = 'WITHDRAW_FROM_TGH_ACCOUNT_PAGE_DELETE_TGH_REFUND';
export const VIEW_INVOICE_PAGE_GET_INVOICE = 'VIEW_INVOICE_PAGE_GET_INVOICE';
export const TRANSACTION_LOGS = 'TRANSACTION_LOGS';
export const MANUEL_CONTROL = 'MANUEL_CONTROL';
export const COLLATERAL_SUMMARY = 'COLLATERAL_SUMMARY';
export const COLLATERAL_DETAIL = 'COLLATERAL_DETAIL';
export const TGH_DETAIL = 'TGH_DETAIL';
export const COLLATERAL_DETAIL_INDEFAULTENTITY = 'COLLATERAL_DETAIL_INDEFAULTENTITY';
export const COLLATERAL_MAIN_DETAIL = 'COLLATERAL_MAIN_DETAIL';
export const SITUATION_FOLLOW = 'SITUATION_FOLLOW';
export const NOTIFICATION_CONFIGURATION = 'NOTIFICATION_CONFIGURATION';
export const NOTIFICATION_CONFIGURATION_DETAIL = 'NOTIFICATION_CONFIGURATION_DETAIL';
export const NOTIFICATION_CONFIGURATION_UPDATE = 'NOTIFICATION_CONFIGURATION_UPDATE';
export const NOTIFICATION_CONFIGURATION_PARAMETERS = 'NOTIFICATION_CONFIGURATION_PARAMETERS';
export const ANNOUNCEMENT_LIST = 'ANNOUNCEMENT_LIST';
export const ANNOUNCEMENT_HEADER_LIST = 'ANNOUNCEMENT_HEADER_LIST';
export const ANNOUNCEMENT_CREATE = 'ANNOUNCEMENT_CREATE';
export const ANNOUNCEMENT_UPDATE = 'ANNOUNCEMENT_UPDATE';
export const ANNOUNCEMENT_READ_UPDATE = 'ANNOUNCEMENT_READ_UPDATE';
export const ANNOUNCEMENT_DELETE = 'ANNOUNCEMENT_DELETE';
export const GET_TEXT_ANNOUNCEMENT_LENGTH = 'GET_TEXT_ANNOUNCEMENT_LENGTH';
export const OBJECTION = 'OBJECTION';
export const GET_INDEFAULTENTITYLIST = 'GET_INDEFAULTENTITYLIST';
export const GET_INDEFAULTENTITYOFFSET = 'GET_INDEFAULTENTITYOFFSET';
export const GET_INDEFAULTENTITY_MANAGEMENT_MATCH = 'GET_INDEFAULTENTITY_MANAGEMENT_MATCH';
export const GET_TGH_INFORMATION_LIST = 'GET_TGH_INFORMATION_LIST';
export const EP_SEARCH = 'EP_SEARCH';
export const PK_SEARCH = 'PK_SEARCH';
export const OBJECTION_APPROVED = 'OBJECTION_APPROVED';
export const OBJECTION_PRE_APPROVED = 'OBJECTION_PRE_APPROVED';
export const OBJECTION_REJECTED = 'OBJECTION_REJECTED';
export const OBJECTION_PRE_REJECTED = 'OBJECTION_PRE_REJECTED';
export const OBJECTION_SUITABLE_MATCHING = 'OBJECTION_SUITABLE_MATCHING';
export const INDICATOR_PRICE_SEARCH = 'INDICATOR_PRICE_SEARCH';
export const INDICATOR_PRICE_CORRECTION = 'INDICATOR_PRICE_CORRECTION';
export const IMPORT_INDICATOR_PRICE = 'IMPORT_INDICATOR_PRICE';
export const MARKET_POSITION_LIMIT = 'MARKET_POSITION_LIMIT';
export const GET_POSITION_LIMIT = 'GET_POSITION_LIMIT';
export const APPLY_POSITION_LIMIT = 'APPLY_POSITION_LIMIT';
export const CALCULATE_POSITION_LIMIT = 'CALCULATE_POSITION_LIMIT';
export const LIMIT_REQUEST_GET_SIMULATE = 'LIMIT_REQUEST_GET_SIMULATE';
export const IMPORT_ORGANIZATION = 'IMPORT_ORGANIZATION';
export const INDEFAULTENTITY_GET_ORGANISTION = 'INDEFAULTENTITY_GET_ORGANISTION';
export const GET_INDEFAULTENTITYOFFSET_SIMULATION = 'GET_INDEFAULTENTITYOFFSET_SIMULATION';
export const LIMIT_REQUEST_AVAILABLE = 'LIMIT_REQUEST_AVAILABLE';
export const ORGANIZATION_TOTAL_LIMIT = 'ORGANIZATION_TOTAL_LIMIT';
export const LIMIT_REQUEST_SIMULATE = 'LIMIT_REQUEST_SIMULATE';
export const LIMIT_REQUEST = 'LIMIT_REQUEST';
export const ORGANIZATION_POZITION_LIMIT_REQUEST = 'ORGANIZATION_POZITION_LIMIT_REQUEST';
export const PAID_TGH = 'PAID_TGH';
export const PARTICIPANT_POSITION_LIMIT_REQUEST = 'PARTICIPANT_POSITION_LIMIT_REQUEST';
export const LIMIT_PREAPPROVED = 'LIMIT_PREAPPROVED';
export const LIMIT_PREREJECTED = 'LIMIT_PREREJECTED';
export const LIMIT_APPROVED = 'LIMIT_APPROVED';
export const LIMIT_REJECTED = 'LIMIT_REJECTED';
export const SEND_ORGANISATION_NOTIFICATION = 'SEND_ORGANISATION_NOTIFICATION';
export const SEND_TAKASBANK_NOTIFICATION = 'SEND_TAKASBANK_NOTIFICATION';
export const ORDER_UPDATE = 'ORDER_UPDATE';
export const GET_SELECTION_ORDER_CREATE = 'GET_SELECTION_ORDER_CREATE';
export const ORDER_INFO = 'ORDER_INFO';
export const BASE_PRICE_LIST = 'BASE_PRICE_LIST';
export const BASE_PRICE_DETAIL = 'BASE_PRICE_DETAIL';
export const BASE_PRICE_SIMULATOR = 'BASE_PRICE_SIMULATOR';
export const BASE_PRICE_SIMULATOR_DATES = 'BASE_PRICE_SIMULATOR_DATES';
export const ORDER_SEARCH_FILTER = 'ORDER_SEARCH_FILTER';
export const ORDER_CANCEL = 'ORDER_CANCEL';
export const PROCESS_FLOW = 'PROCESS_FLOW';
export const GET_LIMIT_USAGE = 'GET_LIMIT_USAGE';
export const GET_ORG_LIMIT_USAGE = 'GET_ORG_LIMIT_USAGE';
export const GET_ORG_ORDER_AND_MATCHING = 'GET_ORG_ORDER_AND_MATCHING';
export const GET_ORG_LIMIT_USAGE_DETAIL = 'GET_ORG_LIMIT_USAGE_DETAIL';
export const REPORT_DEFAULT_CURRENT = 'REPORT_DEFAULT_CURRENT';
export const REPORT_DEFAULT_DETAIL = 'REPORT_DEFAULT_DETAIL';
export const ORDER_UPDATE_ALL = 'ORDER_UPDATE_ALL';
export const REPORT_BILLING_ORGANISATION = 'REPORT_BILLING_ORGANISATION';
export const REPORT_COMPLETED_PERIODS = 'REPORT_COMPLETED_PERIODS';
export const REPORT_INVOICE_GUARANTEE = 'REPORT_INVOICE_GUARANTEE';
export const MARKET_CONCILATION_SUMMARY = 'MARKET_CONCILATION_SUMMARY';
export const MARKET_DATA_PARAMS = 'MARKET_DATA_PARAMS';
export const GET_INDEFAULTENTITY_VGP_LIST = 'GET_INDEFAULTENTITY_VGP_LIST';
export const POST_INDEFAULTENTITY_VGP_ORDER_APPLY = 'POST_INDEFAULTENTITY_VGP_ORDER_APPLY';
export const POST_INDEFAULTENTITY_VGP_ORDER_SIMULATE = 'POST_INDEFAULTENTITY_VGP_ORDER_SIMULATE';
export const MARKET_DATA = 'MARKET_DATA';
export const PROCESS_FLOW_FILTER = 'PROCESS_FLOW_FILTER';
export const MD_CONTRACT_LIST = 'MD_CONTRACT_LIST';
export const MD_CONTRACT_UPDATE = 'MD_CONTRACT_UPDATE';
export const OP_CONTRACT_LIST = 'OP_CONTRACT_LIST';
export const OP_CONTRACT_UPDATE = 'OP_CONTRACT_UPDATE';
export const GET_BANK_HOLIDAY = 'GET_BANK_HOLIDAY';
export const PUT_BANK_HOLIDAY = 'PUT_BANK_HOLIDAY';
export const CONTRACT_CHART = 'CONTRACT_CHART';
export const OPEN_SESSION = 'OPEN_SESSION';
export const SUSPEND_SESSION = 'SUSPEND_SESSION';
export const GET_SCHEDULED_TASK_LIST = 'GET_SCHEDULED_TASK_LIST';
export const RUN_GROUP_SCHEDULED_TASK = 'RUN_GROUP_SCHEDULED_TASK';
export const RUN_SCHEDULED_TASK = 'RUN_SCHEDULED_TASK';
export const GET_TGH_INFORMATION = 'GET_TGH_INFORMATION';
export const POST_TGH_CALL = 'POST_TGH_CALL';
export const GET_DEFAULT_TGH = 'GET_DEFAULT_TGH';
export const IMPORT_BATCH_ORDER = 'IMPORT_BATCH_ORDER';
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const NOTIFICATION_READ = 'NOTIFICATION_READ';
export const NOTICE_CONCILATION_REJECT = 'NOTICE_CONCILATION_REJECT';
export const EVENT_HISTORY = 'EVENT_HISTORY';
export const GET_SESSION_STATUS = 'GET_SESSION_STATUS';
export const GET_VERSION = 'GET_VERSION';
export const GET_INVOICE_DATE = 'GET_INVOICE_DATE';
export const LIST_OP_CONTRACT = 'LIST_OP_CONTRACT';
export const MATCHING_CANCEL = 'MATCHING_CANCEL';
export const MATCH_SEARCH_FILTER = 'MATCH_SEARCH_FILTER';
export const TRANSACTION_HISTORY = 'TRANSACTION_HISTORY';
export const TRANSACTION_HISTORY_ORGANISATION_LIST = 'TRANSACTION_HISTORY_ORGANISATION_LIST';
export const TRANSACTION_HISTORY_PAGE_CHANGE = 'TRANSACTION_HISTORY_PAGE_CHANGE';
export const TRANSACTION_HISTORY_GET_MODULES = 'TRANSACTION_HISTORY_GET_MODULES';
export const TRANSACTION_HISTORY_GET_PAGES = 'TRANSACTION_HISTORY_GET_PAGES';
export const TRANSACTION_HISTORY_TYPE = 'TRANSACTION_HISTORY_TYPE';
export const TRANSACTION_HISTORY_USER_TYPE = 'TRANSACTION_HISTORY_USER_TYPE';
export const TRANSACTION_HISTORY_TRANSACTION_TYPE = 'TRANSACTION_HISTORY_TRANSACTION_TYPE';
export const TRANSACTION_HISTORY_PROCESS_TYPE = 'TRANSACTION_HISTORY_PROCESS_TYPE';
export const GET_CONTRACT_FOR_MODAL = 'GET_CONTRACT_FOR_MODAL';
export const GET_GDDK_PERIODS = 'GET_GDDK_PERIODS';
export const GET_ACTIVE_DEFAULTERS = 'GET_ACTIVE_DEFAULTERS';
export const GET_TGH_REFUND_DETAIL = 'GET_TGH_REFUND_DETAIL';
export const GET_REPORT_PERIOD_SELECT = 'GET_REPORT_PERIOD_SELECT';
export const GET_SETTLEMENT_NOTICE_RESULT_FILTERED = 'GET_SETTLEMENT_NOTICE_RESULT_FILTERED';
export const GET_SETTLEMENT_GDDK_RESULT_FILTERED = 'GET_SETTLEMENT_GDDK_RESULT_FILTERED';
export const GET_SETTLEMENT_LINES_RESULT_FILTERED = 'GET_SETTLEMENT_LINES_RESULT_FILTERED';
export const GET_SETTLEMENT_DETAIL_FINANCE_RESULT_FILTERED = 'GET_SETTLEMENT_DETAIL_FINANCE_RESULT_FILTERED';
export const GET_ISVISIBLE_ORDER_POPUP = 'GET_ISVISIBLE_ORDER_POPUP';
export const GET_TEXT_LENGTH = 'GET_TEXT_LENGTH';
export const GET_TRANSMISSION_LIST_PERIOD = 'GET_TRANSMISSION_LIST_PERIOD';
export const GET_TRANSMISSION_DAILY_LONG = 'GET_TRANSMISSION_DAILY_LONG';
export const GET_TRANSMISSION_DAILY_SHORT = 'GET_TRANSMISSION_DAILY_SHORT';
export const GET_INDEFAULTY_LIST = 'GET_INDEFAULTY_LIST';
export const UPDATE_INDEFAULTY_LIST = 'UPDATE_INDEFAULTY_LIST';
export const GET_SYSTEM_HOURS = 'GET_SYSTEM_HOURS';
export const GET_CURRENT_DATE_REFERENCE = 'GET_CURRENT_DATE_REFERENCE';
export const SET_CURRENT_DATE_REFERENCE = 'SET_CURRENT_DATE_REFERENCE';
export const RESET_CURRENT_DATE = 'RESET_CURRENT_DATE';
export const UPDATE_TASK = 'UPDATE_TASK';
export const BASE_PRICE_LIST_IMPORT = 'BASE_PRICE_LIST_IMPORT';
export const IMPORT_MARKET_POSITION_LIMIT = 'IMPORT_MARKET_POSITION_LIMIT';
export const BASE_PRICE_DETAIL_PERIOD = 'BASE_PRICE_DETAIL_PERIOD';
export const PHYSICAL_COLLATERAL_DETAIL = 'PHYSICAL_COLLATERAL_DETAIL';

/* TEKRAR BAKILACAK */
export const GET_CURRENT_DATE_REFERENCE_LOADING = 'GET_CURRENT_DATE_REFERENCE_LOADING';
export const GET_CURRENT_DATE_REFERENCE_SUCCESS = 'GET_CURRENT_DATE_REFERENCE_SUCCESS';
export const GET_CURRENT_DATE_REFERENCE_FAIL = 'GET_CURRENT_DATE_REFERENCE_FAIL';

export const RESET_CURRENT_DATE_LOADING = 'RESET_CURRENT_DATE_LOADING';
export const RESET_CURRENT_DATE_SUCCESS = 'RESET_CURRENT_DATE_SUCCESS';
export const RESET_CURRENT_DATE_FAIL = 'RESET_CURRENT_DATE_FAIL';

export const SET_CURRENT_DATE_REFERENCE_LOADING = 'SET_CURRENT_DATE_REFERENCE_LOADING';
export const SET_CURRENT_DATE_REFERENCE_SUCCESS = 'SET_CURRENT_DATE_REFERENCE_SUCCESS';
export const SET_CURRENT_DATE_REFERENCE_FAIL = 'SET_CURRENT_DATE_REFERENCE_FAIL';

export const DAILY_PARAMETER_LIST_LOADING = 'DAILY_PARAMETER_LIST_LOADING';
export const DAILY_PARAMETER_LIST_SUCCESS = 'DAILY_PARAMETER_LIST_SUCCESS';
export const DAILY_PARAMETER_LIST_FAIL = 'DAILY_PARAMETER_LIST_FAIL';

export const DAILY_PARAMETER_UPDATE_LOADING = 'DAILY_PARAMETER_UPDATE_LOADING';
export const DAILY_PARAMETER_UPDATE_SUCCESS = 'DAILY_PARAMETER_UPDATE_SUCCESS';
export const DAILY_PARAMETER_UPDATE_FAIL = 'DAILY_PARAMETER_UPDATE_FAIL';

export const GLOBAL_PARAMETER_LIST_LOADING = 'GLOBAL_PARAMETER_LIST_LOADING';
export const GLOBAL_PARAMETER_LIST_SUCCESS = 'GLOBAL_PARAMETER_LIST_SUCCESS';
export const GLOBAL_PARAMETER_LIST_FAIL = 'GLOBAL_PARAMETER_LIST_FAIL';

export const GLOBAL_PARAMETER_UPDATE_LOADING = 'GLOBAL_PARAMETER_UPDATE_LOADING';
export const GLOBAL_PARAMETER_UPDATE_SUCCESS = 'GLOBAL_PARAMETER_UPDATE_SUCCESS';
export const GLOBAL_PARAMETER_UPDATE_FAIL = 'GLOBAL_PARAMETER_UPDATE_FAIL';

export const INDICATOR_PRICE_SIMULATE_LOADING = 'INDICATOR_PRICE_SIMULATE_LOADING';
export const INDICATOR_PRICE_SIMULATE_SUCCESS = 'INDICATOR_PRICE_SIMULATE_SUCCESS';
export const INDICATOR_PRICE_CORRECTION_SUCCESS = 'INDICATOR_PRICE_CORRECTION_SUCCESS';
export const INDICATOR_PRICE_SIMULATE_FAIL = 'INDICATOR_PRICE_SIMULATE_FAIL';

export const MATCH_SEARCH_LOADING = 'MATCH_SEARCH_LOADING';
export const MATCH_SEARCH_SUCCESS = 'MATCH_SEARCH_SUCCESS';
export const MATCH_SEARCH_FAIL = 'MATCH_SEARCH_FAIL';
export const MATCH_SEARCH_UPDATE = 'MATCH_SEARCH_UPDATE';
export const OBJECTION_SUCCESS_ORDER_AND_MATCHING_UPDATE = 'OBJECTION_SUCCESS_ORDER_AND_MATCHING_UPDATE';

export const OBJECTION_SUITABLE_MATCHING_LOADING = 'OBJECTION_SUITABLE_MATCHING_LOADING';
export const OBJECTION_SUITABLE_MATCHING_SUCCESS = 'OBJECTION_SUITABLE_MATCHING_SUCCESS';
export const OBJECTION_SUCCESS_OBJECTION_UPDATE = 'OBJECTION_SUCCESS_OBJECTION_UPDATE';
export const OBJECTION_SUITABLE_MATCHING_FAIL = 'OBJECTION_SUITABLE_MATCHING_FAIL';

export const CREATE_BATCH_ORDER_LOADING = 'CREATE_BATCH_ORDER_LOADING';
export const CREATE_BATCH_ORDER_SUCCESS = 'CREATE_BATCH_ORDER_SUCCESS';
export const CREATE_BATCH_ORDER_UPDATE_BATCH = 'CREATE_BATCH_ORDER_UPDATE_BATCH';
export const CREATE_BATCH_ORDER_FAIL = 'CREATE_BATCH_ORDER_FAIL';

export const ORDER_SEARCH_LOADING = 'ORDER_SEARCH_LOADING';
export const ORDER_SEARCH_SUCCESS = 'ORDER_SEARCH_SUCCESS';
export const ORDER_SEARCH_UPDATE = 'ORDER_SEARCH_UPDATE';
export const ORDER_SEARCH_FAIL = 'ORDER_SEARCH_FAIL';

export const GET_POSITION_LIMIT_LOADING = 'GET_POSITION_LIMIT_LOADING';
export const GET_POSITION_LIMIT_SUCCESS = 'GET_POSITION_LIMIT_SUCCESS';
export const GET_POSITION_LIMIT_FAIL = 'GET_POSITION_LIMIT_FAIL';
export const APPLY_POSITION_LIMIT_FAIL = 'APPLY_POSITION_LIMIT_FAIL';

export const MARKET_POSITION_LIMIT_LOADING = 'MARKET_POSITION_LIMIT_LOADING';
export const MARKET_POSITION_LIMIT_SUCCESS = 'MARKET_POSITION_LIMIT_SUCCESS';
export const MARKET_POSITION_LIMIT_FAIL = 'MARKET_POSITION_LIMIT_FAIL';
export const IMPORT_EPDK_FAIL = 'IMPORT_EPDK_FAIL';

export const GET_ISVISIBLE_ORDER_POPUP_SUCCESS = 'GET_ISVISIBLE_ORDER_POPUP_SUCCESS';
export const GET_ISVISIBLE_ORDER_POPUP_LOADING = 'GET_ISVISIBLE_ORDER_POPUP_LOADING';
export const GET_ISVISIBLE_ORDER_POPUP_FAIL = 'GET_ISVISIBLE_ORDER_POPUP_FAIL';

export const GET_SETTLEMENT_DETAIL_FINANCE_RESULT_FILTERED_LOADING =
  'GET_SETTLEMENT_DETAIL_FINANCE_RESULT_FILTERED_LOADING';
export const GET_SETTLEMENT_DETAIL_FINANCE_RESULT_FILTERED_SUCCESS =
  'GET_SETTLEMENT_DETAIL_FINANCE_RESULT_FILTERED_SUCCESS';
export const GET_SETTLEMENT_DETAIL_FINANCE_RESULT_FILTERED_FAIL = 'GET_SETTLEMENT_DETAIL_FINANCE_RESULT_FILTERED_FAIL';

export const GET_SETTLEMENT_GDDK_RESULT_FILTERED_LOADING = 'GET_SETTLEMENT_GDDK_RESULT_FILTERED_LOADING';
export const GET_SETTLEMENT_GDDK_RESULT_FILTERED_SUCCESS = 'GET_SETTLEMENT_GDDK_RESULT_FILTERED_SUCCESS';
export const GET_SETTLEMENT_GDDK_RESULT_FILTERED_FAIL = 'GET_SETTLEMENT_GDDK_RESULT_FILTERED_FAIL';

export const GET_SETTLEMENT_LINES_RESULT_FILTERED_LOADING = 'GET_SETTLEMENT_LINES_RESULT_FILTERED_LOADING';
export const GET_SETTLEMENT_LINES_RESULT_FILTERED_SUCCESS = 'GET_SETTLEMENT_LINES_RESULT_FILTERED_SUCCESS';
export const GET_SETTLEMENT_LINES_RESULT_FILTERED_FAIL = 'GET_SETTLEMENT_LINES_RESULT_FILTERED_FAIL';

export const GET_SETTLEMENT_NOTICE_RESULT_FILTERED_LOADING = 'GET_SETTLEMENT_NOTICE_RESULT_FILTERED_LOADING';
export const GET_SETTLEMENT_NOTICE_RESULT_FILTERED_SUCCESS = 'GET_SETTLEMENT_NOTICE_RESULT_FILTERED_SUCCESS';
export const GET_SETTLEMENT_NOTICE_RESULT_FILTERED_FAIL = 'GET_SETTLEMENT_NOTICE_RESULT_FILTERED_FAIL';

export const GET_VERSION_LOADING = 'GET_VERSION_LOADING';
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
export const GET_VERSION_FAIL = 'GET_VERSION_FAIL';

export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const GLOBAL_LOADING_ACTIVE = 'GLOBAL_LOADING_ACTIVE';
export const GLOBAL_LOADING_PASSIVE = 'GLOBAL_LOADING_PASSIVE';

export const SETTLEMENT_AND_BILLING_GET_CONCILATION_DAILY = 'SETTLEMENT_AND_BILLING_GET_CONCILATION_DAILY';
