// Import moment.js
import moment from 'moment';

// Import Constants
import {
  dateFormat,
  dateFormatBackend,
  dateTimeFormat,
  dateTimeFormatBackend,
  dateTimeSecondFormatFileName,
  monthYearFormat,
  monthFormatToBackend,
  dateTimeSecondFormat,
  dateTimeMiliSecondFormatBackend,
  dateTimeSecondMiliFormat,
  dateHour,
  timeFormat
} from 'src/common/constants/date/dateFormats';

// GetDays
export const getYesterday = () => moment().subtract(1, 'days');
export const getToday = () => moment();
export const getTomorrow = () => moment().add(1, 'days');
export const getTodayFileName = () => moment().format(dateTimeSecondFormatFileName);
export const getSubtractDays = (count) => moment().subtract(count, 'days');
export const getStartOfDay = () => moment().startOf('day');
export const getEndOfDay = () => moment().endOf('day');

// Get Now Moments
export const getNowHour = () => moment().hour();
export const getNowMinutes = () => moment().minute();

// Get Specific Moments
export const getDate = (date) => moment(date);
export const getMinute = (date) => moment(date).minute();
export const getHours = (date) => moment(date).hours();

// Add Date
export const addDay = (count) => moment().add(count, 'days');
export const addMonth = (count) => moment().add(count, 'month');
export const addYear = (count) => moment().add(count, 'years');

// Formatted to Dates
export const convertToMoment = (date, format?) => moment(date, format || dateFormat);
export const convertToMomentWithoutFormat = (date) => moment(date);
export const getFormattedDate = (date, format?) => moment(date).format(format || dateFormat);
export const getFormattedDateTime = (date, format?) => moment(date).format(format || dateTimeFormat);
export const getFormattedHour = (date, format?) => moment(date).format(format || dateHour);
export const getFormattedMonth = (date, format?) => moment(date).format(format || monthYearFormat);

// To Backend Formatters
export const dateToBackend = (date) => moment(date).format(dateFormatBackend);
export const dateTimeToBackend = (date) => moment(date).format(dateTimeFormatBackend);
export const dateTimeMiliSecondToBackend = (date) => moment(date).format(dateTimeMiliSecondFormatBackend);
export const monthToBackend = (date) => moment(date).format(monthFormatToBackend);

// Stamp
export const stampToString = (date, format?) => moment(date).format(format || dateTimeFormat);
export const stampToStringSeconds = (date, format?) => moment(date).format(format || dateTimeSecondFormat);
export const stampToStringMiliSeconds = (date, format?) => moment(date).format(format || dateTimeSecondMiliFormat);
export const stringToDate = (date) => moment(date, dateTimeFormat).toDate();
export const stampToDate = (date) => moment(date).toDate();

// Controls
export const isMoment = (value) => moment.isMoment(value);
export const isBeforeToday = (value) => value?.isBefore(getToday().startOf('day'));
export const isBetween = (start, end, format?) =>
  moment().isBetween(moment(start, format ? format : timeFormat), moment(end, format ? format : timeFormat));
export const isAfter = (currentDate, expectedDate) => moment(currentDate).isAfter(expectedDate);

// Format Date
export const sortDate = (a, b) => new Date(a).valueOf() - new Date(b).valueOf();
