// Import React
import React from 'react';

// Import Antd
import { Modal as ModalAntd } from 'antd';
import { ModalProps as ModalPrpsAntd } from 'antd/lib/modal';

// Import Constants
import { ICON_LIST } from 'src/common/constants/icon/iconList';

//Import Components
import TranslatedText from 'src/components-new/translated-text/TranslatedText';
import Icon from 'src/components-new/icon/Icon';
export interface ModalProps extends ModalPrpsAntd {
  onCancel?: () => void | undefined;
  onOk?: (values?) => void | undefined;
  visible: boolean | undefined;
  title?: string;
  customTitle?: string;
  children: any;
  footer?: React.ReactNode;
  bodyStyle?: React.CSSProperties | undefined;
  width?: number;
  bottomSpace?: boolean | undefined;
}

function Modal(props: ModalProps) {
  // Desctruct Props
  const { children, onCancel, onOk, visible, title, customTitle, footer, bodyStyle, bottomSpace } = props;

  return (
    <ModalAntd
      {...props}
      title={customTitle ? customTitle : title && <TranslatedText label={title} />}
      onCancel={onCancel}
      onOk={onOk}
      closeIcon={<Icon icon={ICON_LIST.CLOSE} size="lg" />}
      visible={visible}
      bodyStyle={bottomSpace ? { paddingBottom: 80 } : bodyStyle ? bodyStyle : undefined}
      footer={footer}
      destroyOnClose
    >
      {children}
    </ModalAntd>
  );
}

export default Modal;
