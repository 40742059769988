// Import React
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

// Import store
import { authUser } from 'src/store/actions/user/auth';

// Import Components
import Loading from 'src/components-new/loading/Loading';
import { checkArrayIncludes } from 'src/common/utils/checkArrayIncludes';
import { ERROR_PAGE_LIST } from 'src/common/constants/error-page-list/errorPageList';

function AuthControl(props) {
  // Description Props
  const { children } = props;

  // Store Variables
  const dispatch = useDispatch();

  // Variables
  const history = useHistory();
  const AUTH_CONTROL_STATUS = process.env.REACT_APP_AUTHENTICATION;
  const CAS_LOGIN_URL = process.env.REACT_APP_CAS_LOGIN_URL;
  const RETURN_URL = process.env.REACT_APP_RETURN_URL;
  const REACT_APP_EKYS = process.env.REACT_APP_EKYS;
  let returnTemplate;

  // Functions
  function parseQueryParams() {
    const parsed = queryString.parse(window.location.search);

    return parsed;
  }

  // Parsed Variables
  const parsed = parseQueryParams();
  const noTicket = parsed.ticket === undefined;
  const lastUrl = localStorage.getItem('LastPageUrl') ? localStorage.getItem('LastPageUrl') : '/';

  useEffect(() => {
    if (AUTH_CONTROL_STATUS === 'true' && REACT_APP_EKYS === 'false') {
      //qa
      if (!noTicket) {
        // has ticket
        authUser(dispatch, parsed.ticket);

        lastUrl !== '/' && history.push(`${lastUrl}`);
      } else {
        // hasnot ticket
        localStorage.setItem('LastPageUrl', location?.pathname);
        if (checkArrayIncludes(window.location.pathname, ERROR_PAGE_LIST)) {
          returnTemplate = children;
        } else {
          window.location.href = `${CAS_LOGIN_URL}?service=${RETURN_URL}`;
        }
      }
    }
  }, []);

  if (AUTH_CONTROL_STATUS === 'false' && REACT_APP_EKYS === 'true') {
    // dev

    returnTemplate = children;
  } else {
    if (noTicket) {
      if (checkArrayIncludes(window.location.pathname, ERROR_PAGE_LIST)) {
        returnTemplate = children;
      } else {
        returnTemplate = <Loading loading={true} />;
      }
    } else {
      returnTemplate = children;
    }
  }

  return <Fragment>{returnTemplate}</Fragment>;
}

export default AuthControl;
