// Import React
import { lazy } from 'react';

// Import Constants
import { all_users, only_epias } from 'src/common/constants/roles-rights/roles';

import {
  CONSTMENU_ORGANISATION_SITUATION_TRACK_HOMEPAGE,
  HOMEPAGE_MYMATCHES_OBJECTION_BUTTON,
  LOGIN_RIGHT,
  MENU_ANNOUNCEMENT_HOMEPAGE,
  MENU_BASE_PRICE_HOMEPAGE,
  MENU_COLLATERAL_SIMULATIONHOMEPAGE,
  MENU_COLLATERAL_SUMMARY_HOME_PAGE,
  MENU_COLLATERAL_TRACK_HOMEPAGE,
  MENU_CONTRACT_AND_SESSION_OBSERVATION_HOMEPAGE,
  MENU_CONTRACT_CREATE_PARAMETERS_HOMEPAGE,
  MENU_DEPOSE_BACK_TGH_TO_PARTICIPANTS_HOMEPAGE,
  MENU_DRAW_TGH_FROM_PARTICIPANTS_HOMEPAGE,
  MENU_FINANCIAL_CONTROL_HOMEPAGE,
  MENU_GENERAL_PARAMETERS_HOMEPAGE,
  MENU_HOMEPAGE,
  MENU_INDEFAULT_INFORMATION_HOMEPAGE,
  MENU_INDEFULTANTY_MANAGEMENT_HOMEPAGE,
  MENU_INDEFULTANTY_MANAGEMENT_MATCHES,
  MENU_INDEFULTANTY_OFFSET_HOMEPAGE,
  MENU_INDEFULTANTY_TRACK_DETAIL_HOMEPAGE,
  MENU_INDICATOR_PRICE_HOMEPAGE,
  MENU_INFORM_TAKASBANK_HOMEPAGE,
  MENU_INVOICE_COLLATERAL_HOMEPAGE,
  MENU_MANUEL_RETROSPECTIVE_ADJUSTMENT_HOMEPAGE,
  MENU_MARKET_DATA_REPORT_HOMEPAGE,
  MENU_MARKET_RESULTS_HOME_PAGE,
  MENU_NOTIFICATION_CONFIGURATION_HOMEPAGE,
  MENU_OFFICIAL_HOLIDAYS_HOMEPAGE,
  MENU_OPERATION_FLOW_HOMEPAGE,
  MENU_ORDER_AND_MATCHES_HOMEPAGE,
  MENU_ORGANIZATION_PAGE,
  MENU_RECENT_INDEFULTANTY_TRACK_HOMEPAGE,
  MENU_SCHEDULED_JOBS_OBSERVATION_HOMEPAGE,
  MENU_SETTLEMENTPROCESS,
  MENU_SETTLEMENT_CALCULATIONS_AND_FINALIZATION_APPROVAL_PAGE,
  MENU_SETTLEMENT_ITEMS_REPORT,
  MENU_SETTLEMENT_NOTIFICATION_HOMEPAGE,
  MENU_TGH_INDEFULTANTY_TRACK_HOMEPAGE,
  MENU_TRANSACTION_HISTORY_HOMEPAGE,
  MENU_TRANSMISSION_MONTHLY_SETTLEMENT_HOMEPAGE,
  MENU_VGP_OPERATIONS_HOMEPAGE,
  MENU_VIEW_INCOICE_ITEMS_HOMEPAGE,
  POSITION_LIMIT_HOMEPAGE_VIEW
} from 'src/common/constants/roles-rights/rights';

// Kayıt İşlemleri
const Organisations = lazy(() => import('src/pages-new/registration-procedures/organisations/Organisations'));

// Piyasa İşlemleri
const Ggf = lazy(() => import('src/pages-new/market-transactions/ggf/Ggf'));
const HomePage = lazy(() => import('src/pages-new/market-transactions/home-page/HomePage'));
const MarketData = lazy(() => import('src/pages-new/reports/market-data/MarketData'));
const MarketResult = lazy(() => import('src/pages-new/market-transactions/market-results/MarketResuts'));
const Objection = lazy(() => import('src/pages-new/market-transactions/objection/Objection'));
const OrderAndMatchings = lazy(() => import('src/pages-new/market-transactions/order-and-matching/OrderAndMatching'));
const OrganizationStatusTracking = lazy(
  () => import('src/pages-new/market-transactions/organization-status-tracking/OrganizationStatusTracking')
);
const PositionLimit = lazy(() => import('src/pages-new/market-transactions/position-limit/PositionLimit'));
const TransactionFlow = lazy(() => import('src/pages-new/reports/transaction-flow/TransactionFlow'));

// Teminat İşlemleri
const CollateralSimulation = lazy(() => import('src/pages-new/collateral/collateral-simulation/CollateralSimulation'));
const CollateralSummary = lazy(() => import('src/pages-new/collateral/collateral-summary/CollateralSummary'));
const CollateralTracking = lazy(() => import('src/pages-new/collateral/collateral-tracking/CollateralTracking'));

// Uzlaştırma ve Faturalama İşlemleri
// const BackwardCorrectionLineIntroductionPage = lazy(
//   () => import('src/pages/common/settlementAndBillingModule/backwardCorrectionLineIntroductionPage.js')
// );
const SetGddkManualInput = lazy(
  () => import('src/pages-new/settlement-and-billing/set-gddk-manual-input/SetGddkManualInput')
);

const FinanceControls = lazy(() => import('src/pages-new/settlement-and-billing/finance-controls/FinanceControls'));
// const InvoicingSettlementHomepage = lazy(
//   () => import('src/pages/common/settlementAndBillingModule/invoicingSettlementHomepage')
// );
const SettlementProcessTracking = lazy(
  () => import('src/pages-new/settlement-and-billing/settlement-process-tracking/SettlementProcessTracking')
);
// const NoticeConciliationPage = lazy(
//   () => import('src/pages/common/settlementAndBillingModule/noticeConciliationPage.js')
// );
const SettlementNotice = lazy(() => import('src/pages-new/settlement-and-billing/settlement-notice/SettlementNotice'));
const SettlementProcessCalculation = lazy(
  () => import('src/pages-new/settlement-and-billing/settlement-process-calculation/SettlementProcessCalculation')
);

const BasisOfSettlement = lazy(
  () => import('src/pages-new/settlement-and-billing/basis-of-settlement/BasisOfSettlement')
);

// const SettlementCalculationsPage = lazy(
//   () => import('src/pages/common/settlementAndBillingModule/settlementCalculationsPage')
// );
const TakasbankNotification = lazy(
  () => import('src/pages-new/settlement-and-billing/takasbank-notification/TakasbankNotification')
);
const WithdrawFromTGHAccounts = lazy(
  () => import('src/pages-new/settlement-and-billing/withdraw-tgh-accounts/WithdrawTghAccounts')
);
const WithdrawRefundFromTGHAccountsPage = lazy(
  () => import('src/pages-new/settlement-and-billing/withdraw-tgh-refund/WithdrawTghRefund')
);
const SettlementLinesView = lazy(
  () => import('src/pages-new/settlement-and-billing/settlement-lines-view/SettlementLinesView')
);

const DefaultTracking = lazy(
  () => import('src/pages-new/reports/default-tracking/default-tracking-current/DefaultTracking')
);
const DefaultTrackingDetailPage = lazy(
  () => import('src/pages-new/reports/default-tracking/default-tracking-detail/DefaultTrackingDetail')
);
const SettlementItems = lazy(() => import('src/pages-new/reports/settlement-items/SettlementItems'));
const InvoiceGuarantee = lazy(() => import('src/pages-new/reports/invoice-guarantee/InvoiceGuarantee'));
const IndefaultentityNettingPage = lazy(
  () => import('src/pages-new/indefaultentity/indefaultentity-netting/IndefaultentityNetting')
);
const IndefaultEntityManagementMatch = lazy(
  () => import('src/pages-new/indefaultentity/indefaultentity-management-match/IndefaultEntityManagementMatch')
);

// Duyurular ve Bildirimler
const NotificationConfiguration = lazy(
  () => import('src/pages-new/admin-configurations/notification-configuration/NotificationConfiguration')
);
const Announcements = lazy(() => import('src/pages-new/announcements/Announcements'));

// Baz Fiyat
const BasePrice = lazy(() => import('src/pages-new/market-transactions/base-price/BasePrice'));

// TGH Modülü
const VgpOperationsPage = lazy(() => import('src/pages-new/indefaultentity/vgp-operation/VgpOperation'));

const TghDefaultFollow = lazy(() => import('src/pages-new/indefaultentity/tgh-default-follow/TghDefaultFollow'));

const TghAdministrationPage = lazy(() => import('src/pages-new/indefaultentity/tgh-administration/TghAdministration'));

const ClosingPositionMonthlySettlement = lazy(
  () => import('src/pages-new/indefaultentity/closing-position-monthly-settlement/ClosingPositionMonthlySettlement')
);

const DefaultInformation = lazy(() => import('src/pages-new/indefaultentity/default-information/DefaultInformation'));

// Default Template for Components
const DefaultUsage = lazy(() => import('src/components-new/default-usage/DefaultUsage'));

// Yönetici İşlemleri
const ContractCreateParameters = lazy(
  () => import('src/pages-new/admin-configurations/contract-create-parameters/ContractCreateParameters')
);
const OfficialHolidays = lazy(() => import('src/pages-new/admin-configurations/official-holidays/OfficialHolidays'));

const ContractAndSessionTracking = lazy(
  () => import('src/pages-new/admin-configurations/contract-and-session-tracking/ContractAndSessionTracking')
);

const TghInformation = lazy(
  () => import('src/pages-new/indefaultentity/tgh-information/TghInformation')
);

// Raporlar
const ProcessHistory = lazy(() => import('src/pages-new/reports/process-history/ProcessHistory'));
//
const GeneralParameters = lazy(() => import('src/pages-new/admin-configurations/general-parameters/GeneralParameters'));
const ScheduledJobs = lazy(() => import('src/pages-new/admin-configurations/scheduled-jobs/ScheduledJobs'));
const CurrentDateSettings = lazy(
  () => import('src/pages-new/admin-configurations/current-date-settings/CurrentDateSettings')
);

export const routes = [
  {
    title: 'PAGE_TITLES.HOME',
    exact: true,
    path: '/anasayfa',
    hasProfile: all_users,
    hasRight: MENU_HOMEPAGE,
    redirectUrl: '/',
    component: HomePage
  },
  {
    title: 'PAGE_TITLES.HOME',
    exact: true,
    path: '/',
    hasProfile: all_users,
    // hasRight: MENU_HOMEPAGE,
    hasRight: LOGIN_RIGHT,
    redirectUrl: '/',
    component: HomePage
  },

  {
    title: 'PAGE_TITLES.MARKET_RESULTS',
    exact: true,
    path: '/piyasa-sonuclari',
    hasProfile: all_users,
    hasRight: MENU_MARKET_RESULTS_HOME_PAGE,
    redirectUrl: '/',
    component: MarketResult
  },
  {
    title: 'PAGE_TITLES.ORGANISATIONS',
    exact: true,
    path: '/organizasyonlar',
    hasProfile: all_users,
    hasRight: MENU_ORGANIZATION_PAGE,
    redirectUrl: '/',
    component: Organisations
  },
  {
    title: 'PAGE_TITLES.POSITION_LIMIT',
    exact: true,
    path: '/pozisyon-limiti',
    hasProfile: all_users,
    hasRight: POSITION_LIMIT_HOMEPAGE_VIEW,
    redirectUrl: '/',
    component: PositionLimit
  },
  {
    title: 'PAGE_TITLES.ORGANISATION_STATUS_TRACKING',
    exact: true,
    path: '/organizasyon-durum-takip',
    hasProfile: all_users,
    hasRight: CONSTMENU_ORGANISATION_SITUATION_TRACK_HOMEPAGE,
    redirectUrl: '/',
    component: OrganizationStatusTracking
  },
  {
    title: 'PAGE_TITLES.GGF',
    exact: true,
    path: '/ggf',
    hasProfile: all_users,
    hasRight: MENU_INDICATOR_PRICE_HOMEPAGE,
    redirectUrl: '/',
    component: Ggf
  },
  {
    title: 'PAGE_TITLES.INVOICING_SETTLEMENT_HOMEPAGE',
    exact: true,
    path: '/uzlastirma-surec-takip',
    hasProfile: only_epias,
    hasRight: MENU_SETTLEMENTPROCESS,
    redirectUrl: '/',
    component: SettlementProcessTracking
  },
  {
    title: 'PAGE_TITLES.INVOICING_SETTLEMENT_BASIS',
    exact: true,
    path: '/uzlastirma-donemi-bazinda',
    hasProfile: only_epias,
    hasRight: MENU_SETTLEMENTPROCESS,
    redirectUrl: '/',
    component: BasisOfSettlement
  },
  {
    title: 'PAGE_TITLES.SETTLEMENT_CALCULATIONS_PAGE',
    exact: true,
    path: '/uzlastirma-hesaplamalari',
    hasProfile: only_epias,
    hasRight: MENU_SETTLEMENT_CALCULATIONS_AND_FINALIZATION_APPROVAL_PAGE,
    redirectUrl: '/',
    component: SettlementProcessCalculation
  },
  {
    title: 'PAGE_TITLES.NOTICE_CONCILIATION_PAGE',
    exact: true,
    path: '/uzlastirma-bildirim',
    hasProfile: all_users,
    hasRight: MENU_SETTLEMENT_NOTIFICATION_HOMEPAGE,
    redirectUrl: '/',
    component: SettlementNotice
  },
  {
    title: 'PAGE_TITLES.BACKWARD_CORRECTION_LINE_INTRODUCTION_PAGE',
    exact: true,
    path: '/duzeltme-kalemi-giris',
    hasProfile: only_epias,
    hasRight: MENU_MANUEL_RETROSPECTIVE_ADJUSTMENT_HOMEPAGE,
    redirectUrl: '/',
    component: SetGddkManualInput
  },
  {
    title: 'PAGE_TITLES.FINANCIAL_CONTROLS_PAGE',
    exact: true,
    path: '/finans-kontrolleri',
    hasProfile: only_epias,
    hasRight: MENU_FINANCIAL_CONTROL_HOMEPAGE,
    redirectUrl: '/',
    component: FinanceControls
  },
  {
    title: 'PAGE_TITLES.COLLATERAL_SIMULATION',
    exact: true,
    path: '/teminat-simulasyon',
    hasProfile: all_users,
    hasRight: MENU_COLLATERAL_SIMULATIONHOMEPAGE,
    redirectUrl: '/',
    component: CollateralSimulation
  },
  {
    title: 'PAGE_TITLES.COLLATERAL_SUMMARY',
    exact: true,
    path: '/teminat-ozet',
    hasProfile: all_users,
    hasRight: MENU_COLLATERAL_SUMMARY_HOME_PAGE,
    redirectUrl: '/',
    component: CollateralSummary
  },
  {
    title: 'PAGE_TITLES.COLLATERAL_TRACKING',
    exact: true,
    path: '/teminat-takip',
    hasProfile: all_users,
    hasRight: MENU_COLLATERAL_TRACK_HOMEPAGE,
    redirectUrl: '/',
    component: CollateralTracking
  },
  {
    title: 'PAGE_TITLES.TAKASBANK_NOTIFICATION_PAGE',
    exact: true,
    path: '/takasbank-bildirim',
    hasProfile: only_epias,
    hasRight: MENU_INFORM_TAKASBANK_HOMEPAGE,
    redirectUrl: '/',
    component: TakasbankNotification
  },
  {
    title: 'PAGE_TITLES.WITHDRAW_FROM_TGH_ACCOUNTS_PAGE',
    exact: true,
    path: '/tgh-hesaplarindan-cek',
    hasProfile: only_epias,
    hasRight: MENU_DRAW_TGH_FROM_PARTICIPANTS_HOMEPAGE,
    redirectUrl: '/',
    component: WithdrawFromTGHAccounts
  },
  {
    title: 'PAGE_TITLES.WITHDRAW_REFUND_FROM_TGH_ACCOUNTS_PAGE',
    exact: true,
    path: '/katilimcilara-tgh-bedeli-iade-et',
    hasProfile: only_epias,
    hasRight: MENU_DEPOSE_BACK_TGH_TO_PARTICIPANTS_HOMEPAGE,
    redirectUrl: '/',
    component: WithdrawRefundFromTGHAccountsPage
  },
  {
    title: 'PAGE_TITLES.WIEW_INVIOCE_ITEMS_PAGE',
    exact: true,
    path: '/fatura-kalemleri-goruntuleme',
    hasProfile: all_users,
    hasRight: MENU_VIEW_INCOICE_ITEMS_HOMEPAGE,
    redirectUrl: '/',
    component: SettlementLinesView
  },
  {
    title: 'PAGE_TITLES.HOME',
    exact: true,
    path: '/default',
    hasProfile: all_users,
    redirectUrl: '/',
    component: DefaultUsage
  },
  {
    title: 'PAGE_TITLES.ANNOUNCEMENT',
    exact: true,
    path: '/duyurular',
    hasProfile: all_users,
    hasRight: MENU_ANNOUNCEMENT_HOMEPAGE,
    redirectUrl: '/',
    component: Announcements
  },
  {
    title: 'PAGE_TITLES.CURRENT_DEFAULT_TRACKING_PAGE',
    exact: true,
    path: '/guncel-temerrut-takip',
    hasProfile: only_epias,
    hasRight: MENU_RECENT_INDEFULTANTY_TRACK_HOMEPAGE,
    redirectUrl: '/',
    component: DefaultTracking
  },
  {
    title: 'PAGE_TITLES.DEFAULT_TRACKING_DETAIL_PAGE',
    exact: true,
    path: '/temerrut-takip-detay',
    hasProfile: only_epias,
    hasRight: MENU_INDEFULTANTY_TRACK_DETAIL_HOMEPAGE,
    redirectUrl: '/',
    component: DefaultTrackingDetailPage
  },
  {
    title: 'PAGE_TITLES.SETTLEMENT_ITEMS',
    exact: true,
    path: '/uzlastirma-kalemleri',
    hasProfile: only_epias,
    hasRight: MENU_SETTLEMENT_ITEMS_REPORT,
    redirectUrl: '/',
    component: SettlementItems
  },
  {
    title: 'PAGE_TITLES.INVOICE_GUARANTEE_PAGE',
    exact: true,
    path: '/fatura-teminat',
    hasProfile: only_epias,
    hasRight: MENU_INVOICE_COLLATERAL_HOMEPAGE,
    redirectUrl: '/',
    component: InvoiceGuarantee
  },
  {
    title: 'PAGE_TITLES.INDEFAULT_ENTITY_NETTING_PAGE',
    exact: true,
    path: '/temerrut-netlestirme',
    hasProfile: only_epias,
    hasRight: MENU_INDEFULTANTY_OFFSET_HOMEPAGE,
    redirectUrl: '/',
    component: IndefaultentityNettingPage
  },
  {
    title: 'PAGE_TITLES.INDEFAULT_ENTITY_MANAGEMENT_MATCH_PAGE',
    exact: true,
    path: '/temerrut-yonetimi-eslestirmeleri',
    hasProfile: only_epias,
    hasRight: MENU_INDEFULTANTY_MANAGEMENT_MATCHES,
    redirectUrl: '/',
    component: IndefaultEntityManagementMatch
  },
  {
    title: 'PAGE_TITLES.BASE_PRICE',
    exact: true,
    path: '/baz-fiyat',
    hasProfile: all_users,
    hasRight: MENU_BASE_PRICE_HOMEPAGE,
    redirectUrl: '/',
    component: BasePrice
  },
  {
    title: 'PAGE_TITLES.NOTIFICATION_CONFIGURATION',
    exact: true,
    path: '/bildirim-konfigurasyon',
    hasProfile: only_epias,
    hasRight: MENU_NOTIFICATION_CONFIGURATION_HOMEPAGE,
    redirectUrl: '/',
    component: NotificationConfiguration
  },
  {
    title: 'PAGE_TITLES.VGP_OPERATION_PAGE',
    exact: true,
    path: '/vgp-islemleri',
    hasProfile: only_epias,
    hasRight: MENU_VGP_OPERATIONS_HOMEPAGE,
    redirectUrl: '/',
    component: VgpOperationsPage
  },
  {
    title: 'PAGE_TITLES.TGH_DEFAULT_FOLLOW_PAGE',
    exact: true,
    path: '/tgh-temerrut-takip',
    hasProfile: only_epias,
    hasRight: MENU_TGH_INDEFULTANTY_TRACK_HOMEPAGE,
    redirectUrl: '/',
    component: TghDefaultFollow
  },
  {
    title: 'PAGE_TITLES.TGH_MANAGEMENT_PAGE',
    exact: true,
    path: '/tgh-yonetim',
    hasProfile: only_epias,
    hasRight: MENU_INDEFULTANTY_MANAGEMENT_HOMEPAGE,
    redirectUrl: '/',
    component: TghAdministrationPage
  },
  {
    title: 'PAGE_TITLES.TGH_INFORMATION_PAGE',
    exact: true,
    path: '/tgh-bilgileri',
    hasProfile: all_users,
    hasRight: MENU_INDEFULTANTY_MANAGEMENT_HOMEPAGE,
    redirectUrl: '/',
    component: TghInformation
  },
  {
    title: 'PAGE_TITLES.POSITION_CLOSING_SUMMARY',
    exact: true,
    path: '/iletim-pozisyonu-kapama-aylik-uzlastirma',
    hasProfile: only_epias,
    hasRight: MENU_TRANSMISSION_MONTHLY_SETTLEMENT_HOMEPAGE,
    redirectUrl: '/',
    component: ClosingPositionMonthlySettlement
  },
  {
    title: 'PAGE_TITLES.DEFAULT_INFORMATION',
    exact: true,
    path: '/temerrut-bilgi',
    hasProfile: only_epias,
    hasRight: MENU_INDEFAULT_INFORMATION_HOMEPAGE,
    redirectUrl: '/',
    component: DefaultInformation
  },
  {
    title: 'PAGE_TITLES.MARKET_DATA',
    exact: true,
    path: '/piyasa-verileri',
    hasProfile: only_epias,
    hasRight: MENU_MARKET_DATA_REPORT_HOMEPAGE,
    redirectUrl: '/',
    component: MarketData
  },
  {
    title: 'PAGE_TITLES.OBJECTIONS',
    exact: true,
    path: '/itirazlar',
    hasProfile: all_users,
    hasRight: HOMEPAGE_MYMATCHES_OBJECTION_BUTTON,
    redirectUrl: '/',
    component: Objection
  },
  {
    title: 'PAGE_TITLES.ORDER_AND_MATCHINGS',
    exact: true,
    path: '/teklif-ve-eslesmeler',
    hasProfile: all_users,
    hasRight: MENU_ORDER_AND_MATCHES_HOMEPAGE,
    redirectUrl: '/',
    component: OrderAndMatchings
  },
  {
    title: 'PAGE_TITLES.TRANSACTION_FLOW',
    exact: true,
    path: '/islem-akisi',
    hasProfile: only_epias,
    hasRight: MENU_OPERATION_FLOW_HOMEPAGE,
    redirectUrl: '/',
    component: TransactionFlow
  },
  {
    title: 'PAGE_TITLES.CONTRACT_CREATE_PARAMETERS',
    exact: true,
    path: '/kontrat-olusturma-parametreleri',
    hasProfile: only_epias,
    hasRight: MENU_CONTRACT_CREATE_PARAMETERS_HOMEPAGE,
    redirectUrl: '/',
    component: ContractCreateParameters
  },
  {
    title: 'PAGE_TITLES.OFFICIAL_HOLIDAYS',
    exact: true,
    path: '/resmi-tatil-gunleri',
    hasProfile: only_epias,
    hasRight: MENU_OFFICIAL_HOLIDAYS_HOMEPAGE,
    redirectUrl: '/',
    component: OfficialHolidays
  },
  {
    title: 'PAGE_TITLES.CONTRACT_AND_SESSION_TRACKING',
    exact: true,
    path: '/kontrat-ve-seans-izleme',
    hasProfile: only_epias,
    hasRight: MENU_CONTRACT_AND_SESSION_OBSERVATION_HOMEPAGE,
    redirectUrl: '/',
    component: ContractAndSessionTracking
  },
  {
    title: 'PAGE_TITLES.PROCESS_HISTORY',
    exact: true,
    path: '/islem-gecmisi',
    hasProfile: only_epias,
    hasRight: MENU_TRANSACTION_HISTORY_HOMEPAGE,
    redirectUrl: '/',
    component: ProcessHistory
  },
  {
    title: 'PAGE_TITLES.GENERAL_PARAMETERS',
    exact: true,
    path: '/genel-parametreler',
    hasProfile: only_epias,
    hasRight: MENU_GENERAL_PARAMETERS_HOMEPAGE,
    redirectUrl: '/',
    component: GeneralParameters
  },
  {
    title: 'PAGE_TITLES.SCHEDULE_JOBS',
    exact: true,
    path: '/zamanlanmis-gorevler',
    hasProfile: only_epias,
    hasRight: MENU_SCHEDULED_JOBS_OBSERVATION_HOMEPAGE,
    redirectUrl: '/',
    component: ScheduledJobs
  },
  {
    title: 'PAGE_TITLES.TIME_SETTINGS',
    exact: true,
    path: '/zaman-ayarlari',
    hasProfile: only_epias,
    //hasRight: MENU_SCHEDULED_JOBS_OBSERVATION_HOMEPAGE,
    redirectUrl: '/',
    component: CurrentDateSettings
  }
];
