// Import React
import React from 'react';

// Import Components
import { Tooltip as TooltipAntd } from 'antd';
import { TooltipProps as TooltipPropsAntd } from 'antd/lib/tooltip';

//Import Components
import TranslatedText from 'src/components-new/translated-text/TranslatedText';

export interface TooltipProps {
  title?: string | undefined;
  isCustomTitle?: boolean;
  children: React.ReactElement;
}

type MergedTooltipProps = TooltipPropsAntd & TooltipProps;

function Tooltip(props: MergedTooltipProps) {
  // Desctruct Props
  const { children, title, isCustomTitle } = props;

  return (
    <TooltipAntd {...props} title={!isCustomTitle ? <TranslatedText label={title} /> : title}>
      {children}
    </TooltipAntd>
  );
}

export default Tooltip;
