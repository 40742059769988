/*****  PİYASA İŞLEMLERİ *****/

/* === Anasayfa === */
export const LOGIN_RIGHT = ['VGP001']; //Giriş Yetkisi-{$VGP001$}
export const MENU_HOMEPAGE = ['Menu.Homepage']; //Piyasa İşlemleri Modülü Anasayfa Ekranı Görüntüleme UC.009-{$VGP003$}
export const HOMEPAGE_MATCHES_VIEW = ['Homepage.Matches.View']; //Piyasa İşlemleri Modülü Anasayfa Ekranı Eşleşmeler Tablosu Görüntüleme-{$VGP004$}
export const HOMEPAGE_MYMATCHES_VIEW = ['Homepage.Mymatches.View']; //Piyasa İşlemleri Modülü Anasayfa Ekranı Eşleşmelerim Tablosu Görüntüleme-{$VGP196$}
export const HOMEPAGE_MYMATCHES_OBJECTION_BUTTON = ['Homepage.Mymatches.Objection.Button']; //Piyasa İşlemleri Modülü Anasayfa Ekranı Eşleşmelerim Tablosunda İtirazet Butonu Tetikleme UC.024-{$VGP006$}
export const VGP_ORDER_RIGHT = ['VGP.OrderRight']; //Teklif verme yetkisi-{$VGP010$}
export const HOMEPAGE_CONTRACTS_PARTICIPANT_COLUMN_VIEW = ['Homepage.Contracts.Participant.Column.View']; //Ana Sayfa Kontratlar Kısmı Katılımcı Kolonu Görüntüleme Yetkisi UC.014-{$VGP184$}
export const HOMEPAGE_ORDERBOOK_QUICKBUYSELL_BUTTON_VIEW = ['Homepage.OrderBook.QuickBuySell.Button.View']; //Ana Sayfa Teklif Defteri Kısmı Hızlı Al/Sat Butonları Görüntüleme Yetkisi UC.018-{$VGP185$}
export const HOMEPAGE_ORDERS_VERSIONHISTORY_COLUMN_VIEW = ['Homepage.Orders.VersionHistory.Column.View']; //Ana Sayfa Tekliflerim Kısmı Versiyon Detayı Kolonu Görüntüleme Yetkisi UC.019-{$VGP186$}
export const HOMEPAGE_ORDERS_ORDERUPDATE_COLUMN_VIEW = ['Homepage.Orders.OrderUpdate.Column.View']; //Ana Sayfa Tekliflerim Kısmı Teklif Güncelleme Kolonu Görüntüleme Yetkisi UC.019-{$VGP187$}
export const HOMEPAGE_MARKETMATCHES_BUYINGORG_COLUMN_VIEW = ['Homepage.MarketMatches.BuyingOrg.Column.View']; //Ana sayfa Piyasa Eşleşmeleri Alış Organizasyon Kolonu Görüntüleme Yetkisi UC.015-{$VGP161$}
export const HOMEPAGE_MARKETMATCHES_SELLINGORG_COLUMN_VIEW = ['Homepage.MarketMatches.SellingOrg.Column.View']; //Ana sayfa Piyasa Eşleşmeleri Satış Organizasyon Kolonu Görüntüleme Yetkisi UC.015-{$VGP162$}
export const HOMEPAGE_ORDERBOOK_ORDERDEPTHCHART_BUTTON = ['Homepage.OrderBook.OrderDepthChart.Button']; //Ana sayfa Teklif Derinliği Grafiği Butonu Görüntüleme Yetkisi UC.017-{$VGP163$}
export const HOMEPAGE_MATCHES_BUYINGORG_COLUMN_VIEW = ['Homepage.Matches.BuyingOrg.Column.View']; //Ana sayfa Eşleşmeler Alış Organizasyon Kolonu Görüntüleme Yetkisi UC.022-{$VGP164$}
export const HOMEPAGE_MATCHES_SELLINGORG_COLUMN_VIEW = ['Homepage.Matches.SellingOrg.Column.View']; //Ana sayfa Eşleşmeler Satış Organizasyon Kolonu Görüntüleme Yetkisi UC.022-{$VGP165$}
export const HOMEPAGE_MATCHES_MATCHDETAIL_COLUMN_VIEW = ['Homepage.Matches.MatchDetail.Column.View']; //Ana sayfa Eşleşmeler Eşleşme Detayı Kolonu Görüntüleme Yetkisi UC.022-{$VGP166$}
export const HOMEPAGE_MATCHES_ORDERID_COLUMN_VIEW = ['Homepage.Matches.OrderID.Column.View']; //Ana sayfa Eşleşmeler Teklif ID Kolonu Görüntüleme Yetkisi UC.022-{$VGP167$}
export const HOMEPAGE_MATCHES_ORDERDIRECTION_COLUMN_VIEW = ['Homepage.Matches.OrderDirection.Column.View']; //Ana sayfa Eşleşmeler Teklif Yönü Kolonu Görüntüleme Yetkisi UC.022-{$VGP168$}
export const HOMEPAGE_MATCHES_MATCHID_COLUMN_VIEW = ['Homepage.Matches.MatchID.Column.View']; //Ana sayfa Eşleşmeler Eşleşme Id Kolonu Görüntüleme Yetkisi UC.022-{$VGP169$}
export const HOMEPAGE_MATCHES_OBJECTION_COLUMN_VIEW = ['Homepage.Matches.Objection.Column.View']; //Ana sayfa Eşleşmeler İtiraz Et Kolonu Görüntüleme Yetkisi UC.022-{$VGP170$}
export const HOMEPAGE_ORDER_UPDATE = ['Homepage.Order.Update']; //Ana Sayfa Teklif Güncelleme Yetkisi UC.020-{$$}-{$VGP129$}
export const HOMEPAGE_MATCHEDORDER_MATCHEDORDERDETAIL_CANCEL = ['Homepage.MatchedOrder.MatchedOrderDetail.Cancel']; //Ana Sayfa Eşleşme İptal Etme Yetkisi UC.025-{$VGP130$}

/* === Teklif Ve Eşleşmeler === */
export const MENU_ORDER_AND_MATCHES_HOMEPAGE = ['Menu.OrderAndMatchesHomepage']; //Teklif ve Eşleşmeler Sayfası Görüntüleme Yetkisi UC.036-{$VGP005$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_VIEW = ['OrderAndMatchesHomepage.Order.View']; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Görüntüleme Yetkisi UC.037-{$VGP008$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_OPPONENTORG_COLUMN_VIEW = [
  'OrderAndMatchesHomepage.Order.OpponentOrg.Column.View'
]; //Teklif ve Eşleşmeler Sayfası Eşleşmeler Kısmı Karşı Org.Adı Kolonu Görüntüleme Yetkisi UC.037-{$VGP033$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_CANCELFUNC_COLUMN_VIEW = [
  'OrderAndMatchesHomepage.Order.Cancelfunc.Column.View'
]; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı İptal Kolonu Görüntüleme Yetkisi UC.037-{$VGP034$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_CANCEL_BUTTON = ['OrderAndMatchesHomepage.Order.Cancel.Button']; // Teklif ve Eşleşmeler Sayfası Teklifler Kısmı İptal Butonu Tetikleme Yetkisi UC.037 ve UC.039-{$VGP035$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_SELECT_COLUMN_VIEW = ['OrderAndMatchesHomepage.Order.Select.Column.View']; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Seç Kolonu Görüntüleme Yetkisi UC.037-{$VGP036$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_UPTDATEFUNC_COLUMN_VIEW = [
  'OrderAndMatchesHomepage.Order.UptdateFunc.Column.View'
]; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Güncelle Kolonu Görüntüleme Yetkisi UC.037-{$VGP037$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_UPDATE_BUTTON = ['OrderAndMatchesHomepage.Order.Update.Button']; // Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Teklif Güncelleme Butonu Tetikleme Yetkisi  UC.037-{$VGP038$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_BATCHORDER_BUTTON = ['OrderAndMatchesHomepage.Order.BatchOrder.Button']; // Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Toplu Teklif Verme Butonu Tetikleme Yetkisi -{$VGP039$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_ORDERVERSIONDETAIL_COLUMN_VIEW = [
  'OrderAndMatchesHomepage.Order.OrderVersionDetail.Column.View'
]; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Versiyon Detayı Kolonu Görüntüleme Yetkisi UC.037-{$VGP040$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_ORDERVERSIONDETAIL_BUTTON = [
  'OrderAndMatchesHomepage.Order.OrderVersionDetail.Button'
]; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Versiyon Detayı Butonu Tetikleme Yetkisi UC.037-{$VGP041$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_EXPORT_BUTTON = ['OrderAndMatchesHomepage.Order.Export.Button']; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Dışarı Aktar Butonu Tetikleme Yetkisi UC.037-{$VGP042$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_BATCHOPERATION_BUTTON = [
  'OrderAndMatchesHomepage.Order.BatchOperation.Button'
]; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Toplu İşlemler Butonu Tetikleme Yetkisi UC.040-{$VGP043$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_BATCHOPERATION_POP_UP_CANCELAPPROVAL_BUTTON = [
  'OrderAndMatchesHomepage.Order.BatchOperation.Pop_up.CancelApproval.Button'
]; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Toplu İşlem Pop-up'ı Teklifler İptal Onay Butonu Tetikleme Yetkisi UC.040-{$VGP044$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_BATCHOPERATION_POP_UP_UPDATEAPPROVAL_BUTTON = [
  'OrderAndMatchesHomepage.Order.BatchOperation.Pop_up.UpdateApproval.Button'
]; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Toplu İşlem Pop-up'ı Teklifler Güncelleme Onay Butonu Tetikleme Yetkisi UC.040-{$VGP045$}
export const ORDER_AND_MATCHES_HOMEPAGE_MATCH_OBJECTION_BUTTON = ['OrderAndMatchesHomepage.Match.Objection.Button']; //Teklif ve Eşleşmeler İtiraz Et Butonu Tetikleme Yetkisi UC.043-{$VGP011$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_UPDATE = ['OrderAndMatchesHomepage.Order.Update']; //Teklif ve Eşleşmeler sayfası Teklif Güncelleme UC.041-{$VGP131$}
export const ORDER_AND_MATCHES_HOMEPAGE_MATCH_VIEW = ['OrderAndMatchesHomepage.Match.View']; //Teklif ve Eşleşmeler Sayfası Eşleşmeler Kısmı Görüntüleme Yetkisi UC.042-{$VGP009$}
export const ORDER_AND_MATCHES_HOMEPAGE_MATCHEDORDER_CANCEL = ['OrderAndMatchesHomepage.MatchedOrder.Cancel']; //Teklif ve Eşleşmeler Sayfası Eşleşme İptal Etme Yetkisi-{$VGP132$}
export const ORDER_AND_MATCHES_HOMEPAGE_ORDER_ORGANIZATION_COLUMNVIEW = [
  'OrderAndMatchesHomepage.Order.Organization.Column.View'
]; //Teklif ve Eşleşmeler Sayfası Teklifler Kısmı Org.Adı Kolonu Görüntüleme Yetkisi UC.037-{$VGP032$}

/* === Baz Fiyat === */
export const MENU_BASE_PRICE_HOMEPAGE = ['Menu.BasePriceHomepage']; //Baz Fiyat Sayfası Görüntüleme Yetkisi UC.071-{$VGP046$}
export const BASE_PRICE_HOMEPAGE_CONTRACT_BASE_PRICE_VIEW = ['BasePriceHomepage.ContractBasePrice.View']; //Baz Fiyat Sayfası Kontrat Baz Fiyatları Kısmı Görüntüleme Yetkisi UC.072-{$VGP047$}
export const BASE_PRICE_HOMEPAGE_CONTRACT_BASE_PRICE_IMPORT_BUTTON = [
  'BasePriceHomepage.ContractBasePrice.Import.Button'
]; //Baz Fiyat Sayfası Kontrat Baz Fiyatları Kısmı İçeri Aktar Butonu Tetikleme Yetkisi UC.072-{$VGP049$}

export const BASE_PRICE_HOMEPAGE_CONTRACT_BASE_PRICE_DETAIL_COLUMN_VIEW = [
  'BasePriceHomepage.ContractBasePrice.Detail.Column.View'
]; //Baz Fiyat Sayfası Kontrat Baz Fiyatları Kısmı Detay Kolonu Görüntüleme Yetkisi UC.072-{$VGP051$}
export const BASE_PRICE_HOMEPAGE_CONTRACT_BASE_PRICE_DETAIL_BUTTON = [
  'BasePriceHomepage.ContractBasePrice.Detail.Button'
]; //Baz Fiyat Sayfası Kontrat Baz Fiyatları Kısmı Detay Butonu Tetikleme Yetkisi UC.072 ve UC.073-{$VGP052$}
export const BASE_PRICE_HOMEPAGE_CONTRACT_BASE_PRICE_EXPORT_BUTTON = [
  'BasePriceHomepage.ContractBasePrice.Export.Button'
]; //Baz Fiyat Sayfası Kontrat Baz Fiyatları Kısmı Dışarı Aktar Butonu Tetikleme Yetkisi UC.072-{$VGP053$}
export const BASE_PRICE_HOMEPAGE_BASE_PRICE_SIMULATION_VIEW = ['BasePriceHomepage.BasePriceSimulation.View']; //Baz Fiyat Sayfası Baz Fiyat Simülasyon- Ay Kısmı Görüntüleme Yetkisi UC.074-{$VGP055$}
export const BASE_PRICE_HOMEPAGE_SIMULATION_START_BUTTON = ['BasePriceHomepage.SimulationStart.Button']; //Baz Fiyat Sayfası Simülasyon Butonu Tetikleme Yetkisi UC.074-{$VGP056$}

/* === Pozisyon Limiti === */
export const POSITION_LIMIT_HOMEPAGE_VIEW = ['PositionLimitHomepage.View']; //Pozisyon Limiti Anasayfa Ekranı Görüntüleme Yetkisi UC.055-{$VGP012$}
export const POSITION_LIMIT_HOMEPAGE_APPLY_POSITION_LIMIT_CHANGE_BUTTON = [
  'PositionLimitHomepage.ApplyPositionLimitChange.Button'
]; //Limit Değişim Başvur Butonu Tetikleme Yetkisi UC.059-{$VGP013$}
export const POSITION_LIMIT_HOMEPAGE_LIMIT_INCREASE_CONTRUBUTION_BUTTON = [
  'PositionLimitHomepage.LimitIncreaseContrubution.Button'
]; //Limit Artışıyla İlgili Katkıyı Yatırdım Butonu Tetikleme Yetkisi UC.060-{$VGP014$}
export const POSITION_LIMIT_HOMEPAGE_LIMIT_REQUEST_ASSESSMENT_TRIGGER = [
  'PositionLimitHomepage.LimitRequestAssessment.Trigger'
]; //Pozisyon Limit Talep Değerlendirme (Talep Detay açma) Yetkisi UC.061-{$VGP015$}
export const POSITION_LIMIT_HOMEPAGE_INFORM_TAKASBANK_BUTTON = ['PositionLimitHomepage.InformTakasbank.Button']; //Takasbank’a Bilgi Ver Butonu Tetikleme Yetkisi UC.062-{$VGP016$}
export const POSITION_LIMIT_HOMEPAGE_INFORM_PARTICIPANT_BUTTON = ['PositionLimitHomepage.InformParticipant.Button']; //Katılımcıya Bilgi Ver Butonu Tetikleme Yetkisi UC.063-{$VGP017$}
export const POSITION_LIMIT_HOMEPAGE_ORGANIZATION_LIMIT_VIEW = ['PositionLimitHomepage.OrganizationLimit.View']; //Pozisyon Limiti Sayfası Organizasyon Limitleri Kısmı Görüntüleme Yetkisi UC.093-{$VGP057$}
export const POSITION_LIMIT_HOMEPAGE_MARKET_POSITION_LIMIT_USAGE_VIEW = [
  'PositionLimitHomepage.MarketPositionLimitUsage.View'
]; //Pozisyon Limiti Sayfası Piyasa Pozisyon Limiti Kullanım Durumu Kısmı Görüntüleme Yetkisi UC.094-{$VGP058$}
export const POSITION_LIMIT_HOMEPAGE_ORG_MARKET_POSITION_LIMIT_CONTRACT_VIEW = [
  'PositionLimitHomepage.OrgMarketPositionLimitContract.View'
]; //Pozisyon Limiti Sayfası Organizasyon Piyasa Pozisyon Limiti Kontrat Kısmı Görüntüleme Yetkisi UC.095-{$VGP059$}
export const POSITION_LIMIT_HOMEPAGE_PARTICIPANT_POSITION_LIMIT_USAGE_DETAIL_VIEW = [
  'PositionLimitHomepage.ParticipantPositionLimitUsageDetail.View'
]; //Pozisyon Limiti Sayfası Katılımcı Pozisyon Limiti Kullanım Detayı Kısmı Görüntüleme Yetkisi UC.096-{$VGP060$}
export const POSITION_LIMIT_HOMEPAGE_MARKET_POSITION_LIMIT = ['PositionLimitHomepage.MarketPositionLimit']; //Piyasa Pozisyon Limiti Hesaplama Yetkisi UC.057-{$VGP138$}
export const POSITION_LIMIT_HOMEPAGE_MARKET_PARTICIPANT_POSITION_LIMIT = [
  'PositionLimitHomepage.MarketParticipantPositionLimit'
]; //Genel Katılımcı Pozisyon Limiti Hesaplama Yetkisi UC.058-{$VGP139$}

/* === Organizasyonlar === */
export const MENU_ORGANIZATION_PAGE = ['Menu.OrganisationPage']; //Organizsasyonlar Sayfası Görüntüleme Yetkisi UC.006-{$VGP193$}
export const MENU_ORGANIZATION_PAGE_REGISTRATION_PERIOD_COLUMN_VIEW = [
  'Menu.OrganisationPage.RegistrationPeriod.Column.View'
]; //Organizasyonlar Sayfası Kayıt Dönemi Kolonu Görüntüleme Organizasyonlar Sayfası Kayıt Dönemi Kolonu Görüntüleme Yetkisi UC.007-{$VGP194$}
export const MENU_ORGANIZATION_PAGE_ORGANISATIONDETAIL_COLUMN_VIEW = [
  'Menu.OrganisationPage.OrganisationDetail.Column.View'
]; //Organizasyonlar Sayfası Organizasyon Detay Kolonu Görüntüleme Organizasyonlar Sayfası Organizasyon Detay Kolonu Görüntüleme Yetkisi  UC.008-{$VGP195$}

/* === Piyasa Sonuçları === */
export const MENU_MARKET_RESULTS_HOME_PAGE = ['Menu.MarketResultsHomepage']; //Piyasa Sonuçları Sayfası Görüntüleme Yetkisi UC.045-{$VGP171$}
export const MARKET_RESULT_SHOMEPAGE_ORGANISATION_DETAIL_VIEW = ['MarketResultsHomepage.OrganisationDetail.View']; //Piyasa Sonuçları Sayfası Organizasyon Detay Kısmı Görüntüleme Yetkisi UC.050-{$VGP133$}

/* === Organizasyon Durum Takip === */
export const CONSTMENU_ORGANISATION_SITUATION_TRACK_HOMEPAGE = ['Menu.OrganisationSituationTrackHomepage']; //Organizasyon Durum Takip Sayfası Görüntüleme Yetkisi UC.051-{$VGP134$}

/* === GGF === */
export const MENU_INDICATOR_PRICE_HOMEPAGE = ['Menu.IndicatorPriceHomepage']; //GGF Sayfası Görüntüleme Yetkisi UC.052-{$VGP135$}
export const INDICATOR_PRICE_HOMEPAGE_PREVIOUS_DAYS_INDICATOR_PRICES_VIEW = [
  'IndicatorPriceHomepage.PreviousDaysIndicatorPrices.View'
]; //GGF Sayfası Geçmiş Günler - GGF Kısmı Görüntüleme Yetkisi UC.053-{$VGP136$}
export const INDICATOR_PRICE_HOMEPAGE_ONGOING_SESSION_INDICATOR_PRICES_VIEW = [
  'IndicatorPriceHomepage.OngoingSessionIndicatorPrices.View'
]; //GGF Sayfası Seans Esnasında - GGF Kısmı Görüntüleme Yetkisi UC.054-{$VGP137$}

/*****  TEMERRÜT TGH *****/

/* === VGP İşlemleri === */
export const MENU_VGP_OPERATIONS_HOMEPAGE = ['Menu.VgpOperationsHomepage']; //VGP İşlemleri Sayfası Görüntüleme Yetkisi UC.149-{$VGP061$}
export const VGP_OPERATIONS_HOMEPAGE_TO_BE_MANAGE_OPEN_POSITIONS_VIEW = [
  'VgpOperationsHomepage.ToBeManageOpenPositions.View'
]; //VGP İşlemleri Sayfası Yönetilecek Açık Pozisyonlar Kısmı Görüntüleme Yetkisi UC.149-{$VGP062$}

export const VGP_OPERATIONS_HOMEPAGE_SENDTO_ORDER_BOOK_BUTTON = ['VgpOperationsHomepage.SendtoOrderBook.Button']; //VGP İşlemleri Sayfası Yönetilecek Açık Pozisyonlar Kısmı Teklif Defterine Gönder Butonu Tetikleme Yetkisi UC.149-{$VGP063$}
export const VGP_OPERATIONS_HOMEPAGE_ORDER_CREATE = ['VgpOperationsHomepage.OrderCreate']; //VGP İşlemleri Sayfası Teklif Oluşturma yetkisi  UC.150-{$VGP064$}

/* === Temerrüt TGH === */
export const MENU_INDEFULTANTY_OFFSET_HOMEPAGE = ['Menu.IndefultantyOffsetHomepage']; //Temerrüt Netleştirme Sayfası Görüntüleme Yetkisi UC.146-{$VGP018$}
export const INDEFULTANTY_OFFSETHOMEPAGE_INDEFULTANTYOFFSET_BUTTON = [
  'IndefultantyOffsetHomepage.IndefultantyOffset.Button'
]; //Temerrüt Netleştirme Sayfası Temerrüt Netleştirme Butonu Tetikleme Yetkisi UC.146-{$VGP020$}
export const INDEFULTANTY_OFFSET_HOMEPAGE_IMPORT_BUTTON = ['IndefultantyOffsetHomepage.Import.Button']; //Temerrüt Netleştirme Sayfası Dışarı Aktar Butonu Tetikleme Yetkisi UC.146-{$VGP125$}
export const INDEFULTANTY_OFFSETHOMEPAGE_INDEFULTANTYOFFSETRESULT_POP_UP_APPROVAL_BUTTON = [
  'IndefultantyOffsetHomepage.IndefultantyOffsetResult.Pop_up.Approval.Button'
]; //Temerrüt Netleştirme Sayfası Temerrüt Netleştirmesi Sonuç Pop-up'ı Onay Butonu tetikleme yetkisi  UC.146-{$VGP021$}

export const MENU_TRANSMISSION_MONTHLY_SETTLEMENT_HOMEPAGE = ['Menu.TransmissionMonthlySettlementHomepage']; //İletim Pozisyon Kapama Aylık Uzlaştırma Sayfası Görüntüleme Yetkisi UC.155-{$VGP126$}
export const MENU_INDEFAULT_INFORMATION_HOMEPAGE = ['Menu.IndefaultInformationHomepage']; //Temerrüt Bilgi Sayfası Görüntüleme Yetkisi UC.157-{$VGP019$}
export const INDEFAULT_INFORMATION_HOMEPAGE_EDIT_COLUMN_VIEW = ['IndefaultInformationHomepage.Edit.Column.View']; //Temerrüt Bilgi Sayfası Temerrüt Tarihi Düzenleme Yetkisi UC.157-{$VGP127$}

export const MENU_INDEFULTANTY_MANAGEMENT_MATCHES = ['Menu.IndefultantyManagementMatches']; //Temerrüt Yönetimi Eşleşmeleri Sayfası Görüntüleme Yetkisi  UC.148-{$VGP022$}
export const MENU_INDEFULTANTY_MANAGEMENT_HOMEPAGE = ['Menu.IndefultantyManagementHomepage']; //TGH Yönetim Sayfası Görüntüleme Yetkisi UC.151-{$VGP078$}
//export const MENU_TGH_INFORMATION_HOMEPAGE = ['Menu.TghInformationtHomepage']; //TGH Bilgileri Sayfası Görüntüleme Yetkisi

export const MENU_MAKE_AMANUEL_TGH_CALL_HOMEPAGE = ['Menu.MakeAManuelTGHCallHomepage']; //Manuel TGH Çağrısı Sayfası Görüntüleme Yetkisi UC.152-{$VGP079$}
export const MAKE_AMANUEL_TGH_CALL_HOMEPAGE_MAKE_AMANUEL_TGH_CALL_BUTTON = [
  'MakeAManuelTGHCallHomepage.MakeAManuelTGHCall.Button'
]; //Manuel TGH Çağrısı Sayfası Manuel TGH Gönder Butonu Tetikleme Yetkisi UC.152-{$VGP080$}
export const MENU_TGH_INDEFULTANTY_TRACK_HOMEPAGE = ['Menu.TGHIndefultantyTrackHomepage']; //TGH Temerrüt Takip Sayfası Görüntüleme Yetkisi UC.153-{$VGP081$}

/***** RAPORLAR *****/

/* === İşlem Akışı === */
export const MENU_OPERATION_FLOW_HOMEPAGE = ['Menu.OperationFlowHomepage']; //İşlem Akışı Sayfası Görüntüleme Yetkisi UC.170-{$VGP065$}

/* === Piyasa Verileri === */
export const MENU_MARKET_DATA_REPORT_HOMEPAGE = ['Menu.MarketDataReportHomepage']; //Piyasa Verileri Sayfası Görüntüleme Yekisi UC.171-{$VGP066$}
export const MARKET_DATA_REPORT_HOMEPAGE_SEARCH_BUTTON = ['MarketDataReportHomepage.Search.Button']; //Piyasa Verileri Sayfası Sorgula Butonu Tetikleme Yetkisi UC.171-{$VGP067$}
export const MARKET_DATA_REPORT_HOMEPAGE_EXPORT_BUTTON = ['MarketDataReportHomepage.Export.Button']; //Piyasa Verileri Sayfası Dışarı Aktar Butonu Tetikleme Yetkisi UC.171-{$VGP068$}

/* === Fatura Teminat === */
export const MENU_INVOICE_COLLATERAL_HOMEPAGE = ['Menu.InvoiceCollateralHomepage']; //Fatura Teminat Sayfası Görüntüleme Yetkisi UC.172-{$VGP069$}

/* === Güncel Temerrüt Takip === */
export const MENU_RECENT_INDEFULTANTY_TRACK_HOMEPAGE = ['Menu.RecentIndefultantyTrackHomepage']; //Güncel Temerrüt Takip Sayfası Görüntüleme Yetkisi UC.173-{$VGP070$}
export const RECENT_INDEFULTANTY_TRACK_HOMEPAGE_EXPORT_BUTTON = ['RecentIndefultantyTrackHomepage.Export.Button']; //Güncel Temerrüt Takip Sayfası Dışarı Aktar Butonu Tetikleme Yetkisi UC.173-{$VGP071$}

/* === Temerrüt Takip Detay === */
export const MENU_INDEFULTANTY_TRACK_DETAIL_HOMEPAGE = ['Menu.IndefultantyTrackDetailHomepage']; //Temerrüt Takip Detay Sayfası Görüntüleme Yetkisi UC.174-{$VGP072$}
export const INDEFULTANTY_TRACK_DETAIL_HOMEPAGE_EXPORT_BUTTON = ['IndefultantyTrackDetailHomepage.Export.Button']; //Temerrüt Takip Detay Sayfası Dışarı Aktar Butonu Tetikleme Yetkisi UC.174-{$VGP073$}

/* === Uzlaştırma Kalemleri Raporu === */
export const MENU_SETTLEMENT_ITEMS_REPORT = ['Menu.SettlementItemsReport']; //Uzlaştırma Kalemleri Raporu Sayfası Görüntüleme Yetkisi UC.175-{$VGP074$}
export const SETTLEMENT_ITEMS_REPORT_EXPORT_BUTTON = ['SettlementItemsReport.Export.Button']; //Uzlaştırma Kalemleri Raporu Sayfası Dışarı Aktar Butonu Tetikleme Yetkisi UC.175-{$VGP075$}

/* === İşlem Geçmişi Raporu === */
export const MENU_TRANSACTION_HISTORY_HOMEPAGE = ['Menu.TransactionHistoryHomepage']; //İşlem Geçmişi Ana sayfası Görüntüleme Yetkisi {$VGP197$}

/***** DUYURU VE BİLDİRİMLER *****/

/* === Duyurular === */
export const MENU_ANNOUNCEMENT_HOMEPAGE = ['Menu.AnnouncementHomepage']; //Duyurular Sayfası Görüntüleme Yetkisi UC.162-{$VGP026$}

export const ANNOUNCEMENT_HOMEPAGE_ADDNEW_BUTTON = ['AnnouncementHomepage.AddNew.Button']; //Duyurular Sayfası Yeni Ekle Butonu Tetikleme Yetkisi UC.162-{$VGP027$}

export const ANNOUNCEMENT_HOMEPAGE_COPY_BUTTON = ['AnnouncementHomepage.Copy.Button']; //Duyurular Sayfası Kopyala Butonu Tetikleme Yetkisi UC.162-{$VGP028$}

export const ANNOUNCEMENT_HOMEPAGE_DETAIL_BUTTON = ['AnnouncementHomepage.Detail.Button']; //Duyurular Sayfası Detay Butonu Tetikleme Yetkisi UC.162-{$VGP029$}

export const ANNOUNCEMENT_HOMEPAGE_ANNOUNCEMENT_POPUP_DELETE_BUTTON = [
  'AnnouncementHomepage.AnnouncementDetail.Pop_up.Delete.Button'
]; //Duyurular Sayfası Duyuru Detay Pop-up'ı Duyuru Sil Butonu Tetikleme Yetkisi UC.162-{$VGP030$}

export const ANNOUNCEMENT_HOMEPAGE_ANNOUNCEMENT_POPUP_SAVE_BUTTON = [
  'AnnouncementHomepage.AnnouncementDetail.Pop_up.Save.Button'
]; //Duyurular Sayfası Duyuru Detay Pop-up'ı Kaydet Butonu Tetikleme Yetkisi UC.162-{$VGP31$}

/* === Bildirimler === */
export const MENU_NOTIFICATION_CONFIGURATION_HOMEPAGE = ['Menu.NotificationConfigurationHomepage']; //Bildirim Konfigürasyon Sayfası Görüntüleme Yetkisi UC.161-{$VGP023$}

export const NOTIFICATION_CONFIGURATION_HOMEPAGE_EDIT_BUTTON = ['NotificationConfigurationHomepage.Edit.Button']; //Bildirim Konfigürasyon Sayfası Düzenle Butonu Tetikleme Yetkisi UC.161-{$VGP024$}

export const NOTIFICATION_CONFIGURATION_HOMEPAGE_CONFIGURATION_SETTINGS_POPUP_SAVE_BUTTON = [
  'NotificationConfigurationHomepage.ConfigurationSettings.Pop_up.Save.Button'
]; //Bildirim Konfigürasyon Sayfası Bildirim Konfigürasyon Ayarı Pop-up'ı Kaydet Butonu Tetikleme Yetkisi UC.161-{$VGP025$}

/***** UZLAŞTIRMA FATURALAMA *****/

/* === Uzlaştırma Faturalama === */
export const MENU_SETTLEMENTPROCESS = ['Menu.SettlementProcess']; //Süreç Takip Sayfası Görüntüleme Yetkisi UC.121 -{$VGP188$}
export const MENU_SETTLEMENT_CALCULATIONS_AND_FINALIZATION_APPROVAL_PAGE = [
  'Menu.SettlementCalculationsAndFinalizationApprovalPage'
]; //Uzlaştırma Hesaplamaları ve Kesinleştirme Sayfası Görüntüleme Yetkisi UC.122-{$VGP189$}
export const SETTLEMENT_CALCULATIONS_AND_FINALIZATION_APPROVAL_PAGE_RUNSETTLEMENT_BUTTON_TRIGGER = [
  'SettlementCalculationsAndFinalizationApprovalPage.RunSettlement.Button.Trigger'
];
//Uzlaştırma Hesaplamaları ve Kesinleştirme Sayfası Uzlaştırmayı Çalıştır Butonu Tetikleme Yetkisi UC.122/UC.123-{$VGP190$}
export const SETTLEMENT_CALCULATIONS_AND_FINALIZATION_APPROVAL_PAGE_SHOWSETTLEMENT_BUTTON_TRIGGER = [
  'SettlementCalculationsAndFinalizationApprovalPage.ShowSettlement.Button.Trigger'
];
//Uzlaştırma Hesaplamaları ve Kesinleştirme Sayfası Uzlaştırmayı Katılımcılara Göster Butonu Tetikleme Yetkisi UC.122-{$VGP191$}
export const SETTLEMENT_CALCULATIONS_AND_FINALIZATION_APPROVAL_PAGE_SENDTOFINANCIALCONTROL_BUTTON_TRIGGER = [
  'SettlementCalculationsAndFinalizationApprovalPage.SendToFinancialControl.Button.Trigger'
];
//Uzlaştırma Hesaplamaları ve Kesinleştirme Sayfası Finans Kontrolüne Gönder Butonu Tetikleme Yetkisi UC.122-{$VGP192$}
export const SETTLEMENT_CALCULATIONS_HOMEPAGE_FINALIZATION_BUTTON = [
  'SettlementCalculationsHomepage.Finalization.Button'
]; //Uzlaştırma Kesinleştirme Butonu tetikleme Yetkisi UC.130-{$VGP173$}
export const MENU_SETTLEMENT_NOTIFICATION_HOMEPAGE = ['Menu.SettlementNotificationHomepage']; //Uzlaştırma Bildirim Sayfası Görüntüleme Yetkisi UC.132-{$VGP174$}
export const SETTLEMENT_NOTIFICATION_HOMEPAGE_RETURN_SETTLEMENT_STATEMENT_BUTTON_VIEW = [
  'SettlementNotificationHomepage.ReturnSettlementStatement.Button.View'
]; //Uzlaştırma Bildirim Sayfası Uzlaştırma Bildirimi Uygun Değildir Butonu Görüntüleme Yetkisi UC.132-{$VGP175$}
export const SETTLEMENT_NOTIFICATION_HOMEPAGE_RETROSPECTIVE_ADJUSTMENT_DETAIL_BUTTON_TRIGGER = [
  'SettlementNotificationHomepage.RetrospectiveAdjustmentDetail.Button.Trigger'
]; //Uzlaştırma Bildirim Sayfası Uzlaştırma GDDK Detayı Butonu Tetikleme Yetkisi UC.133-{$VGP176$}
export const SETTLEMENT_NOTIFICATIONT_HOMEPAGE_MOFDETAIL_BUTTON_TRIGGER = [
  'SettlementNotificationtHomepage.MOFDetail.Button.Trigger'
]; //Uzlaştırma Bildirim Sayfası Uzlaştırma PIU Detayı Butonu Tetikleme Yetkisi UC.134-{$VGP177$}
export const MENU_FINANCIAL_CONTROL_HOMEPAGE = ['Menu.FinancialControlHomepage']; //Finans Kontrolleri Sayfası Görüntüleme Yetkisi UC.135-{$VGP178$}
export const FINANCIAL_CONTROL_HOMEPAGE_FINANCIAL_CONTROL_APPROPRIATE_BUTTON_VIEW = [
  'FinancialControlHomepage.FinancialControlAppropriate.Button.View'
]; //Finans Kontrolleri Sayfası Finans Kontrolü Uygundur Butonu Görüntüleme UC.135-{$VGP179$}
export const FINANCIAL_CONTROL_HOMEPAGE_FNANCIAL_CONTROL_NOTAPPROPRIATE_BUTTON_VIEW = [
  'FinancialControlHomepage.FinancialControlNotAppropriate.Button.View'
]; //Finans Kontrolleri Sayfası Finans Kontrolü Uygun Değildir Butonu Görüntüleme Yetkisi UC.135-{$VGP180$}
export const FINANCIAL_CONTROL_HOMEPAGE_DETAIL_BUTTON_VIEW = ['FinancialControlHomepage.Detail.Button.View']; //Finans Kontrolleri Sayfası Detay Butonu Görüntüleme Yetkisi UC.135-{$VGP181$}
export const FINANCIAL_CONTROL_HOMEPAGE_ORGANISATION_DETAIL_COLUMN_VIEW = [
  'FinancialControlHomepage.OrganisationDetail.Column.View'
]; //Finans Kontrolleri Sayfası Org. Detay Kolonu Görüntüleme Yetkisi UC.135-{$VGP182$}
export const MENU_MANUEL_RETROSPECTIVE_ADJUSTMENT_HOMEPAGE = ['Menu.ManuelRetrospectiveAdjustmentHomepage']; //Düzeltme Kalemi Manuel Giriş Sayfası Görüntüleme Yetkisi UC.141-{$VGP183$}

export const MENU_INFORM_TAKASBANK_HOMEPAGE = ['Menu.InformTakasBankHomepage']; //TakasBank Bildirim Sayfası Görüntüleme Yetkisi UC.138-{$VGP151$}
export const INFORM_TAKASBANK_HOMEPAGE_SEND_DECLARATION_TO_TAKASBANK_BUTTON = [
  'InformTakasBankHomepage.SendDeclarationToTakasbank.Button'
]; //TakasBank Bildirim Sayfası Takasbank’a Bildirim Gönder Butonu Görüntüleme Yetkisi UC.139-{$VGP152$}
export const INFORM_TAKASBANK_HOMEPAGE_APPROVE_TAKASBANK_DECLARATION_BUTTON = [
  'InformTakasBankHomepage.ApproveTakasbankDeclaration.Button'
]; //TakasBank Bildirim Sayfası Takasbank’a Bildirimi Onayla Butonu Görüntüleme Yetkisi UC.140-{$VGP153$}
export const MENU_DRAW_TGH_FROM_PARTICIPANTS_HOMEPAGE = ['Menu.DrawTGHFromParticipantsHomepage']; //Katılımcılardan TGH Bedeli Çek Sayfası Görüntüleme Yetkisi UC.142-{$VGP154$}
export const DRAW_TGH_FROM_PARTICIPANTS_HOMEPAGE_ACTIVE_PASSIVE_COLUMN_VIEW = [
  'DrawTGHFromParticipantsHomepage.Active-Passive.Column.View'
]; //Katılımcılardan TGH Bedeli Çek Sayfası Aktif/Pasif Buton Kolonu Görüntüleme Yetkisi UC.142-{$VGP155$}
export const DRAW_TGH_FROM_PARTICIPANTS_HOMEPAGE_CALCULATE_BUTTON = [
  'DrawTGHFromParticipantsHomepage.Calculate.Button'
]; //Katılımcılardan TGH Bedeli Çek Sayfası Hesapla Butonu Görüntüleme Yetkisi UC.142-{$VGP156$}
export const MENU_DEPOSE_BACK_TGH_TO_PARTICIPANTS_HOMEPAGE = ['Menu.DeposeBackTGHToParticipantsHomepage']; //Katılımcılara TGH Bedellerini İade Et Sayfası Görüntüleme Yetkisi UC.143-{$VGP157$}
export const DEPOSE_BACK_TGH_TO_PARTICIPANTS_HOMEPAGE_ACTIVE_PASSIVE_COLUMN_VIEW = [
  'DeposeBackTGHToParticipantsHomepage.Active-Passive.Column.View'
]; //Katılımcılara TGH Bedellerini İade Et Sayfası Aktif/Pasif Buton Kolonu Görüntüleme Yetkisi UC.143-{$VGP158$}
export const DEPOSE_BACK_TGH_TO_PARTICIPANTS_HOMEPAGE_CALCULATE_BUTTON = [
  'DeposeBackTGHToParticipantsHomepage.Calculate.Button'
]; //Katılımcılara TGH Bedellerini İade Et Sayfası Hesapla Butonu Görüntüleme Yetkisi UC.143-{$VGP159$}
export const MENU_VIEW_INCOICE_ITEMS_HOMEPAGE = ['Menu.ViewIncoiceItemsHomepage']; //Fatura Kalemleri Görüntüle Sayfası Görüntüleme Yetkisi UC.145-{$VGP160$}

/***** TEMİNAT *****/
/* === Teminat === */
export const MENU_COLLATERAL_SUMMARY_HOME_PAGE = ['Menu.CollateralSummaryHomepage']; //Teminat Özet Sayfası Görüntüleme Yetkisi UC.104-{$VGP172$}
export const MENU_COLLATERAL_TRACK_HOMEPAGE = ['Menu.CollateralTrackHomepage']; //Teminat Takip Sayfası Görüntüleme Yetkisi UC.099-{$VGP140$}
export const COLLATERAL_TRACK_HOMEPAGE_INDEFAULTDETAIL_BUTTON = ['CollateralTrackHomepage.IndefaultDetail.Button']; //Teminat Takip Sayfası Temerrüt Detay Butonu Görüntüleme Yetkisi UC.100-{$VGP141$}
export const COLLATERAL_TRACK_HOMEPAGE_TGH_DETAIL_BUTTON = ['CollateralTrackHomepage.TGHDetail.Button']; //Teminat Takip Sayfası TGH Detay Butonu Görüntüleme Yetkisi UC.101-{$VGP142$}
export const COLLATERAL_TRACK_HOMEPAGE_MANUELCONTROL_COLUMN_VIEW = [
  'CollateralTrackHomepage.ManuelControl.Column.View'
]; //Teminat Takip Sayfası Manuel Kontrol Kolunu Görüntüleme Yetkisi UC.102-{$VGP143$}
export const COLLATERAL_TRACK_HOMEPAGE_PROCESSLOG_TABLE_VIEW = ['CollateralTrackHomepage.ProcessLog.Table.View']; //Teminat Takip Sayfası İşlem Logu Kısmı Görüntüleme Yetkisi UC.103-{$VGP144$}
export const MENU_COLLATERAL_SIMULATIONHOMEPAGE = ['Menu.CollateralSimulationHomepage']; //Teminat Simülasyon Sayfası Görüntüleme Yetkisi UC.106-{$VGP145$}
export const COLLATERAL_SIMULATIONHOMEPAGE_DETAIL_POSITIONS_TABLE_VIEW = [
  'CollateralSimulationHomepage.DetailPositions.Table.View'
]; //Teminat Simülasyon Sayfası Teminat Detay Pozisyonlar Kısmı Görüntüleme Yetkisi UC.107-{$VGP146$}
export const COLLATERAL_SIMULATIONHOMEPAGE_DETAIL_ORDERS_TABLE_VIEW = [
  'CollateralSimulationHomepage.DetailOrders.Table.View'
]; //Teminat Simülasyon Sayfası Teminat Detay Teklifler Kısmı Görüntüleme Yetkisi UC.108-{$VGP147$}
export const COLLATERAL_SIMULATIONHOMEPAGE_DETAIL_ORDERSIMULATION_TABLE_VIEW = [
  'CollateralSimulationHomepage.DetailOrderSimulation.Table.View'
]; //Teminat Simülasyon Sayfası Teminat Detay Teklif Simülasyon Kısmı Görüntüleme Yetkisi UC.109-{$VGP148$}
export const COLLATERAL_SIMULATIONHOMEPAGE_DETAIL_ORDERSIMULATION_ORDERADD_BUTTON = [
  'CollateralSimulationHomepage.DetailOrderSimulation.OrderAdd.Button'
]; //Teminat Simülasyon Sayfası Teminat Detay Teklif Simülasyon Teklif Ekle Butonu Görüntüleme Yetkisi UC.110-{$VGP149$}
export const COLLATERAL_SIMULATIONHOMEPAGE_DETAIL_ORDERSIMULATION_ORDERDISCARD_BUTTON = [
  'CollateralSimulationHomepage.DetailOrderSimulation.OrderDiscard.Button'
]; //Teminat Simülasyon Sayfası Teminat Detay Teklif Simülasyon Teklif Kaldır Butonu Görüntüleme Yetkisi UC.111-{$VGP150$}

/***** YÖNETİCİ İŞLEMLERİ *****/
/* === Yönetici İşlemleri === */
export const MENU_MANAGEMENT_OPERATIONS_HOMEPAGE = ['Menu.ManagementOperationsHomepage']; //Yönetici İşlemleri Navigasyon Menü Görüntüleme Yetkisi UC.179-{$VGP128$}
export const MENU_CONTRACT_CREATE_PARAMETERS_HOMEPAGE = ['Menu.ContractCreateParametersHomepage']; //Kontrat Oluşturma Parametreleri Ekranı Görüntüleme Yetkisi UC.179-{$VGP082$}
export const CONTRACT_CREATE_PARAMETERS_HOMEPAGE_UPDATE_COLUMN_VIEW = [
  'ContractCreateParametersHomepage.Update.Column.View'
]; //Kontrat Oluşturma Parametreleri Sayfası Güncelle Kolonu Görüntüleme Yetkisi UC.179-{$VGP087$}
export const CONTRACT_CREATE_PARAMETERS_HOMEPAGE_UPDATE_BUTTON = ['ContractCreateParametersHomepage.Update.Button']; //Kontrat Oluşturma Parametreleri Sayfası Güncelle Butonu Tetikleme Yetkisi UC.179-{$VGP088$}
export const MENU_CONTRACT_AND_SESSION_OBSERVATION_HOMEPAGE = ['Menu.ContractAndSessionObservationHomepage']; //Kontrat ve Seans İzleme Sayfası Görüntüleme Yetkisi UC.180-{$VGP090$}
export const CONTRACT_AND_SESSION_OBSERVATION_HOMEPAGE_UPDATE_COLUMN_VIEW = [
  'ContractAndSessionObservationHomepage.Update.Column.View'
]; //Kontrat ve Seans İzleme Sayfası Güncelle Kolonu Görüntüleme Yetkisi UC.180-{$VGP091$}
export const CONTRACT_AND_SESSION_OBSERVATION_HOMEPAGE_UPDATE_BUTTON = [
  'ContractAndSessionObservationHomepage.Update.Button'
]; //Kontrat ve Seans İzleme Sayfası Güncelle Butonu Tetikleme Yetkisi UC.180-{$VGP092$}
export const CONTRACT_AND_SESSION_OBSERVATION_HOMEPAGE_SUSPENDING_THE_SESSION_BUTTON = [
  'ContractAndSessionObservationHomepage.SuspendingTheSession.Button'
]; //Kontrat ve Seans İzleme Sayfası Askıya Al Butonu Tetikleme Yetkisi UC.181-{$VGP094$}
export const CONTRACT_AND_SESSION_OBSERVATION_HOMEPAGE_REMOVESUSPEND_BUTTON = [
  'ContractAndSessionObservationHomepage.RemoveSuspend.Button'
]; //Kontrat ve Seans İzleme Sayfası Askıdan Çıkar Butonu Tetikleme Yetkisi UC.181-{$VGP096$}
export const MENU_OFFICIAL_HOLIDAYS_HOMEPAGE = ['Menu.OfficialHolidaysHomepage']; //Resmi Tatil Günleri Sayfası Görüntüleme Yetkisi UC.182-{$VGP098$}
export const OFFICIAL_HOLIDAYSHOMEPAGE_IMPORT_OFFICIAL_HOLIDAYS_FROM_STP_BUTTON = [
  'OfficialHolidaysHomepage.ImportOfficialHolidaysFromSTP.Button'
]; //Resmi Tatil Günleri Sayfası STP'den Resmi Tatil Bilgilerini Al Butonu Tetikleme Yetkisi UC.182-{$VGP099$}

export const MENU_GENERAL_PARAMETERS_HOMEPAGE = ['Menu.GeneralParametersHomepage']; //Genel Parametreler Sayfası Görüntüleme Yetkisi UC.183-{$VGP100$}
export const GENERAL_PARAMETERS_MARKET_OPERATIONS_HOMEPAGE_GENERAL_TAB_VIEW = [
  'GeneralParametersMarketOperationsHomepage.General.Tab.View'
]; //Parametreler Sayfası Piyasa İşlemleri Genel Kısmı Görüntüleme Yetkisi UC.183-{$VGP101$}
export const GENERAL_PARAMETERS_MARKET_OPERATIONS_HOMEPAGE_GENERAL_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersMarketOperationsHomepage.General.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Piyasa İşlemleri Genel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP102$}
export const GENERAL_PARAMETERS_MARKET_OPERATIONS_HOMEPAGE_GENERAL_TAB_SCHEDULED_JOBS_EDIT_COLUMN_VIEW = [
  'GeneralParametersMarketOperationsHomepage.General.Tab.ScheduledJobs.Edit.Column.View'
]; //Parametreler Sayfası Piyasa İşlemleri Genel Kısmı Zamanlı Görevler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP103$}
export const GENERAL_PARAMETERS_MARKET_OPERATIONS_HOMEPAGE_DAILY_TAB_VIEW = [
  'GeneralParametersMarketOperationsHomepage.Daily.Tab.View'
]; //Parametreler Sayfası Piyasa İşlemleri Güne Özel Kısmı Görüntüleme Yetkisi UC.183-{$VGP104$}
export const GENERAL_PARAMETERS_MARKET_OPERATIONS_HOMEPAGE_DAILY_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersMarketOperationsHomepage.Daily.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Piyasa İşlemleri Güne Özel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP105$}
export const GENERAL_PARAMETERS_MARKET_OPERATIONS_HOMEPAGE_DAILY_TAB_SCHEDULED_JOBS_EDIT_COLUMN_VIEW = [
  'GeneralParametersMarketOperationsHomepage.Daily.Tab.ScheduledJobs.Edit.Column.View'
]; //Parametreler Sayfası Piyasa İşlemleri Güne Özel Kısmı Zamanlı Görevler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP106$}
export const GENERAL_PARAMETERS_COLLATERAL_HOMEPAGE_GENERAL_TAB_VIEW = [
  'GeneralParametersCollateralHomepage.General.Tab.View'
]; //Parametreler Sayfası Teminat Genel Kısmı Görüntüleme Yetkisi UC.183-{$VGP107$}
export const GENERAL_PARAMETERS_COLLATERAL_HOMEPAGE_GENERAL_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersCollateralHomepage.General.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Teminat Genel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP108}
export const GENERAL_PARAMETERS_COLLATERAL_HOMEPAGE_DAILY_TAB_VIEW = [
  'GeneralParametersCollateralHomepage.Daily.Tab.View'
]; //Parametreler Sayfası Teminat Güne Özel Kısmı Görüntüleme Yetkisi UC.183-{$VGP109$}
export const GENERAL_PARAMETERS_COLLATERAL_HOMEPAGE_DAILY_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersCollateralHomepage.Daily.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Teminat Güne Özel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP110$}
export const GENERAL_PARAMETERS_SETTLEMENTAND_INVOICING_HOMEPAGE_GENERAL_TAB_VIEW = [
  'GeneralParametersSettlementandInvoicingHomepage.General.Tab.View'
]; //Parametreler Sayfası Uzlaştırma ve faturalama Genel Kısmı Görüntüleme Yetkisi UC.183-{$VGP111$}
export const GENERAL_PARAMETERS_SETTLEMENTAND_INVOICING_HOMEPAGE_GENERAL_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersSettlementandInvoicingHomepage.General.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Uzlaştırma ve faturalama Genel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP112$}
export const GENERAL_PARAMETERS_SETTLEMENTAND_INVOICING_HOMEPAGE_DAILY_TAB_VIEW = [
  'GeneralParametersSettlementandInvoicingHomepage.Daily.Tab.View'
]; //Parametreler Sayfası Uzlaştırma ve faturalama Güne Özel Kısmı Görüntüleme Yetkisi UC.183-{$VGP113$}
export const GENERAL_PARAMETERS_SETTLEMENTAND_INVOICING_HOMEPAGE_DAILY_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersSettlementandInvoicingHomepage.Daily.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Uzlaştırma ve faturalama Güne Özel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP114$}
export const GENERAL_PARAMETERS_INDEFAULT_HOMEPAGE_GENERAL_TAB_VIEW = [
  'GeneralParametersIndefaultHomepage.General.Tab.View'
]; //Parametreler Sayfası Temerrüt TGH Genel Kısmı Görüntüleme Yetkisi UC.183-{$VGP115$}
export const GENERAL_PARAMETERS_INDEFAULT_HOMEPAGE_GENERAL_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersIndefaultHomepage.General.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Temerrüt TGH Genel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP116$}
export const GENERAL_PARAMETERS_INDEFAULT_HOMEPAGE_DAILY_TAB_VIEW = [
  'GeneralParametersIndefaultHomepage.Daily.Tab.View'
]; //Parametreler Sayfası Temerrüt TGH Güne Özel Kısmı Görüntüleme Yetkisi UC.183-{$VGP117$}
export const GENERAL_PARAMETERS_INDEFAULT_HOMEPAGE_DAILY_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersIndefaultHomepage.Daily.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Temerrüt TGH Güne Özel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP118$}
export const GENERAL_PARAMETERS_ANNOUNCEMENTS_HOMEPAGE_GENERAL_TAB_VIEW = [
  'GeneralParametersAnnouncementsHomepage.General.Tab.View'
]; //Parametreler Sayfası Duyurular Genel Kısmı Görüntüleme Yetkisi UC.183-{$VGP119$}
export const GENERAL_PARAMETERS_ANNOUNCEMENTS_HOMEPAGE_GENERAL_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersAnnouncementsHomepage.General.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Duyurular Genel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP120$}
export const GENERAL_PARAMETERS_ANNOUNCEMENTS_HOMEPAGE_DAILY_TAB_VIEW = [
  'GeneralParametersAnnouncementsHomepage.Daily.Tab.View'
]; //Parametreler Sayfası Duyurular Güne Özel Kısmı Görüntüleme Yetkisi UC.183-{$VGP121$}
export const GENERAL_PARAMETERS_ANNOUNCEMENTS_HOMEPAGE_DAILY_TAB_PARAMETERS_EDIT_COLUMN_VIEW = [
  'GeneralParametersAnnouncementsHomepage.Daily.Tab.Parameters.Edit.Column.View'
]; //Parametreler Sayfası Duyurular Güne Özel Kısmı Parametreler Düzenleme Kolonu Görüntüleme Yetkisi UC.183-{$VGP122$}
export const MENU_SCHEDULED_JOBS_OBSERVATION_HOMEPAGE = ['Menu.ScheduledJobsObservationHomepage']; //Zamanlı Görevler İzleme Sayfası Görüntüleme Yetkisi UC.184-{$VGP123$}
export const SCHEDULED_JOBS_OBSERVATION_HOMEPAGE_MANUELRUN_COLUMN_VIEW = [
  'ScheduledJobsObservationHomepage.ManuelRun.Column.View'
]; //Zamanlı Görevler İzleme Sayfası Manuel Çalıştırma Kolonu Görüntüleme Yetkisi UC.185-{$VGP124$}
