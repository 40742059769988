export const arrayFindIndexById = (arr, filterKey) => {
  const result = arr?.findIndex((item) => item?.id === filterKey);

  return result;
};
export const arrayFindNameById = (arr, filterKey) => {
  let result = '';

  arr?.map((item) => item?.id === filterKey && (result = item?.name));

  return result;
};
export const arrayFindIndexByAny = (arr, mainKey, filterKey) => {
  const result = arr?.findIndex((item) => item?.[mainKey] === filterKey);

  return result;
};

export const isArray = (value) => {
  const result = Array?.isArray(value);

  return result;
};
