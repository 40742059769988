import { Fragment, useState } from 'react';
import { Row, Col } from 'antd';

import { authUser } from 'src/store/actions/user/auth';
import { useDispatch } from 'react-redux';

// Import Assets
import epiasLogo from 'src/assets/img/epias-transparent-beyaz.png';
import Button from 'src/components-new/cta/button/Button';
import { BUTTON_SIZES } from 'src/common/constants/sizes/buttonSizes';
import { STATUS } from 'src/common/constants/status/status';
import Select from 'src/components-new/form-elements/select/Select';
import FormApp from 'src/components-new/form-elements/form/FormApp';

function FakeJobLogin() {
  const dispatch = useDispatch();

  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [error, setError] = useState<boolean | null>(null);
  const handleChange = (value) => {
    setSelectedUser(value);
  };
  const handleLogin = () => {
    if (!selectedUser) {
      setError(true);
    } else {
      setError(false);
      authUser(dispatch, selectedUser);
    }
  };

  const dummySelectData = [
    {
      name: 'VGPPK1',
      id: 'VGPPK1'
    },
    {
      name: 'VGPPK1Y',
      id: 'VGPPK1Y'
    },
    {
      name: 'VGPPK2',
      id: 'VGPPK2'
    },
    {
      name: 'VGPPK3',
      id: 'VGPPK3'
    },
    {
      name: 'VGPPK4',
      id: 'VGPPK4'
    },
    {
      name: 'VGPPK5',
      id: 'VGPPK5'
    },
    {
      name: 'VGPUZMAN',
      id: 'VGPUZMAN'
    },
    {
      name: 'VGPUZMAN2',
      id: 'VGPUZMAN2'
    }
  ];

  return (
    <Fragment>
      <div>
        <div className="logo-login">
          <img src={epiasLogo} height="65px" alt="Epiaş Logo" />
        </div>

        <div className="login-container">
          <Row>
            <Col span={24}>
              <div className="login-title">GİRİŞ YAP</div>

              <FormApp name="loginForm">
                <Select
                  label="GLOBAL.FORM_ELEMENTS.LABELS.USER"
                  data={dummySelectData}
                  name="selectBasic"
                  listHeight={300}
                  onChange={handleChange}
                />
              </FormApp>

              {error && <div>Lütfen kullanıcı seçiniz.</div>}
              <Row justify="center">
                <Button
                  status={STATUS.WARNING}
                  sizes={BUTTON_SIZES.MD}
                  label="GLOBAL.FORM_ELEMENTS.CTA.LOGIN"
                  name="login"
                  onClick={() => handleLogin()}
                  block={true}
                />
              </Row>
            </Col>
          </Row>
        </div>
        <p className="footer-text">Vadeli Gaz Piyasası</p>
      </div>
    </Fragment>
  );
}

export default FakeJobLogin;
