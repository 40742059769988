const registrationValue = process.env.REACT_APP_API_REGISTRATION_SERVICE!.toString();
const marketsBackendValue = process.env.REACT_APP_API_MARKETS_BACKEND_SERVICE!.toString();
const marketsMatchingEngineValue = process.env.REACT_APP_API_MARKETS_MATCHING_ENGINE_SERVICE!.toString();
const marketsDataUpdaterValue = process.env.REACT_APP_API_MARKETS_DATA_UPDATER_SERVICE!.toString();
const jobsValue = process.env.REACT_APP_API_JOBS_SERVICE!.toString();
const billingValue = process.env.REACT_APP_API_BILLING_SERVICE!.toString();
const notifierAdminValue = process.env.REACT_APP_API_NOTIFIER_ADMIN_SERVICE!.toString();

export class SERVICES {
  static readonly REGISTRATION_SERVICE = new SERVICES(registrationValue, 'REGISTRATION_SERVICE');
  static readonly MARKETS_BACKEND_SERVICE = new SERVICES(marketsBackendValue, 'MARKETS_BACKEND_SERVICE');
  static readonly MARKETS_MATCHING_ENGINE_SERVICE = new SERVICES(
    marketsMatchingEngineValue,
    'MARKETS_MATCHING_ENGINE_SERVICE'
  );
  static readonly MARKETS_DATA_UPDATER_SERVICE = new SERVICES(marketsDataUpdaterValue, 'MARKETS_DATA_UPDATER_SERVICE');
  static readonly JOBS_SERVICE = new SERVICES(jobsValue, 'JOBS_SERVICE');
  static readonly BILLING_SERVICE = new SERVICES(billingValue, 'BILLING_SERVICE');
  static readonly NOTIFIER_ADMIN_SERVICE = new SERVICES(notifierAdminValue, 'NOTIFIER_ADMIN_SERVICE');

  // private to disallow creating other instances of this type
  private constructor(private readonly key: string, public readonly value: any) {}

  toString() {
    return this.key;
  }
}
