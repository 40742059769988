// Import Constants
import { ICON_LIST } from 'src/common/constants/icon/iconList';
import { all_users, only_epias } from 'src/common/constants/roles-rights/roles';
import {
  CONSTMENU_ORGANISATION_SITUATION_TRACK_HOMEPAGE,
  HOMEPAGE_MYMATCHES_OBJECTION_BUTTON,
  MENU_ANNOUNCEMENT_HOMEPAGE,
  MENU_BASE_PRICE_HOMEPAGE,
  MENU_COLLATERAL_SIMULATIONHOMEPAGE,
  MENU_COLLATERAL_SUMMARY_HOME_PAGE,
  MENU_COLLATERAL_TRACK_HOMEPAGE,
  MENU_CONTRACT_AND_SESSION_OBSERVATION_HOMEPAGE,
  MENU_CONTRACT_CREATE_PARAMETERS_HOMEPAGE,
  MENU_DEPOSE_BACK_TGH_TO_PARTICIPANTS_HOMEPAGE,
  MENU_DRAW_TGH_FROM_PARTICIPANTS_HOMEPAGE,
  MENU_FINANCIAL_CONTROL_HOMEPAGE,
  MENU_GENERAL_PARAMETERS_HOMEPAGE,
  MENU_HOMEPAGE,
  MENU_INDEFAULT_INFORMATION_HOMEPAGE,
  MENU_INDEFULTANTY_MANAGEMENT_HOMEPAGE,
  MENU_INDEFULTANTY_MANAGEMENT_MATCHES,
  MENU_INDEFULTANTY_OFFSET_HOMEPAGE,
  MENU_INDEFULTANTY_TRACK_DETAIL_HOMEPAGE,
  MENU_INDICATOR_PRICE_HOMEPAGE,
  MENU_INFORM_TAKASBANK_HOMEPAGE,
  MENU_INVOICE_COLLATERAL_HOMEPAGE,
  MENU_MANAGEMENT_OPERATIONS_HOMEPAGE,
  MENU_MANUEL_RETROSPECTIVE_ADJUSTMENT_HOMEPAGE,
  MENU_MARKET_DATA_REPORT_HOMEPAGE,
  MENU_MARKET_RESULTS_HOME_PAGE,
  MENU_NOTIFICATION_CONFIGURATION_HOMEPAGE,
  MENU_OFFICIAL_HOLIDAYS_HOMEPAGE,
  MENU_OPERATION_FLOW_HOMEPAGE,
  MENU_ORDER_AND_MATCHES_HOMEPAGE,
  MENU_ORGANIZATION_PAGE,
  MENU_RECENT_INDEFULTANTY_TRACK_HOMEPAGE,
  MENU_SCHEDULED_JOBS_OBSERVATION_HOMEPAGE,
  MENU_SETTLEMENTPROCESS,
  MENU_SETTLEMENT_CALCULATIONS_AND_FINALIZATION_APPROVAL_PAGE,
  MENU_SETTLEMENT_ITEMS_REPORT,
  MENU_SETTLEMENT_NOTIFICATION_HOMEPAGE,
  MENU_TGH_INDEFULTANTY_TRACK_HOMEPAGE,
  MENU_TRANSACTION_HISTORY_HOMEPAGE,
  MENU_TRANSMISSION_MONTHLY_SETTLEMENT_HOMEPAGE,
  MENU_VGP_OPERATIONS_HOMEPAGE,
  MENU_VIEW_INCOICE_ITEMS_HOMEPAGE,
  POSITION_LIMIT_HOMEPAGE_VIEW
} from 'src/common/constants/roles-rights/rights';
import { MODULE_ENUMS, PAGE_ENUMS } from 'src/common/constants/page-module-enums/pageModuleEnums';

export const MENU = [
  {
    key: 'home',
    title: 'NAVIGATION_MENU.HOME',
    enum: PAGE_ENUMS.HOME,
    parentenum: MODULE_ENUMS.MARKET_TRANSACTIONS,
    url: '/',
    icon: ICON_LIST.HOME,
    hasProfile: all_users,
    hasRight: MENU_HOMEPAGE
  },
  {
    key: 'kayit_islemleri',
    icon: ICON_LIST.ADDRESS_CARD,
    title: 'NAVIGATION_MENU.REGISTRATION_PROCEDURES',
    enum: MODULE_ENUMS.REGISTRATION_PROCEDURES,
    // YETKİ50
    hasProfile: all_users,
    children: [
      {
        key: 'kayit:1',
        title: 'NAVIGATION_MENU.ORGANISATIONS',
        enum: PAGE_ENUMS.ORGANISATIONS,
        url: '/organizasyonlar',
        hasProfile: all_users,
        hasRight: MENU_ORGANIZATION_PAGE
      }
    ]
  },
  {
    key: 'piyasa_islemleri',
    icon: ICON_LIST.CHART_BAR,
    title: 'NAVIGATION_MENU.MARKET_TRANSACTIONS',
    enum: MODULE_ENUMS.MARKET_TRANSACTIONS,
    // YETKİ51
    hasProfile: all_users,
    children: [
      {
        key: 'piyasa:1',
        title: 'NAVIGATION_MENU.OFFERS_AND_MATCHES',
        enum: PAGE_ENUMS.OFFERS_AND_MATCHES,
        url: '/teklif-ve-eslesmeler',
        hasProfile: all_users,
        hasRight: MENU_ORDER_AND_MATCHES_HOMEPAGE
      },
      {
        key: 'piyasa:2',
        title: 'NAVIGATION_MENU.MARKET_RESULTS',
        enum: PAGE_ENUMS.MARKET_RESULTS,
        url: '/piyasa-sonuclari',
        hasProfile: all_users,
        hasRight: MENU_MARKET_RESULTS_HOME_PAGE
      },
      {
        key: 'piyasa:3',
        title: 'NAVIGATION_MENU.ORGANISATION_STATUS_TRACKING',
        enum: PAGE_ENUMS.ORGANISATION_STATUS_TRACKING,
        url: '/organizasyon-durum-takip',
        hasProfile: all_users,
        hasRight: CONSTMENU_ORGANISATION_SITUATION_TRACK_HOMEPAGE
      },
      {
        key: 'piyasa:4',
        title: 'NAVIGATION_MENU.POSITION_LIMIT',
        enum: PAGE_ENUMS.POSITION_LIMIT,
        url: '/pozisyon-limiti',
        hasProfile: all_users,
        hasRight: POSITION_LIMIT_HOMEPAGE_VIEW
      },
      {
        key: 'piyasa:5',
        title: 'NAVIGATION_MENU.GGF',
        enum: PAGE_ENUMS.GGF,
        url: '/ggf',
        hasProfile: all_users,
        hasRight: MENU_INDICATOR_PRICE_HOMEPAGE
      },
      {
        key: 'piyasa:6',
        title: 'NAVIGATION_MENU.BASE_PRICE',
        enum: PAGE_ENUMS.BASE_PRICE,
        url: '/baz-fiyat',
        hasProfile: all_users,
        hasRight: MENU_BASE_PRICE_HOMEPAGE
      },
      {
        key: 'piyasa:7',
        title: 'NAVIGATION_MENU.OBJECTIONS',
        enum: PAGE_ENUMS.OBJECTIONS,
        url: '/itirazlar',
        hasProfile: all_users,
        hasRight: HOMEPAGE_MYMATCHES_OBJECTION_BUTTON
      }
    ]
  },
  {
    key: 'teminat_islemleri',
    icon: ICON_LIST.BRIEFCASE,
    title: 'NAVIGATION_MENU.GUARANTEE_PROCEDURES',
    enum: MODULE_ENUMS.GUARANTEE_PROCEDURES,
    // YETKİ56
    hasProfile: all_users,
    children: [
      {
        key: 'teminat:2',
        title: 'NAVIGATION_MENU.COLLATERAL_TRACKING',
        enum: PAGE_ENUMS.COLLATERAL_TRACKING,
        url: '/teminat-takip',
        hasProfile: all_users,
        hasRight: MENU_COLLATERAL_TRACK_HOMEPAGE
      },
      {
        key: 'teminat:3',
        title: 'NAVIGATION_MENU.COLLATERAL_SUMMARY',
        enum: PAGE_ENUMS.COLLATERAL_SUMMARY,
        url: '/teminat-ozet',
        hasProfile: all_users,
        hasRight: MENU_COLLATERAL_SUMMARY_HOME_PAGE
      },
      {
        key: 'teminat:4',
        title: 'NAVIGATION_MENU.COLLATERAL_SIMULATION',
        enum: PAGE_ENUMS.COLLATERAL_SIMULATION,
        url: '/teminat-simulasyon',
        hasProfile: all_users,
        hasRight: MENU_COLLATERAL_SIMULATIONHOMEPAGE
      }
    ]
  },
  {
    key: 'uzlastirma_ve_faturalama_islemleri',
    icon: ICON_LIST.FILE_ALT,
    title: 'NAVIGATION_MENU.SETTLEMENT_AND_INVOICING_PROCEDURES',
    enum: MODULE_ENUMS.SETTLEMENT_AND_INVOICING_PROCEDURES,
    // YETKİ57
    hasProfile: all_users,
    children: [
      {
        key: 'uzlastirma_ve_faturalama:1',
        title: 'NAVIGATION_MENU.CONCILIATION_PROCESS_TRACKING',
        enum: PAGE_ENUMS.CONCILIATION_PROCESS_TRACKING,
        url: '/uzlastirma-surec-takip',
        hasProfile: only_epias,
        hasRight: MENU_SETTLEMENTPROCESS
      },
      {
        key: 'uzlastirma_ve_faturalama:2',
        title: 'NAVIGATION_MENU.SETTLEMENT_CALCULATIONS',
        enum: PAGE_ENUMS.SETTLEMENT_CALCULATIONS,
        url: '/uzlastirma-hesaplamalari',
        hasProfile: only_epias,
        hasRight: MENU_SETTLEMENT_CALCULATIONS_AND_FINALIZATION_APPROVAL_PAGE
      },
      {
        key: 'uzlastirma_ve_faturalama:3',
        title: 'NAVIGATION_MENU.CORRECTION_PEN_INTRODUCTION',
        enum: PAGE_ENUMS.CORRECTION_PEN_INTRODUCTION,
        url: '/duzeltme-kalemi-giris',
        hasProfile: only_epias,
        hasRight: MENU_MANUEL_RETROSPECTIVE_ADJUSTMENT_HOMEPAGE
      },
      {
        key: 'uzlastirma_ve_faturalama:4',
        title: 'NAVIGATION_MENU.CONCILIATION_NOTICE_PAGE',
        enum: PAGE_ENUMS.CONCILIATION_NOTICE_PAGE,
        url: '/uzlastirma-bildirim',
        hasProfile: all_users,
        hasRight: MENU_SETTLEMENT_NOTIFICATION_HOMEPAGE
      },
      {
        key: 'uzlastirma_ve_faturalama:5',
        title: 'NAVIGATION_MENU.FINANCIAL_CONTROLS_PAGE',
        enum: PAGE_ENUMS.FINANCIAL_CONTROLS_PAGE,
        url: '/finans-kontrolleri',
        hasProfile: only_epias,
        hasRight: MENU_FINANCIAL_CONTROL_HOMEPAGE
      },
      {
        key: 'uzlastirma_ve_faturalama:6',
        title: 'NAVIGATION_MENU.TAKASBANK_NOTICE',
        enum: PAGE_ENUMS.TAKASBANK_NOTICE,
        url: '/takasbank-bildirim',
        hasProfile: only_epias,
        hasRight: MENU_INFORM_TAKASBANK_HOMEPAGE
      },
      {
        key: 'uzlastirma_ve_faturalama:7',
        title: 'NAVIGATION_MENU.WITHDRAW_FROM_TGH_ACCOUNTS',
        enum: PAGE_ENUMS.WITHDRAW_FROM_TGH_ACCOUNTS,
        url: '/tgh-hesaplarindan-cek',
        hasProfile: only_epias,
        hasRight: MENU_DRAW_TGH_FROM_PARTICIPANTS_HOMEPAGE
      },
      {
        key: 'uzlastirma_ve_faturalama:8',
        title: 'NAVIGATION_MENU.REFUND_TO_TGH_ACCOUNTS',
        enum: PAGE_ENUMS.REFUND_TO_TGH_ACCOUNTS,
        url: '/katilimcilara-tgh-bedeli-iade-et',
        hasProfile: only_epias,
        hasRight: MENU_DEPOSE_BACK_TGH_TO_PARTICIPANTS_HOMEPAGE
      },
      {
        key: 'uzlastirma_ve_faturalama:9',
        title: 'NAVIGATION_MENU.VIEW_INVOICE_ITEMS',
        enum: PAGE_ENUMS.VIEW_INVOICE_ITEMS,
        url: '/fatura-kalemleri-goruntuleme',
        hasProfile: all_users,
        hasRight: MENU_VIEW_INCOICE_ITEMS_HOMEPAGE
      },
      {
        key: 'uzlastirma_ve_faturalama:10',
        title: 'NAVIGATION_MENU.CONCILIATION_BASIS',
        enum: PAGE_ENUMS.CONCILIATION_BASIS,
        url: '/uzlastirma-donemi-bazinda',
        hasProfile: only_epias,
        hasRight: MENU_SETTLEMENTPROCESS
      }
    ]
  },
  {
    key: 'temerrut_tgh',
    icon: ICON_LIST.COINS,
    title: 'NAVIGATION_MENU.DEFAULT_TRANSACTIONS',
    enum: MODULE_ENUMS.DEFAULT_TRANSACTIONS,
    // YETKİ58
    hasProfile: all_users,
    children: [
      {
        key: 'temerrut_tgh:1',
        title: 'NAVIGATION_MENU.DEFAULT_NETTING',
        enum: PAGE_ENUMS.DEFAULT_NETTING,
        url: '/temerrut-netlestirme',
        hasRight: MENU_INDEFULTANTY_OFFSET_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'temerrut_tgh:2',
        title: 'NAVIGATION_MENU.DEFAULT_MANAGEMENT_MATCHES',
        enum: PAGE_ENUMS.DEFAULT_MANAGEMENT_MATCHES,
        url: '/temerrut-yonetimi-eslestirmeleri',
        hasRight: MENU_INDEFULTANTY_MANAGEMENT_MATCHES,
        hasProfile: only_epias
      },
      {
        key: 'temerrut_tgh:3',
        title: 'NAVIGATION_MENU.VGP_TRANSACTIONS',
        enum: PAGE_ENUMS.VGP_TRANSACTIONS,
        url: '/vgp-islemleri',
        hasRight: MENU_VGP_OPERATIONS_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'temerrut_tgh:4',
        title: 'NAVIGATION_MENU.TGH_MANAGEMENT',
        enum: PAGE_ENUMS.TGH_MANAGEMENT,
        url: '/tgh-yonetim',
        hasRight: MENU_INDEFULTANTY_MANAGEMENT_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'temerrut_tgh:5',
        title: 'NAVIGATION_MENU.TGH_INFORMATION',
        enum: PAGE_ENUMS.TGH_INFORMATION,
        url: '/tgh-bilgileri',
        hasRight: MENU_ORDER_AND_MATCHES_HOMEPAGE,
        hasProfile: all_users
      },
      {
        key: 'temerrut_tgh:6',
        title: 'NAVIGATION_MENU.TGH_DEFAULT_TRACKING',
        enum: PAGE_ENUMS.TGH_DEFAULT_TRACKING,
        url: '/tgh-temerrut-takip',
        hasRight: MENU_TGH_INDEFULTANTY_TRACK_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'temerrut_tgh:7',
        title: 'NAVIGATION_MENU.POSITION_CLOSING_SUMMARY',
        enum: PAGE_ENUMS.POSITION_CLOSING_SUMMARY,
        url: '/iletim-pozisyonu-kapama-aylik-uzlastirma',
        hasRight: MENU_TRANSMISSION_MONTHLY_SETTLEMENT_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'temerrut_tgh:8',
        title: 'NAVIGATION_MENU.DEFAULT_INFORMATION',
        enum: PAGE_ENUMS.DEFAULT_INFORMATION,
        url: '/temerrut-bilgi',
        hasRight: MENU_INDEFAULT_INFORMATION_HOMEPAGE,
        hasProfile: only_epias
      }
    ]
  },
  {
    key: 'duyurular',
    icon: ICON_LIST.BULLHORN,
    title: 'NAVIGATION_MENU.ANNOUNCEMENTS',
    enum: PAGE_ENUMS.ANNOUNCEMENTS,
    parentenum: MODULE_ENUMS.ANNOUNCEMENTS,
    hasRight: MENU_ANNOUNCEMENT_HOMEPAGE,
    hasProfile: all_users,
    url: '/duyurular'
  },
  {
    key: 'raporlar',
    icon: ICON_LIST.SCROLL,
    title: 'NAVIGATION_MENU.REPORTS',
    enum: MODULE_ENUMS.REPORTS,
    // YETKİ59
    hasProfile: only_epias,
    children: [
      {
        key: 'raporlar:1',
        title: 'NAVIGATION_MENU.PROCESS_FLOW',
        enum: PAGE_ENUMS.PROCESS_FLOW,
        url: '/islem-akisi',
        hasRight: MENU_OPERATION_FLOW_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'raporlar:2',
        title: 'NAVIGATION_MENU.MARKET_DATA',
        enum: PAGE_ENUMS.MARKET_DATA,
        url: '/piyasa-verileri',
        hasRight: MENU_MARKET_DATA_REPORT_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'raporlar:3',
        title: 'NAVIGATION_MENU.INVOICE_GUARANTEE',
        enum: PAGE_ENUMS.INVOICE_GUARANTEE,
        url: '/fatura-teminat',
        hasRight: MENU_INVOICE_COLLATERAL_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'raporlar:4',
        title: 'NAVIGATION_MENU.SETTLEMENT_ITEMS',
        enum: PAGE_ENUMS.SETTLEMENT_ITEMS,
        url: '/uzlastirma-kalemleri',
        hasRight: MENU_SETTLEMENT_ITEMS_REPORT,
        hasProfile: only_epias
      },
      {
        key: 'raporlar:5',
        title: 'NAVIGATION_MENU.CURRENT_DEFAULT_TRACKING',
        enum: PAGE_ENUMS.CURRENT_DEFAULT_TRACKING,
        url: '/guncel-temerrut-takip',
        hasRight: MENU_RECENT_INDEFULTANTY_TRACK_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'raporlar:6',
        title: 'NAVIGATION_MENU.DEFAULT_TRACKING_DETAIL',
        enum: PAGE_ENUMS.DEFAULT_TRACKING_DETAIL,
        url: '/temerrut-takip-detay',
        hasRight: MENU_INDEFULTANTY_TRACK_DETAIL_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'raporlar:7',
        title: 'NAVIGATION_MENU.PROCESS_HISTORY',
        enum: PAGE_ENUMS.PROCESS_HISTORY,
        url: '/islem-gecmisi',
        hasRight: MENU_TRANSACTION_HISTORY_HOMEPAGE,
        hasProfile: only_epias
      }
    ]
  },
  {
    key: 'yonetici_islemleri',
    icon: ICON_LIST.USERS_COG,
    title: 'NAVIGATION_MENU.MANAGER_OPERATIONS',
    enum: MODULE_ENUMS.MANAGER_OPERATIONS,
    hasRight: MENU_MANAGEMENT_OPERATIONS_HOMEPAGE,
    hasProfile: only_epias,
    children: [
      {
        key: 'yonetici_islemleri:1',
        title: 'NAVIGATION_MENU.NOTIFICATION_CONFIGURATION',
        enum: PAGE_ENUMS.NOTIFICATION_CONFIGURATION,
        url: '/bildirim-konfigurasyon',
        hasRight: MENU_NOTIFICATION_CONFIGURATION_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'yonetici_islemleri:2',
        title: 'NAVIGATION_MENU.CONTRACT_CREATE_PARAMETERS',
        enum: PAGE_ENUMS.CONTRACT_CREATE_PARAMETERS,
        url: '/kontrat-olusturma-parametreleri',
        hasRight: MENU_CONTRACT_CREATE_PARAMETERS_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'yonetici_islemleri:3',
        title: 'NAVIGATION_MENU.CONTRACT_AND_SESSION_TRACKING',
        enum: PAGE_ENUMS.CONTRACT_AND_SESSION_TRACKING,
        url: '/kontrat-ve-seans-izleme',
        hasRight: MENU_CONTRACT_AND_SESSION_OBSERVATION_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'yonetici_islemleri:4',
        title: 'NAVIGATION_MENU.OFFICIAL_HOLIDAYS',
        enum: PAGE_ENUMS.OFFICIAL_HOLIDAYS,
        url: '/resmi-tatil-gunleri',
        hasRight: MENU_OFFICIAL_HOLIDAYS_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'yonetici_islemleri:5',
        title: 'NAVIGATION_MENU.GENERAL_PARAMETERS',
        enum: PAGE_ENUMS.GENERAL_PARAMETERS,
        url: '/genel-parametreler',
        hasRight: MENU_GENERAL_PARAMETERS_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'yonetici_islemleri:6',
        title: 'NAVIGATION_MENU.SCHEDULE_JOBS',
        enum: PAGE_ENUMS.SCHEDULE_JOBS,
        url: '/zamanlanmis-gorevler',
        hasRight: MENU_SCHEDULED_JOBS_OBSERVATION_HOMEPAGE,
        hasProfile: only_epias
      },
      {
        key: 'yonetici_islemleri:7',
        title: 'NAVIGATION_MENU.TIME_SETTINGS',
        enum: PAGE_ENUMS.TIME_SETTINGS,
        url: '/zaman-ayarlari',
        hasRight: MENU_SCHEDULED_JOBS_OBSERVATION_HOMEPAGE,
        hasProfile: only_epias
      }
    ]
  }
];
