import * as types from 'src/store/actions/types';

const initialState = {
  isLoading: false,
  success: null,
  failed: null
};

export default function matchSearch(state: any = initialState, action) {
  switch (action.type) {
    case types.MATCH_SEARCH_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case types.MATCH_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
        failed: null
      };
    case types.MATCH_SEARCH_UPDATE:
      return {
        ...state,
        success: {
          ...state?.success,
          data: {
            ...state?.success?.data,
            data: {
              ...state?.success?.data?.data,
              data: [...action?.payload]
            }
          }
        }
      };
    case types.OBJECTION_SUCCESS_ORDER_AND_MATCHING_UPDATE:
      return {
        ...state,
        success: {
          ...state?.success,
          data: {
            ...state?.success?.data,
            data: {
              ...state?.success?.data?.data,
              ...(state.success.data.data.data[action?.payload?.index].canObjection = action?.payload?.canObjection)
            }
          }
        }
      };
    case types.MATCH_SEARCH_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload
      };
    default:
      return state;
  }
}
