// Import React
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import Ant
import { Layout } from 'antd';

// Import Store
import { RootState } from 'src/store/store';

// Import Constants
import { LOADING_TYPES } from 'src/common/constants/loading/loadingTypes';
import { SESSION_TIMEOUT_MIN } from 'src/common/constants/user/authConstants';

// Import Components
import Sidebar from 'src/layouts/sidebar/Sidebar';
import AppHeader from 'src/layouts/header/AppHeader';
import PageTitle from 'src/components-new/title/page-title/PageTitle';
import Loading from 'src/components-new/loading/Loading';
import PageHead from 'src/components-new/page-head/PageHead';
import LogoutCatchModal from 'src/layouts/_partials/LogoutCatchModal';

// Import Styles
import 'src/assets/styles/_layout.scss';

// Desctruct Components
const { Content } = Layout;

function DefaultLayout(props) {
  // Descturct Props
  const { authUserSuccessStates, children } = props;

  // Store Variables
  const globalLoadingStates = useSelector((state: RootState) => state?.globalLoading);

  // Variables
  const [visibleLogoutModal, setVisibleLogoutModal] = useState(false);
  const location = useLocation();

  let getVal = 0;

  // Logout CountDown
  function logoutCountDown() {
    localStorage.setItem('counter', `${SESSION_TIMEOUT_MIN}`);

    const interval = setInterval(() => {
      const getCounterValue = localStorage.getItem('counter');

      getVal = Number(getCounterValue);

      if (getVal > 0) {
        getVal = getVal - 1;

        localStorage.setItem('counter', `${getVal}`);

        if (getVal === 15) {
          setVisibleLogoutModal(true);
        }

        if (getVal <= 0) {
          setVisibleLogoutModal(false);
          clearInterval(interval);
          localStorage.setItem('counter', `${0}`);
          window.location.href = `${process.env.REACT_APP_CAS_LOGOUT_URL}`;
        }
      }
    }, 1000);
  }

  // Run on Load for CountDown Session
  useEffect(() => {
    logoutCountDown();
  }, []);

  // On Route Change Detect
  useEffect(() => {
    // runs on location, route change
    localStorage.setItem('counter', `${SESSION_TIMEOUT_MIN}`);
    localStorage.setItem('LastPageUrl', location?.pathname);
  }, [location]);

  return (
    <Fragment>
      <PageHead />
      <Layout className="defaultLayout">
        <Sidebar />
        <Layout className="header_and_content">
          <AppHeader authUserSuccessStates={authUserSuccessStates} />
          <Content className="app-container">
            <PageTitle />
            {children}
            <LogoutCatchModal visible={visibleLogoutModal} setVisible={setVisibleLogoutModal} />
          </Content>
        </Layout>
      </Layout>
      <Loading loading={globalLoadingStates.isLoading} loadingType={LOADING_TYPES.FULLSCREEN} />
    </Fragment>
  );
}

export default DefaultLayout;
