import { SESSION_TIMEOUT_MIN } from 'src/common/constants/user/authConstants';
import * as types from 'src/store/actions/types';

export const getIsVisibleOrderPopUp = (data) => (dispatch) => {
  // Reset Session Timeout
  localStorage.setItem('counter', `${SESSION_TIMEOUT_MIN}`);

  dispatch({
    type: types.GET_ISVISIBLE_ORDER_POPUP_SUCCESS,
    payload: data
  });
};
