import * as types from 'src/store/actions/types';

const initialState = {
  isLoading: false,
  success: null,
  failed: null,
  isSimulated: false
};

export default function indicatorPriceSimulate(state = initialState, action) {
  switch (action.type) {
    case types.INDICATOR_PRICE_SIMULATE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case types.INDICATOR_PRICE_SIMULATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
        failed: null,
        isSimulated: true
      };
    case types.INDICATOR_PRICE_CORRECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
        failed: null
      };
    case types.INDICATOR_PRICE_SIMULATE_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload
      };
    default:
      return state;
  }
}
