import { translate } from 'src/common/utils/translateUtil';

export const ERROR_CODES = [
  {
    code: 'ORDER_CONFLICT',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ERROR.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ERROR.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'SUCCESS',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.SUCCESS.MESSAGE'),
    description: null,
    status: 'success'
  },
  {
    code: 'ERROR',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.GENERAL.ERROR.MESSAGE'),
    description: null,
    status: 'error'
  },
  {
    code: 'USER_NOT_FOUND',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.USER_NOTFOUND.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.USER_NOTFOUND.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'INVALID_AUTHORIZATION',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.INVALID_AUTHORIZATION.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.INVALID_AUTHORIZATION.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'USER_ORGANISATION_NOT_FOUND',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.USER_ORGANISATION_NOT_FOUND.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.USER_ORGANISATION_NOT_FOUND.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_VALIDATION_OUT_OF_PRICE_RANGE',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OUT_OF_PRICE_RANGE.MESSAGE'),
    description: translate(
      'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OUT_OF_PRICE_RANGE.DESCRIPTION'
    ),
    status: 'error'
  },
  {
    code: 'ORDER_VALIDATION_ORGANISATION',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_ORGANISATION.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_ORGANISATION.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORGANISATION_STATUS_ERROR',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORGANISATION_STATUS_ERROR.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORGANISATION_STATUS_ERROR.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_VALIDATION_OPCONTRACT',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OPCONTRACT.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OPCONTRACT.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_VALIDATION_OPCONTRACT_STATUS',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OPCONTRACT_STATUS.MESSAGE'),
    description: translate(
      'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OPCONTRACT_STATUS.DESCRIPTION'
    ),
    status: 'error'
  },
  {
    code: 'ORDER_CHANGED',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_CHANGED.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_CHANGED.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'EXCEED_POSITION_LIMIT',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.EXCEED_POSITION_LIMIT.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.EXCEED_POSITION_LIMIT.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'GGF_IMPORT_ERROR',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.GGF_IMPORT_ERROR.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.GGF_IMPORT_ERROR.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'POSITION_LIMIT_IMPORT_ERROR',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.POSITION_LIMIT_IMPORT_ERROR.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.POSITION_LIMIT_IMPORT_ERROR.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'LIMIT_REQUEST_PRE_ACTION_SAME_USER',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.LIMIT_REQUEST_PRE_ACTION_SAME_USER.MESSAGE'),
    description: translate(
      'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.LIMIT_REQUEST_PRE_ACTION_SAME_USER.DESCRIPTION'
    ),
    status: 'error'
  },
  {
    code: 'NO_UPDATED_VALUE',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.NO_UPDATED_VALUE.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.NO_UPDATED_VALUE.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'INVALID_SESSION_STATUS',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.INVALID_SESSION_STATUS.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.INVALID_SESSION_STATUS.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'LEAK_COLLATERAL',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.LEAK_COLLATERAL.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.LEAK_COLLATERAL.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_MATCHED',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_MATCHED.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_MATCHED.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_CANCELED',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_CANCELED.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_CANCELED.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORGANISATION_STATUS_INVALID',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORGANISATION_STATUS_INVALID.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORGANISATION_STATUS_INVALID.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_NOT_MATCHING',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_NOT_MATCHING.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_NOT_MATCHING.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_NOT_FOUND',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_NOT_FOUND.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_NOT_FOUND.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_VALIDATION_OUT_OF_QUANTITY_RANGE',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OUT_OF_QUANTITY_RANGE.MESSAGE'),
    description: translate(
      'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OUT_OF_QUANTITY_RANGE.DESCRIPTION'
    ),
    status: 'error'
  },
  {
    code: 'ORDER_VALIDATION_OUT_OF_USER_LIMIT',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OUT_OF_USER_LIMIT.MESSAGE'),
    description: translate(
      'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OUT_OF_USER_LIMIT.DESCRIPTION'
    ),
    status: 'error'
  },
  {
    code: 'ORDER_VALIDATION_OUT_OF_EXPIRE_TIME',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OUT_OF_EXPIRE_TIME.MESSAGE'),
    description: translate(
      'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_OUT_OF_EXPIRE_TIME.DESCRIPTION'
    ),
    status: 'error'
  },
  {
    code: 'INVALID_ORDER',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.INVALID_ORDER.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.INVALID_ORDER.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_EXPIRED',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_EXPIRED.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_EXPIRED.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_VALIDATION_AUTHORISATION',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_AUTHORISATION.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_AUTHORISATION.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ORDER_VALIDATION_MINUTE_COUNT',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_MINUTE_COUNT.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ORDER_VALIDATION_MINUTE_COUNT.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'MD_PARAM_NOT_VALIDATION',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.MD_PARAM_NOT_VALIDATION.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.MD_PARAM_NOT_VALIDATION.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'MD_PARAM_INVALID_TIME',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.MD_PARAM_INVALID_TIME.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.MD_PARAM_INVALID_TIME.DESCRIPTION'),
    status: 'error'
  },
  {
    code: 'ANNOUNCEMENT_TEXT_LENGHT',
    title: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ANNOUNCEMENT_TEXT_LENGHT.MESSAGE'),
    description: translate('GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.ANNOUNCEMENT_TEXT_LENGHT.DESCRIPTION'),
    status: 'error'
  }
];
