// Import React
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import Ant
import { Menu, Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

// Import Utils
import { check_permission } from 'src/common/utils/checkPermissionUtils';
import { translate } from 'src/common/utils/translateUtil';
import { IsPrp } from 'src/common/utils/prpControl';

// Import Constants
import { MENU } from 'src/layouts/sidebar/menu';
import { ICON_LIST } from 'src/common/constants/icon/iconList';
// import { ICON_STATUS } from 'src/common/constants/icon/iconStatus';
import { all_users } from 'src/common/constants/roles-rights/roles';
import { ICON_STATUS } from 'src/common/constants/icon/iconStatus';
import { STATUS } from 'src/common/constants/status/status';
import { BUTTON_SIZES } from 'src/common/constants/sizes/buttonSizes';

// Import Components
import Icon from 'src/components-new/icon/Icon';
import Button from 'src/components-new/cta/button/Button';
import Tooltip from 'src/components-new/tooltip/Tooltip';

// Import Store
import { RootState } from 'src/store/store';

// Desctruct Components
const { Sider } = Layout;
const { SubMenu } = Menu;

function Sidebar() {
  //Variables
  const [collapsed, setCollapsed] = useState(true);
  const [pathname, setPathname] = useState<string>();
  const menuContainer = useRef<any>();

  // Store Variables
  const authUserSuccessStates = useSelector((state: RootState) => state?.authUser.success);

  // Menu On Collapse Action
  const onCollapse = () => {
    setCollapsed((state: any) => !state);
  };

  // Menu SubItem Icon
  const subMenuIcon = <Icon icon={ICON_LIST.ARROW_RIGHT} size="sm" marginright={5} />;

  const onLogout = () => {
    localStorage.setItem('counter', `${0}`);
    localStorage.setItem('LastPageUrl', '/');
    window.location.href = `${process.env.REACT_APP_CAS_LOGOUT_URL}`;
  };

  // Handle Click Outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuContainer.current && !menuContainer.current.contains(event.target)) {
        setCollapsed(true);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuContainer]);

  useEffect(() => {
    setPathname(location?.pathname);
  }, [location?.pathname]);

  return (
    <Sider
      theme="light"
      trigger={collapsed ? <MenuUnfoldOutlined className="icons" /> : <MenuFoldOutlined className="icons" />}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      className="app-sidebar"
    >
      <>
        <div className="nav-profile">
          <div className="avatar">
            <Tooltip
              title={
                authUserSuccessStates
                  ? authUserSuccessStates?.data?.detail?.userInfo?.username
                  : translate('GLOBAL.COMPONENTS.TOOLTIP.UNKNOWN_USER')
              }
              isCustomTitle={true}
              placement="right"
            >
              <Icon icon={ICON_LIST.USER_CIRCLE} status={ICON_STATUS.WHITE} size="2x" id="profile_icon_cta" />
            </Tooltip>
            <div className="user">
              <span className="user-name" id="profile_name_cta">
                {/* eslint-disable-next-line max-len */}
                {authUserSuccessStates
                  ? authUserSuccessStates?.data?.detail?.userInfo?.username
                  : translate('GLOBAL.COMPONENTS.TOOLTIP.UNKNOWN_USER')}{' '}
              </span>
              <Button
                status={STATUS.LINK_WHITE}
                sizes={BUTTON_SIZES.SM}
                onClick={() => onLogout()}
                icon={<Icon icon={ICON_LIST.LOGOUT} status={ICON_STATUS.WHITE} size="sm" />}
                label="GLOBAL.LAYOUT.HEADER.PROFILE.LOGOUT"
                name="logout"
              />
            </div>
          </div>
        </div>
        <div ref={menuContainer}>
          <Menu id="menu_sidebar" mode="inline">
            {MENU?.map((item: any, index) => {
              const hasRight = item?.hasRight ? item?.hasRight : undefined;
              const hasProfile = item?.hasProfile ? item?.hasProfile : all_users;
              const prpVisibleControl = IsPrp(authUserSuccessStates?.data?.profile) && item?.disablePRP ? false : true;

              return (
                prpVisibleControl &&
                check_permission(hasRight, hasProfile) &&
                (!item?.children ? (
                  <Menu.Item
                    id={`menu_${item?.key}`}
                    key={`menu_${item?.key}`}
                    className={pathname === item?.url ? 'menu_active' : ''}
                    icon={
                      item?.icon ? (
                        <span className="anticon">
                          <Icon icon={item?.icon} />
                        </span>
                      ) : (
                        subMenuIcon
                      )
                    }
                  >
                    <Link to={item?.url}>{translate(item?.title)}</Link>
                  </Menu.Item>
                ) : (
                  <>
                    {
                      <SubMenu
                        key={item?.key}
                        className={
                          pathname === item?.url
                            ? `menu_item_${item?.key} myNavItem_${index} menu_active`
                            : `myNavItem_${index}`
                        }
                        icon={
                          <span className="anticon">
                            <Icon icon={item?.icon} />
                          </span>
                        }
                        title={translate(item?.title)}
                      >
                        {item?.children?.map((childItem: any) => {
                          const hasRightChild = childItem?.hasRight ? childItem?.hasRight : undefined;
                          const hasProfileChild = childItem?.hasProfile ? childItem?.hasProfile : all_users;
                          const childPrpVisibleControl =
                            IsPrp(authUserSuccessStates?.data?.profile) && childItem?.disablePRP ? false : true;

                          if (pathname === childItem?.url) {
                            const element: any = document.getElementsByClassName(`myNavItem_${index}`);

                            element[0].children[0].classList.add('menu_active');
                          }

                          return (
                            <>
                              {childPrpVisibleControl && check_permission(hasRightChild, hasProfileChild) && (
                                <Menu.Item
                                  id={`menu_${childItem?.key}`}
                                  key={`menu_${childItem?.key}`}
                                  className={pathname === childItem?.url ? 'menu_active_sub' : ''}
                                  icon={
                                    childItem?.icon ? (
                                      <span className="anticon">
                                        <Icon icon={childItem?.icon} />
                                      </span>
                                    ) : (
                                      subMenuIcon
                                    )
                                  }
                                >
                                  <Link to={childItem?.url}>{translate(childItem?.title)}</Link>
                                </Menu.Item>
                              )}
                            </>
                          );
                        })}
                      </SubMenu>
                    }
                  </>
                ))
              );
            })}
          </Menu>
        </div>
      </>
    </Sider>
  );
}

export default Sidebar;
