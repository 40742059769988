export enum MODULE_ENUMS {
  REGISTRATION_PROCEDURES = 'REGISTRATION_PROCEDURES',
  MARKET_TRANSACTIONS = 'MARKET_TRANSACTIONS',
  GUARANTEE_PROCEDURES = 'GUARANTEE_PROCEDURES',
  SETTLEMENT_AND_INVOICING_PROCEDURES = 'SETTLEMENT_AND_INVOICING_PROCEDURES',
  MANAGER_OPERATIONS = 'MANAGER_OPERATIONS',
  DEFAULT_TRANSACTIONS = 'DEFAULT_TRANSACTIONS',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  REPORTS = 'REPORTS'
}

export enum PAGE_ENUMS {
  HOME = 'HOME',
  ORGANISATIONS = 'ORGANISATIONS',
  OFFERS_AND_MATCHES = 'OFFERS_AND_MATCHES',
  MARKET_RESULTS = 'MARKET_RESULTS',
  ORGANISATION_STATUS_TRACKING = 'ORGANISATION_STATUS_TRACKING',
  POSITION_LIMIT = 'POSITION_LIMIT',
  GGF = 'GGF',
  BASE_PRICE = 'BASE_PRICE',
  OBJECTIONS = 'OBJECTIONS',
  COLLATERAL_TRACKING = 'COLLATERAL_TRACKING',
  COLLATERAL_SUMMARY = 'COLLATERAL_SUMMARY',
  COLLATERAL_SIMULATION = 'COLLATERAL_SIMULATION',
  CONCILIATION_PROCESS_TRACKING = 'CONCILIATION_PROCESS_TRACKING',
  CONCILIATION_BASIS = 'CONCILIATION_BASIS',
  SETTLEMENT_CALCULATIONS = 'SETTLEMENT_CALCULATIONS',
  CORRECTION_PEN_INTRODUCTION = 'CORRECTION_PEN_INTRODUCTION',
  CONCILIATION_NOTICE_PAGE = 'CONCILIATION_NOTICE_PAGE',
  FINANCIAL_CONTROLS_PAGE = 'FINANCIAL_CONTROLS_PAGE',
  TAKASBANK_NOTICE = 'TAKASBANK_NOTICE',
  WITHDRAW_FROM_TGH_ACCOUNTS = 'WITHDRAW_FROM_TGH_ACCOUNTS',
  REFUND_TO_TGH_ACCOUNTS = 'REFUND_TO_TGH_ACCOUNTS',
  VIEW_INVOICE_ITEMS = 'VIEW_INVOICE_ITEMS',
  NOTIFICATION_CONFIGURATION = 'NOTIFICATION_CONFIGURATION',
  CONTRACT_CREATE_PARAMETERS = 'CONTRACT_CREATE_PARAMETERS',
  CONTRACT_AND_SESSION_TRACKING = 'CONTRACT_AND_SESSION_TRACKING',
  OFFICIAL_HOLIDAYS = 'OFFICIAL_HOLIDAYS',
  GENERAL_PARAMETERS = 'GENERAL_PARAMETERS',
  SCHEDULE_JOBS = 'SCHEDULE_JOBS',
  CURRENT_DATE = 'CURRENT_DATE',
  DEFAULT_NETTING = 'DEFAULT_NETTING',
  DEFAULT_MANAGEMENT_MATCHES = 'DEFAULT_MANAGEMENT_MATCHES',
  VGP_TRANSACTIONS = 'VGP_TRANSACTIONS',
  TGH_MANAGEMENT = 'TGH_MANAGEMENT',
  TGH_INFORMATION = 'TGH_INFORMATION',
  TGH_DEFAULT_TRACKING = 'TGH_DEFAULT_TRACKING',
  POSITION_CLOSING_SUMMARY = 'POSITION_CLOSING_SUMMARY',
  DEFAULT_INFORMATION = 'DEFAULT_INFORMATION',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  PROCESS_FLOW = 'PROCESS_FLOW',
  MARKET_DATA = 'MARKET_DATA',
  INVOICE_GUARANTEE = 'INVOICE_GUARANTEE',
  SETTLEMENT_ITEMS = 'SETTLEMENT_ITEMS',
  CURRENT_DEFAULT_TRACKING = 'CURRENT_DEFAULT_TRACKING',
  DEFAULT_TRACKING_DETAIL = 'DEFAULT_TRACKING_DETAIL',
  PROCESS_HISTORY = 'PROCESS_HISTORY',
  CURRENT_DEFAULT = 'CURRENT_DEFAULT',
  TIME_SETTINGS = 'TIME_SETTINGS'
}
