import * as types from 'src/store/actions/types';
import { api } from 'src/store/actions/api';
import { SERVICES } from 'src/store/constants/serviceConstants';

export const transactionHistoryPageChange = (moduleName, pageName) => (dispatch) => {
  const params = {
    moduleName,
    pageName
  };

  dispatch(
    api(
      'get',
      SERVICES.MARKETS_BACKEND_SERVICE,
      `/api/transactions/transaction-history-frontend`,
      params,
      types.TRANSACTION_HISTORY_PAGE_CHANGE
    )
  );
};
