export const STORE_CONFIG: Record<string, Record<string, string | boolean>> = {
  NOTIFICATION_CONFIGURATION_DETAIL: {
    serviceName: 'notificationConfigurationDetail',
    successMessage: false,
    errorMessage: false
  },
  NOTIFICATION_CONFIGURATION: {
    serviceName: 'notificationConfigurationList',
    successMessage: false,
    errorMessage: false
  },
  NOTIFICATION_CONFIGURATION_UPDATE: {
    serviceName: 'notificationConfigurationUpdate',
    successMessage: true,
    errorMessage: true
  },
  NOTIFICATION_CONFIGURATION_PARAMETERS: {
    serviceName: 'notificationParameterList',
    successMessage: false,
    errorMessage: false
  },
  ANNOUNCEMENT_CREATE: { serviceName: 'announcementCreate', successMessage: true, errorMessage: true },
  ANNOUNCEMENT_DELETE: {
    serviceName: 'announcementDelete',
    successMessage: true,
    errorMessage: true
  },
  ANNOUNCEMENT_HEADER_LIST: {
    serviceName: 'announcementHeaderList',
    successMessage: false,
    errorMessage: false
  },
  ANNOUNCEMENT_LIST: {
    serviceName: 'announcementList',
    successMessage: false,
    errorMessage: true
  },
  ANNOUNCEMENT_READ_UPDATE: {
    serviceName: 'announcementReadUpdate',
    successMessage: true,
    errorMessage: true
  },
  ANNOUNCEMENT_UPDATE: {
    serviceName: 'announcementUpdate',
    successMessage: true,
    errorMessage: true
  },
  BASE_PRICE_DETAIL: {
    serviceName: 'basePriceDetail',
    successMessage: false,
    errorMessage: false
  },
  BASE_PRICE_LIST_IMPORT: {
    serviceName: 'basePriceImport',
    successMessage: true,
    errorMessage: true
  },
  BASE_PRICE_LIST: {
    serviceName: 'basePriceList',
    successMessage: false,
    errorMessage: false
  },
  BASE_PRICE_SIMULATOR: {
    serviceName: 'basePriceListSimulator',
    successMessage: true,
    errorMessage: true
  },
  BASE_PRICE_SIMULATOR_DATES: {
    serviceName: 'basePriceSimulatorDates',
    successMessage: false,
    errorMessage: false
  },

  COLLETERAL_POSITIONS: {
    serviceName: 'getColleteralPositions',
    successMessage: false,
    errorMessage: false
  },
  COLLETERAL_POSITION_DETAIL: {
    serviceName: 'getColleteralPositionsDetail',
    successMessage: false,
    errorMessage: false
  },
  COLLETERAL_SIMULATION: {
    serviceName: 'getColleteralSimulation',
    successMessage: true,
    errorMessage: true
  },
  GET_FILTER_CONTRACT_TYPE: {
    serviceName: 'getContractType',
    successMessage: false,
    errorMessage: false
  },
  GET_FILTER_CONTRACT_TYPE_ALL_COMBO: {
    serviceName: 'getContractTypeAll',
    successMessage: false,
    errorMessage: false
  },
  GET_FILTER_CONTRACT_TYPE_COMBO: {
    serviceName: 'getContractTypeCombo',
    successMessage: false,
    errorMessage: false
  },
  GET_FILTER_ORGANIZATION_LIST: {
    serviceName: 'getOrganizationList',
    successMessage: false,
    errorMessage: false
  },
  GET_CONTRACT: {
    serviceName: 'getContract',
    successMessage: false,
    errorMessage: false
  },
  GET_CONTRACT_FOR_MODAL: {
    serviceName: 'getContractForModal',
    successMessage: false,
    errorMessage: false
  },
  GET_DEFAULT_TGH: {
    serviceName: 'getDefaultTgh',
    successMessage: false,
    errorMessage: false
  },
  GET_INDEFAULTENTITYLIST: {
    serviceName: 'getIndefaultentityList',
    successMessage: false,
    errorMessage: false
  },
  GET_INDEFAULTENTITY_MANAGEMENT_MATCH: {
    serviceName: 'getIndefaultentityManagementMatch',
    successMessage: false,
    errorMessage: false
  },
  GET_TGH_INFORMATION_LIST: {
    serviceName: 'getTghInformationList',
    successMessage: false,
    errorMessage: false
  },
  GET_INDEFAULTENTITYOFFSET: {
    serviceName: 'getIndefaultentityOffset',
    successMessage: false,
    errorMessage: false
  },
  GET_REPORT_PERIOD_SELECT: {
    serviceName: 'getReportsPeriods',
    successMessage: false,
    errorMessage: false
  },
  GET_INDEFAULTENTITYOFFSET_SIMULATION: {
    serviceName: 'getIndefaultentityOffsetSimulation',
    successMessage: false,
    errorMessage: false
  },
  GET_INDEFAULTENTITY_VGP_LIST: {
    serviceName: 'getIndefaultentityVgpList',
    successMessage: false,
    errorMessage: false
  },
  INDEFAULTENTITY_GET_ORGANISTION: {
    serviceName: 'indefaultentityOffsetModuleGetOrganisation',
    successMessage: false,
    errorMessage: false
  },
  GET_TGH_INFORMATION: {
    serviceName: 'getTghInformations',
    successMessage: false,
    errorMessage: false
  },
  POST_TGH_CALL: {
    serviceName: 'postTghCall',
    successMessage: true,
    errorMessage: true
  },
  POST_INDEFAULTENTITY_VGP_ORDER_APPLY: {
    serviceName: 'postVgpOrderApply',
    successMessage: true,
    errorMessage: true
  },
  POST_INDEFAULTENTITY_VGP_ORDER_SIMULATE: {
    serviceName: 'postVgpOrderSimulate',
    successMessage: false,
    errorMessage: true
  },
  EVENT_HISTORY: {
    serviceName: 'eventHistory',
    successMessage: false,
    errorMessage: false
  },
  GET_SCHEDULED_TASK_LIST: {
    serviceName: 'getScheduledTaskList',
    successMessage: false,
    errorMessage: false
  },
  RUN_GROUP_SCHEDULED_TASK: {
    serviceName: 'runGroupScheduledTask',
    successMessage: true,
    errorMessage: true
  },
  RUN_SCHEDULED_TASK: {
    serviceName: 'runScheduledTask',
    successMessage: true,
    errorMessage: true
  },
  GET_TEXT_LENGTH: {
    serviceName: 'getTextLength',
    successMessage: false,
    errorMessage: false
  },
  GET_SESSION_STATUS: {
    serviceName: 'getSessionStatus',
    successMessage: false,
    errorMessage: false
  },
  MD_CONTRACT_LIST: {
    serviceName: 'mdContractList',
    successMessage: false,
    errorMessage: false
  },
  MD_CONTRACT_UPDATE: {
    serviceName: 'mdContractUpdate',
    successMessage: true,
    errorMessage: true
  },
  OP_CONTRACT_LIST: {
    serviceName: 'opContractList',
    successMessage: false,
    errorMessage: false
  },
  OP_CONTRACT_UPDATE: {
    serviceName: 'opContractUpdate',
    successMessage: true,
    errorMessage: true
  },
  OPEN_SESSION: {
    serviceName: 'openSession',
    successMessage: true,
    errorMessage: true
  },
  SUSPEND_SESSION: {
    serviceName: 'suspendSession',
    successMessage: true,
    errorMessage: true
  },
  GET_BANK_HOLIDAY: {
    serviceName: 'getBankHoliday',
    successMessage: false,
    errorMessage: false
  },
  PUT_BANK_HOLIDAY: {
    serviceName: 'putBankHoliday',
    successMessage: true,
    errorMessage: true
  },
  COLLATERAL_MAIN_DETAIL: {
    serviceName: 'collateralMainDetails',
    successMessage: false,
    errorMessage: false
  },
  COLLATERAL_SUMMARY: {
    serviceName: 'collateralSummary',
    successMessage: false,
    errorMessage: false
  },
  COLLATERAL_DETAIL: {
    serviceName: 'collateralDetail',
    successMessage: false,
    errorMessage: false
  },
  COLLATERAL_DETAIL_INDEFAULTENTITY: {
    serviceName: 'inDefaultEntity',
    successMessage: false,
    errorMessage: false
  },
  TGH_DETAIL: {
    serviceName: 'tghDetail',
    successMessage: false,
    errorMessage: false
  },
  LIST_OP_CONTRACT: {
    serviceName: 'listOpContract',
    successMessage: false,
    errorMessage: false
  },
  CONTRACT_CHART: {
    serviceName: 'contractChart',
    successMessage: false,
    errorMessage: false
  },
  GET_INDEFAULTY_LIST: {
    serviceName: 'indefaultyList',
    successMessage: false,
    errorMessage: false
  },
  MANUEL_CONTROL: {
    serviceName: 'manuelControl',
    successMessage: true,
    errorMessage: true
  },
  TRANSACTION_LOGS: {
    serviceName: 'transactionLogs',
    successMessage: false,
    errorMessage: false
  },
  UPDATE_INDEFAULTY_LIST: {
    serviceName: 'updateIndefaultyList',
    successMessage: false,
    errorMessage: true
  },
  IMPORT_INDICATOR_PRICE: {
    serviceName: 'indicatorPriceImports',
    successMessage: false,
    errorMessage: true
  },
  INDICATOR_PRICE_SEARCH: {
    serviceName: 'indicatorPriceSearch',
    successMessage: false,
    errorMessage: false
  },
  LIMIT_APPROVED: {
    serviceName: 'limitApproved',
    successMessage: true,
    errorMessage: true
  },
  LIMIT_PREAPPROVED: {
    serviceName: 'limitPreApproved',
    successMessage: true,
    errorMessage: true
  },
  LIMIT_PREREJECTED: {
    serviceName: 'limitPreRejected',
    successMessage: true,
    errorMessage: true
  },
  LIMIT_REJECTED: {
    serviceName: 'limitRejected',
    successMessage: true,
    errorMessage: true
  },
  LIMIT_REQUEST: {
    serviceName: 'limitRequest',
    successMessage: true,
    errorMessage: true
  },
  LIMIT_REQUEST_AVAILABLE: {
    serviceName: 'limitRequestAvailable',
    successMessage: false,
    errorMessage: true
  },
  LIMIT_REQUEST_SIMULATE: {
    serviceName: 'limitRequestSimulate',
    successMessage: true,
    errorMessage: true
  },
  LIMIT_REQUEST_GET_SIMULATE: {
    serviceName: 'limitRequestSimulateGet',
    successMessage: false,
    errorMessage: false
  },
  ORGANIZATION_POZITION_LIMIT_REQUEST: {
    serviceName: 'organizationPozitionLimitRequest',
    successMessage: false,
    errorMessage: true
  },
  ORGANIZATION_TOTAL_LIMIT: {
    serviceName: 'organizationTotalLimit',
    successMessage: false,
    errorMessage: false
  },
  PAID_TGH: {
    serviceName: 'paidTgh',
    successMessage: true,
    errorMessage: true
  },
  PARTICIPANT_POSITION_LIMIT_REQUEST: {
    serviceName: 'participantPozitionLimitRequest',
    successMessage: false,
    errorMessage: true
  },
  SEND_ORGANISATION_NOTIFICATION: {
    serviceName: 'sendOrganisationNotification',
    successMessage: true,
    errorMessage: true
  },
  SEND_TAKASBANK_NOTIFICATION: {
    serviceName: 'sendTakasbankNotification',
    successMessage: true,
    errorMessage: true
  },
  MATCH_SEARCH_FILTER: {
    serviceName: 'matchSearchFilter',
    successMessage: false,
    errorMessage: true
  },
  EP_SEARCH: {
    serviceName: 'getEPSearch',
    successMessage: false,
    errorMessage: false
  },
  PK_SEARCH: {
    serviceName: 'getPKSearch',
    successMessage: false,
    errorMessage: false
  },
  MATCHING_CANCEL: {
    serviceName: 'matchingCancel',
    successMessage: true,
    errorMessage: true
  },
  OBJECTION: {
    serviceName: 'objection',
    successMessage: true,
    errorMessage: true
  },
  OBJECTION_APPROVED: {
    serviceName: 'objectionApproved',
    successMessage: true,
    errorMessage: true
  },
  OBJECTION_PRE_APPROVED: {
    serviceName: 'objectionPreApproved',
    successMessage: true,
    errorMessage: true
  },
  OBJECTION_PRE_REJECTED: {
    serviceName: 'objectionPreRejected',
    successMessage: true,
    errorMessage: true
  },
  OBJECTION_REJECTED: {
    serviceName: 'objectionRejected',
    successMessage: true,
    errorMessage: true
  },
  IMPORT_BATCH_ORDER: {
    serviceName: 'importBatchOrder',
    successMessage: false,
    errorMessage: true
  },
  ORDER_CANCEL: {
    serviceName: 'orderCancel',
    successMessage: false,
    errorMessage: true
  },
  ORDER_INFO: {
    serviceName: 'orderInfo',
    successMessage: false,
    errorMessage: false
  },
  ORDER_SEARCH_FILTER: {
    serviceName: 'orderSearchFilter',
    successMessage: false,
    errorMessage: true
  },
  SITUATION_FOLLOW: {
    serviceName: 'situationFollow',
    successMessage: false,
    errorMessage: true
  },
  CALCULATE_POSITION_LIMIT: {
    serviceName: 'calculatePositionLimit',
    successMessage: false,
    errorMessage: false
  },
  GET_LIMIT_USAGE: {
    serviceName: 'getLimitUsage',
    successMessage: false,
    errorMessage: true
  },
  GET_ORG_LIMIT_USAGE: {
    serviceName: 'getOrgLimitUsage',
    successMessage: false,
    errorMessage: false
  },
  GET_ORG_LIMIT_USAGE_DETAIL: {
    serviceName: 'getOrgLimitUsageDetail',
    successMessage: false,
    errorMessage: true
  },
  GET_ORG_ORDER_AND_MATCHING: {
    serviceName: 'getOrgOrderAndMatching',
    successMessage: false,
    errorMessage: false
  },
  IMPORT_MARKET_POSITION_LIMIT: {
    serviceName: 'importMarketPositionLimit',
    successMessage: true,
    errorMessage: true
  },
  IMPORT_ORGANIZATION: {
    serviceName: 'importOrganization',
    successMessage: true,
    errorMessage: true
  },
  REPORT_COMPLETED_PERIODS: {
    serviceName: 'getCompletedPeriods',
    successMessage: false,
    errorMessage: false
  },
  REPORT_DEFAULT_CURRENT: {
    serviceName: 'getDefaultCurrent',
    successMessage: false,
    errorMessage: false
  },
  REPORT_DEFAULT_DETAIL: {
    serviceName: 'getDefaultDetail',
    successMessage: false,
    errorMessage: false
  },
  REPORT_INVOICE_GUARANTEE: {
    serviceName: 'getInvoiceGuarentee',
    successMessage: false,
    errorMessage: false
  },
  MARKET_CONCILATION_SUMMARY: {
    serviceName: 'getMarketConcilationSummary',
    successMessage: false,
    errorMessage: false
  },
  REPORT_BILLING_ORGANISATION: {
    serviceName: 'getReportBillingOrganisation',
    successMessage: false,
    errorMessage: false
  },
  MARKET_DATA: {
    serviceName: 'marketData',
    successMessage: true,
    errorMessage: true
  },
  MARKET_DATA_PARAMS: {
    serviceName: 'marketDataParams',
    successMessage: false,
    errorMessage: false
  },
  PROCESS_FLOW: {
    serviceName: 'processFlow',
    successMessage: false,
    errorMessage: false
  },
  PROCESS_FLOW_FILTER: {
    serviceName: 'processFlowFilter',
    successMessage: false,
    errorMessage: false
  },
  TRANSACTION_HISTORY_TYPE: {
    serviceName: 'historyTypeSelect',
    successMessage: false,
    errorMessage: false
  },
  TRANSACTION_HISTORY_ORGANISATION_LIST: {
    serviceName: 'organisationSearchList',
    successMessage: false,
    errorMessage: false
  },
  TRANSACTION_HISTORY: {
    serviceName: 'transactionHistory',
    successMessage: false,
    errorMessage: false
  },
  TRANSACTION_HISTORY_GET_MODULES: {
    serviceName: 'transactionHistoryGetModules',
    successMessage: false,
    errorMessage: false
  },
  TRANSACTION_HISTORY_GET_PAGES: {
    serviceName: 'transactionHistoryGetPages',
    successMessage: false,
    errorMessage: false
  },
  TRANSACTION_HISTORY_PAGE_CHANGE: {
    serviceName: 'transactionHistoryPageChange',
    successMessage: false,
    errorMessage: false
  },
  TRANSACTION_HISTORY_PROCESS_TYPE: {
    serviceName: 'transactionProcessTypes',
    successMessage: false,
    errorMessage: false
  },
  TRANSACTION_HISTORY_TRANSACTION_TYPE: {
    serviceName: 'transactionTypeSelect',
    successMessage: false,
    errorMessage: false
  },
  TRANSACTION_HISTORY_USER_TYPE: {
    serviceName: 'userTypeSelect',
    successMessage: false,
    errorMessage: false
  },
  GET_TRANSMISSION_DAILY_LONG: {
    serviceName: 'transmissionDailyLong',
    successMessage: false,
    errorMessage: false
  },
  GET_TRANSMISSION_DAILY_SHORT: {
    serviceName: 'transmissionDailyShort',
    successMessage: false,
    errorMessage: false
  },
  GET_TRANSMISSION_LIST_PERIOD: {
    serviceName: 'transmissionList',
    successMessage: false,
    errorMessage: false
  },
  GET_ORGANIZATION_SUMMARY: {
    serviceName: 'getOrganizationSummary',
    successMessage: false,
    errorMessage: false
  },
  GET_SUMMARY_MARKET: {
    serviceName: 'getSummaryMarket',
    successMessage: false,
    errorMessage: false
  },
  GET_MARKET_SUMMARY: {
    serviceName: 'getSummaryTables',
    successMessage: false,
    errorMessage: false
  },
  MATCH_DETAIL: {
    serviceName: 'matchDetails',
    successMessage: false,
    errorMessage: false
  },
  NOTIFICATION_LIST: {
    serviceName: 'notificationHeaderList',
    successMessage: false,
    errorMessage: false
  },
  NOTIFICATION_READ: {
    serviceName: 'notificationHeaderRead',
    successMessage: false,
    errorMessage: false
  },
  ORDER_CREATE_TIME_SELECT: {
    serviceName: 'getMdContractCombo',
    successMessage: false,
    errorMessage: false
  },
  ORDER_CREATE: {
    serviceName: 'orderCreate',
    successMessage: false,
    errorMessage: true
  },
  ORDER_CREATE_OFFERS_SELECT: {
    serviceName: 'getOffersSelect',
    successMessage: false,
    errorMessage: false
  },
  ORDER_UPDATE: {
    serviceName: 'orderUpdate',
    successMessage: false,
    errorMessage: true
  },
  ORDER_UPDATE_ALL: {
    serviceName: 'orderUpdateAll',
    successMessage: true,
    errorMessage: true
  },
  ORDER_VERSION: {
    serviceName: 'orderVersions',
    successMessage: false,
    errorMessage: false
  },
  GET_DETAIL: {
    serviceName: 'getDetail',
    successMessage: false,
    errorMessage: false
  },
  GET_PERIOD_QUERY: {
    serviceName: 'getPeriods',
    successMessage: false,
    errorMessage: false
  },
  PERIOD_QUERY: {
    serviceName: 'periodQueryWithSTP',
    successMessage: true,
    errorMessage: true
  },
  TABLE_DATA: {
    serviceName: 'tableData',
    successMessage: false,
    errorMessage: false
  },
  FINANCE_CONTROL_PAGE_GET_DETAILS: {
    serviceName: 'financeControlPageGetFinanceDetail',
    successMessage: false,
    errorMessage: false
  },
  FINANCE_CONTROL_PAGE_INQUIRY: {
    serviceName: 'financeControlPageGetFinanceInquiry',
    successMessage: false,
    errorMessage: false
  },
  FINANCE_CONTROL_PAGE_PROVIDING: {
    serviceName: 'financeControlPageGetFinanceProviding',
    successMessage: false,
    errorMessage: false
  },
  FINANCE_CONTROL_PAGE_APPROVE: {
    serviceName: 'financeControlPagePutFinanceApprove',
    successMessage: true,
    errorMessage: true
  },
  FINANCE_CONTROL_PAGE_REJECT: {
    serviceName: 'financeControlPagePutReject',
    successMessage: true,
    errorMessage: true
  },
  GET_GDDK_PERIODS: {
    serviceName: 'getGDDKPeriods',
    successMessage: false,
    errorMessage: false
  },
  NOTICE_CONCILIATION_PAGE_GET_ORGANISATION_SELECTBOX: {
    serviceName: 'settlementAndBillingModuleGetOrganisation',
    successMessage: false,
    errorMessage: false
  },
  GET_PERIOD: {
    serviceName: 'settlementAndBillingModuleGetPeriods',
    successMessage: false,
    errorMessage: false
  },
  GET_INVOICE_DATE: {
    serviceName: 'putInvoiceDate',
    successMessage: false,
    errorMessage: true
  },
  NOTICE_CONCILIATION_PAGE_GET_GDDK_DETAILS: {
    serviceName: 'noticeConciliationPageGetGDDKDetails',
    successMessage: false,
    errorMessage: false
  },
  NOTICE_CONCILIATION_PAGE_GET_GDDK_HISTORY: {
    serviceName: 'noticeConciliationPageGetGDDKHistory',
    successMessage: false,
    errorMessage: false
  },
  NOTICE_CONCILIATION_PAGE_GET_DETAILS_SELECTBOX: {
    serviceName: 'noticeConciliationPageGetDetails',
    successMessage: false,
    errorMessage: false
  },
  NOTICE_CONCILIATION_PAGE_GET_GDDK_CREATE: {
    serviceName: 'noticeConciliationPageGetGDDKCreate',
    successMessage: true,
    errorMessage: true
  },
  NOTICE_CONCILIATION_PAGE_GET_GDDK_APPROVED: {
    serviceName: 'noticeConciliationPageGetGDDKApproved',
    successMessage: true,
    errorMessage: true
  },
  NOTICE_CONCILIATION_PAGE_GET_GDDK_REJECTED: {
    serviceName: 'noticeConciliationPageGetGDDKRejected',
    successMessage: true,
    errorMessage: true
  },
  NOTICE_CONCILIATION_PAGE_GET_GDDK_UPDATE: {
    serviceName: 'noticeConciliationPageGetGDDKUpdate',
    successMessage: true,
    errorMessage: true
  },
  NOTICE_CONCILATION_REJECT: {
    serviceName: 'noticeConciliationPagePutReject',
    successMessage: true,
    errorMessage: true
  },
  SETTLEMENT_AND_BILLING_GET_BILLING_QUERY: {
    serviceName: 'settlementAndBillingModuleGetBillingStatus',
    successMessage: false,
    errorMessage: false
  },
  SETTLEMENT_AND_BILLING_GET_LIST_VERSIONS_QUERY: {
    serviceName: 'settlementAndBillingModuleGetListVersions',
    successMessage: false,
    errorMessage: false
  },
  SETTLEMENT_AND_BILLING_GET_CONCILATION_DAILY: {
    serviceName: 'settlementAndBillingModuleGetConcilationDaily',
    successMessage: false,
    errorMessage: false
  },
  SETTLEMENT_AND_BILLING_GET_CALCULATE_QUERY: {
    serviceName: 'settlementAndBillingModulePutCalculateInvoice',
    successMessage: true,
    errorMessage: true
  },
  SETTLEMENT_AND_BILLING_PUT_FINALIZATION: {
    serviceName: 'settlementAndBillingModulePutFinalization',
    successMessage: true,
    errorMessage: true
  },
  SETTLEMENT_AND_BILLING_PUT_FINANCE_CONTROL_QUERY: {
    serviceName: 'settlementAndBillingModulePutFinanceControl',
    successMessage: true,
    errorMessage: true
  },
  SETTLEMENT_AND_BILLING_GET_OPEN_PARTICIPANT_QUERY: {
    serviceName: 'settlementAndBillingModulePutOpenParticipant',
    successMessage: true,
    errorMessage: true
  },
  TAKASBANK_NOTIFICATION_PAGE_ACTION_HISTORY: {
    serviceName: 'takasbankNotificationPageGetTakasbankActionHistory',
    successMessage: false,
    errorMessage: false
  },
  TAKASBANK_NOTIFICATION_PAGE_ACTION_APPROVE_NOTIFICATION: {
    serviceName: 'takasbankNotificationPagePutTakasbankApproveNotification',
    successMessage: true,
    errorMessage: true
  },
  TAKASBANK_NOTIFICATION_PAGE_ACTION_SEND_NOTIFICATION: {
    serviceName: 'takasbankNotificationPagePutTakasbankSendNotification',
    successMessage: true,
    errorMessage: true
  },
  VIEW_INVOICE_PAGE_GET_INVOICE: {
    serviceName: 'viewInvoiceItemsPageGetInvoice',
    successMessage: false,
    errorMessage: false
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_DELETE_TGH: {
    serviceName: 'withdrawFromTGHAccountsPageDeleteTGH',
    successMessage: false,
    errorMessage: false
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DETAIL_CALCULATE: {
    serviceName: 'withdrawFromTGHAccountsPageGetTGHDetailCalculate',
    successMessage: false,
    errorMessage: false
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DETAIL_DEFAULTER: {
    serviceName: 'withdrawFromTGHAccountsPageGetTGHDetailDefaulter',
    successMessage: false,
    errorMessage: false
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH: {
    serviceName: 'withdrawFromTGHAccountsPageGetTGH',
    successMessage: false,
    errorMessage: false
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DEFAULTER: {
    serviceName: 'withdrawFromTGHAccountsPageGetTGHDefaulter',
    successMessage: false,
    errorMessage: false
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_SAVE: {
    serviceName: 'withdrawFromTGHAccountsPagePostTGHSave',
    successMessage: true,
    errorMessage: true
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_CALCULATED: {
    serviceName: 'withdrawFromTGHAccountsPagePostTGHCalculated',
    successMessage: true,
    errorMessage: true
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_ACTIVE: {
    serviceName: 'withdrawFromTGHAccountsPagePutTGHActivate',
    successMessage: true,
    errorMessage: true
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_PASSIVE: {
    serviceName: 'withdrawFromTGHAccountsPagePutTGHPassive',
    successMessage: true,
    errorMessage: true
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_DELETE_TGH_REFUND: {
    serviceName: 'withdrawFromTGHAccountsPageDeleteTGH',
    successMessage: false,
    errorMessage: false
  },
  GET_ACTIVE_DEFAULTERS: {
    serviceName: 'getActiveDefaulters',
    successMessage: false,
    errorMessage: false
  },

  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_DEFAULTER_REFUND: {
    serviceName: 'withdrawFromTGHAccountsPageGetTGHDefaulterRefund',
    successMessage: false,
    errorMessage: false
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_GET_TGH_REFUND: {
    serviceName: 'withdrawFromTGHAccountsPageGetTGHRefund',
    successMessage: false,
    errorMessage: false
  },
  GET_TGH_REFUND_DETAIL: {
    serviceName: 'getTGHRefundDetail',
    successMessage: false,
    errorMessage: false
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_SAVE_REFUND: {
    serviceName: 'withdrawFromTGHAccountsPagePostTGHSave',
    successMessage: true,
    errorMessage: true
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_POST_TGH_CALCULATED_REFUND: {
    serviceName: 'withdrawFromTGHAccountsPagePostTGHCalculatedRefund',
    successMessage: true,
    errorMessage: true
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_ACTIVE_REFUND: {
    serviceName: 'withdrawFromTGHAccountsPagePutTGHActivate',
    successMessage: true,
    errorMessage: true
  },
  WITHDRAW_FROM_TGH_ACCOUNT_PAGE_PUT_TGH_PASSIVE_REFUND: {
    serviceName: 'withdrawFromTGHAccountsPagePutTGHPassive',
    successMessage: true,
    errorMessage: true
  },
  GET_SYSTEM_HOURS: {
    serviceName: 'systemHours',
    successMessage: false,
    errorMessage: false
  },
  BASE_PRICE_DETAIL_PERIOD: {
    serviceName: 'basePriceDetailContractOrReference',
    successMessage: false,
    errorMessage: false
  },
  PHYSICAL_COLLATERAL_DETAIL: {
    serviceName: 'physicalCollateralDetail',
    successMessage: false,
    errorMessage: false
  },
  GET_POSITION_LIMIT: {
    serviceName: 'applyPositionLimit',
    successMessage: false,
    errorMessage: true
  },
  GET_TEXT_ANNOUNCEMENT_LENGTH: {
    serviceName: 'getAnnouncementLength',
    successMessage: false,
    errorMessage: false
  }
};
