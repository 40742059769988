// Import Utils
import { checkArrayIncludes } from 'src/common/utils/checkArrayIncludes';

// Import Store
import store from 'src/store/store';

const enableRights = process.env.REACT_APP_ENABLE_RIGHTS;

// Check Utils
export const check_permission = (hasRight, hasProfile) => {
  const storeAuthStates = store.getState().authUser;

  // Variables

  let privileges;
  let profiles;

  if (storeAuthStates?.success?.data?.privileges?.length > 0) {
    privileges = storeAuthStates?.success?.data?.privileges;
  } else {
    privileges = [''];
  }

  if (storeAuthStates?.success?.data?.profile) {
    profiles = [storeAuthStates?.success?.data?.profile];
  } else {
    profiles = [''];
  }

  const profilePermission = checkArrayIncludes(hasProfile, profiles);

  const rightPermission =
    enableRights === 'true' && hasRight !== undefined ? checkArrayIncludes(hasRight, privileges) : true;

  if (profilePermission && rightPermission) {
    return true;
  } else {
    return false;
  }
};
