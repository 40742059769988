// Import React
import { ReactNode, ReactElement, Fragment } from 'react';
import { useSelector } from 'react-redux';

// Import Store
import { RootState } from 'src/store/store';

// Import Utils
import { checkArrayIncludes } from 'src/common/utils/checkArrayIncludes';

// const reactAppEkys = process.env.REACT_APP_EKYS

const enableRights = process.env.REACT_APP_ENABLE_RIGHTS;

interface CheckAuthProps {
  children: ReactElement | ReactNode;
  hasRight?: (string | null | undefined | string[])[];
  hasProfile: (string | null | undefined)[];
}

function CheckAuth(props: CheckAuthProps) {
  // Desctruct Props
  const { children, hasProfile, hasRight } = props;

  // Variables
  let privileges;
  let profiles;

  const authUserSuccessStates = useSelector((state: RootState) => state?.authUser.success);

  if (authUserSuccessStates?.data.privileges?.length > 0) {
    privileges = authUserSuccessStates?.data.privileges;
  } else {
    privileges = [''];
  }

  if (authUserSuccessStates?.data?.profile) {
    profiles = [authUserSuccessStates?.data?.profile];
  } else {
    profiles = [''];
  }

  const profilePermission = checkArrayIncludes(hasProfile, profiles);

  const rightPermission =
    enableRights === 'true' && hasRight !== undefined ? checkArrayIncludes(hasRight, privileges) : true;

  if (profilePermission && rightPermission) {
    return <Fragment>{children}</Fragment>;
  } else {
    return null;
  }
}

export default CheckAuth;
