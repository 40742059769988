import * as types from 'src/store/actions/types';

const initialState = {
  isLoading: true,
  isLoadingBySelection: false,
  success: null,
  failed: null,
  update: null
};

// Use the initialState as a default value
export default function contractList(state: any = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case types.CONTRACT_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        success: null,
        failed: null
      };
    case types.CONTRACT_LIST_BY_SELECTION_LOADING:
      return {
        ...state,
        isLoadingBySelection: true
      };
    case types.CONTRACT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingBySelection: false,
        success: action?.payload,
        failed: null
      };
    case types.CONTRACT_LIST_BY_SELECTION_SUCCESS:
      return {
        ...state,
        isLoadingBySelection: false,
        success: {
          ...state?.success,
          data: {
            ...state?.success?.data,
            timestamp: action?.payload?.data?.timestamp,
            data: {
              ...state.success.data.data,
              lastUpdatedDate: action?.payload?.data?.data?.lastUpdatedDate,
              homepageComponents: {
                ...state.success.data.data.homepageComponents,
                UI_CONTRACTMATCHES: action?.payload?.data?.data?.homepageComponents?.UI_CONTRACTMATCHES,
                UI_MARKETMATCHES: action?.payload?.data?.data?.homepageComponents?.UI_MARKETMATCHES,
                UI_MYMATCHINGS: action?.payload?.data?.data?.homepageComponents?.UI_MYMATCHINGS,
                UI_MYORDERS: action?.payload?.data?.data?.homepageComponents?.UI_MYORDERS,
                UI_ORDERBOOK: action?.payload?.data?.data?.homepageComponents?.UI_ORDERBOOK
              }
            }
          }
        }
      };
    case types.OBJECTION_SUCCESS_MY_MATCHING_UPDATE:
      return {
        ...state,
        success: {
          ...state?.success,
          data: {
            ...state?.success?.data,
            data: {
              ...state?.success?.data?.data,
              homepageComponents: {
                ...state?.success?.data?.data?.homepageComponents,
                UI_MYMATCHINGS: {
                  ...state?.success?.data?.data?.homepageComponents?.UI_MYMATCHINGS,
                  ...(state.success.data.data.homepageComponents.UI_MYMATCHINGS.myMatchings[
                    action?.payload?.index
                  ].canObjection = action?.payload?.canObjection)
                }
              }
            }
          }
        }
      };
    case types.CONTRACT_LIST_UPDATE:
      return {
        ...state,
        success: {
          ...state?.success,
          data: {
            ...state?.success?.data,
            data: {
              ...state?.success?.data?.data,
              lastUpdatedDate: action?.payload?.message?.lastUpdatedDate,
              homepageComponents: {
                ...state?.success.data?.data?.homepageComponents,
                UI_CONTRACTS: action?.payload?.message?.homepageComponents?.UI_CONTRACTS?.items
                  ? {
                      ...state?.success?.data?.data?.homepageComponents?.UI_CONTRACTS,
                      ...(state.success.data.data.homepageComponents.UI_CONTRACTS.items[
                        action?.payload?.updatedContracts
                      ].contracts[action?.payload?.updatedList] = action.payload.newList)
                    }
                  : {
                      ...state?.success?.data?.data?.homepageComponents?.UI_CONTRACTS
                    },
                UI_CONTRACTMATCHES: {
                  ...state.success.data?.data?.homepageComponents?.UI_CONTRACTMATCHES,
                  contractMatchings:
                    action?.payload?.isMatchCanceledforContractMatches === false &&
                    action?.payload?.message?.homepageComponents?.UI_CONTRACTMATCHES?.contractMatchings?.length > 0
                      ? [
                          ...action?.payload?.message?.homepageComponents?.UI_CONTRACTMATCHES?.contractMatchings,
                          ...state?.success?.data?.data?.homepageComponents?.UI_CONTRACTMATCHES?.contractMatchings
                        ]
                      : action?.payload?.effectedContractMatchesCancelIndex !== -1
                      ? [...action?.payload?.newContractMatchesData]
                      : state?.success?.data?.data?.homepageComponents?.UI_CONTRACTMATCHES?.contractMatchings
                },
                UI_MARKETMATCHES: {
                  ...state?.success?.data?.data?.homepageComponents?.UI_MARKETMATCHES,
                  marketsMatchings:
                    action?.payload?.isMatchCanceledforMarketMatches === false &&
                    action.payload?.message?.homepageComponents?.UI_MARKETMATCHES?.marketsMatchings?.length > 0
                      ? [
                          ...action.payload?.message?.homepageComponents?.UI_MARKETMATCHES?.marketsMatchings,
                          ...state.success.data.data.homepageComponents?.UI_MARKETMATCHES?.marketsMatchings
                        ]
                      : action?.payload?.effectedMarketMatchesCancelIndex !== -1
                      ? [...action?.payload?.newMarketMatchesData]
                      : state.success.data.data.homepageComponents?.UI_MARKETMATCHES?.marketsMatchings
                },
                UI_MYMATCHINGS: {
                  ...state.success.data.data.homepageComponents.UI_MYMATCHINGS,
                  myMatchings:
                    action?.payload?.affectCurrent &&
                    action?.payload?.isMatchCanceledforContractMatches === false &&
                    action?.payload?.message?.homepageComponents?.UI_MYMATCHINGS?.myMatchings?.length > 0
                      ? [
                          ...action?.payload?.message?.homepageComponents?.UI_MYMATCHINGS?.myMatchings,
                          ...state?.success?.data?.data?.homepageComponents?.UI_MYMATCHINGS?.myMatchings
                        ]
                      : action?.payload?.effectedMyMatchesCancelIndex !== -1
                      ? [...action?.payload?.newMyMatchesData]
                      : state?.success?.data?.data?.homepageComponents?.UI_MYMATCHINGS?.myMatchings
                },
                UI_MYORDERS: {
                  ...state.success.data.data.homepageComponents.UI_MYORDERS,
                  myOrders:
                    action?.payload?.affectCurrent &&
                    action?.payload?.message?.homepageComponents?.UI_MYORDERS?.myOrders?.length > 0
                      ? action?.payload?.message?.homepageComponents?.UI_MYORDERS?.myOrders
                      : state?.success?.data?.data?.homepageComponents?.UI_MYORDERS?.myOrders
                },
                UI_ORDERBOOK: {
                  ...state.success.data.data.homepageComponents.UI_ORDERBOOK,
                  orderBookComponents: {
                    ...state.success?.data?.data?.homepageComponents?.UI_ORDERBOOK?.orderBookComponents,
                    UI_ORDERBOOKCHART:
                      action?.payload?.affectCurrent &&
                      action?.payload?.message?.homepageComponents?.UI_ORDERBOOK?.orderBookComponents
                        ?.UI_ORDERBOOKCHART,
                    UI_ORDERBOOKDETAILS:
                      action?.payload?.affectCurrent &&
                      action?.payload?.message?.homepageComponents?.UI_ORDERBOOK?.orderBookComponents
                        ?.UI_ORDERBOOKDETAILS?.orderBookDetails !== null
                        ? action?.payload?.message?.homepageComponents?.UI_ORDERBOOK?.orderBookComponents
                            ?.UI_ORDERBOOKDETAILS
                        : state?.success?.data?.data?.homepageComponents?.UI_ORDERBOOK?.orderBookComponents
                            ?.UI_ORDERBOOKDETAILS
                  }
                }
              }
            }
          }
        }
      };
    case types.SESSION_CHANGE_UPDATE:
      return {
        ...state,
        success: {
          ...state?.success,
          data: {
            ...state?.success?.data,
            data: {
              ...state?.success?.data?.data,
              homepageComponents: {
                ...state?.success?.data?.data?.homepageComponents,
                UI_CONTRACTS: {
                  ...state?.success?.data?.data?.homepageComponents?.UI_CONTRACTS,
                  items: action?.payload?.sessionChangeContracts
                }
              }
            }
          }
        }
      };
    case types.CONTRACT_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingBySelection: false,
        success: null,
        failed: action.payload
      };
    case types.CONTRACT_LIST_BY_SELECTION_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingBySelection: false,
        success: null,
        failed: action.payload
      };
    default:
      return state;
  }
}
