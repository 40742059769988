export const IsPrp = (userType) => {
  const prpEnv = process.env.REACT_APP_HIDE_ON_PRP === 'true' ? true : false;
  const isUserPK = userType === 'DPK' ? true : false;

  let returnResult = false;

  if (isUserPK === true) {
    // If user DPK --> PK
    if (prpEnv === true) {
      // If user DPK and PRP env is true
      returnResult = true;
    } else {
      // If user EXIST --> Uzman -->
      returnResult = false;
    }
  } else {
    // If user EXIST --> Uzman -->
    returnResult = false;
  }

  return returnResult;
};
