import * as types from 'src/store/actions/types';

const initialState = {
  isLoading: false,
  success: null,
  failed: null
};

// Use the initialState as a default value
export default function getSettlementNoticeResultFiltered(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case types.GET_SETTLEMENT_NOTICE_RESULT_FILTERED_LOADING:
      return {
        ...state,
        isLoading: true,
        success: null,
        failed: null
      };
    case types.GET_SETTLEMENT_NOTICE_RESULT_FILTERED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
        failed: null
      };
    case types.GET_SETTLEMENT_NOTICE_RESULT_FILTERED_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload
      };
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
