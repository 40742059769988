// Import React
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import Utils
import { checkArrayIncludes } from 'src/common/utils/checkArrayIncludes';
import { IsPrp } from 'src/common/utils/prpControl';

// Import Store
import { RootState } from 'src/store/store';

// Import Constants
import { LOADING_TYPES } from 'src/common/constants/loading/loadingTypes';

// Import Components
import FakeJobLogin from 'src/components-new/authentication/fake-login/FakeJobLogin';
import DefaultLayout from 'src/layouts/DefaultLayout';
import Loading from 'src/components-new/loading/Loading';

import 'src/assets/styles/_login.scss';
import TransactionHistoryController from 'src/components-new/authentication/transaction-history-controller/TransactionHistoryController';

function PrivateRoute(props) {
  // Desctruct Props
  const { component: Component, hasProfile, hasRight, redirectUrl, disablePRP, ...rest } = props;

  const authUserSuccessStates = useSelector((state: RootState) => state?.authUser.success);
  const authUserState = useSelector((state: RootState) => state?.authUser);

  const profilePermission =
    process.env.REACT_APP_ENABLE_RIGHTS === 'true'
      ? checkArrayIncludes(hasProfile, [authUserSuccessStates?.data?.profile])
      : true;

  const rightPermission =
    process.env.REACT_APP_ENABLE_RIGHTS === 'true' && hasRight !== undefined
      ? checkArrayIncludes(hasRight, authUserSuccessStates?.data?.privileges)
      : true;

  const prpControl = disablePRP && IsPrp(authUserSuccessStates?.data?.profile) ? true : false;

  // IF ELSE STATEMENTS RETURN
  if (authUserState.isLoading) {
    return <Loading loading={true} loadingType={LOADING_TYPES.FULLSCREEN} />;
  }

  if (authUserState.failed) {
    return <div className="login-failed"></div>;
  }

  if (authUserState.success) {
    return (
      <Route
        {...rest}
        exact
        render={(props) => {
          if (
            prpControl ||
            (!(profilePermission && rightPermission) && process.env.REACT_APP_ENABLE_RIGHTS === 'true')
          ) {
            return <Redirect to={redirectUrl} />;
          }

          return (
            <TransactionHistoryController>
              <DefaultLayout authUserSuccessStates={authUserSuccessStates}>
                <Component {...props} />
              </DefaultLayout>
            </TransactionHistoryController>
          );
        }}
      />
    );
  }

  return (
    <div className="login-wrapper">
      <FakeJobLogin />
    </div>
  );
}

export default PrivateRoute;
