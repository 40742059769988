// Import React
import { useState, useEffect } from 'react';

// Import Utils
import { removeAccents } from 'src/common/utils/stringUtil';

// Import Antd
import { Select as SelectAntd } from 'antd';
import { SelectProps as SelectPropsAntd } from 'antd/lib/select';

// Import Utils
import { translate } from 'src/common/utils/translateUtil';
import { isArray } from 'src/common/utils/arrayUtils';

// Import Components
import FormItem, { FormItemProps } from 'src/components-new/form-elements/form-item/FormItem';

// Interface
export interface SelectProps extends SelectPropsAntd<any> {
  data?: Record<string, React.ReactText>[];
  displaykey?: string;
  valuekey?: string;
  customkey?: string | undefined;
  all?: boolean;
  notAllowClear?: boolean;
  placeholder?: string;
  multipleParams?;
  inputRef?;
  isTranslated?: boolean;
}

type MergedSelectProps = SelectProps & Omit<FormItemProps, 'children'>;

// Import Child Components
const { Option } = SelectAntd;

function Select(props: MergedSelectProps) {
  // Desctruct Props
  const {
    data,
    displaykey,
    valuekey,
    customkey,
    placeholder,
    mode,
    all,
    notAllowClear,
    name,
    onChange,
    multipleParams,
    onClear,
    inputRef,
    isTranslated
  } = props;

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectClear = () => {
    setSelectAll(false);
  };

  const handleSelectAll = (value) => {
    const isArrayResult = isArray(value);
    const formName = name;

    if (isArrayResult) {
      if (value?.includes('all')) {
        multipleParams?.form?.setFieldsValue({
          [`${formName}`]: multipleParams?.data?.map((item) => item?.[`${multipleParams?.valueKey}`])
        });
        setSelectAll(true);
      } else if (value?.includes('clear')) {
        multipleParams.form.resetFields([`${formName}`]);
        setSelectAll(false);
      } else {
        if (value?.length === data?.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
      }
    }
  };

  useEffect(() => {
    if (data) {
      setSelectAll(false);
    }
  }, [data]);

  useEffect(() => {
    if (!multipleParams?.form?.getFieldValue(name)) {
      setSelectAll(false);
    }
  }, [multipleParams?.form?.getFieldValue(name)]);

  return (
    <FormItem {...props}>
      <SelectAntd
        {...props}
        ref={inputRef}
        getPopupContainer={(trigger: any) => trigger?.parentNode} //Scroll Fixed problemi çözümü
        dropdownMatchSelectWidth={true}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filterOption={(input, option: any) => removeAccents(option?.children)?.indexOf?.(removeAccents(input)) >= 0}
        optionFilterProp="children"
        showSearch={true}
        onClear={
          mode === 'multiple'
            ? () => {
                handleSelectClear();
                onClear && onClear();
              }
            : onClear
        }
        className="w-100"
        onChange={
          mode === 'multiple'
            ? (value, option) => {
                handleSelectAll(value);
                onChange && onChange(value, option);
              }
            : onChange
        }
        allowClear={notAllowClear ? false : true}
        placeholder={placeholder ? translate(placeholder.toString()) : translate('GLOBAL.FORM_ELEMENTS.LABELS.SELECT')}
      >
        {all && <Option value={''}>{translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.ALL')}</Option>}
        {mode === 'multiple' && data && data?.length > 1 && (
          <Option value={selectAll ? 'clear' : 'all'}>
            {selectAll
              ? translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.CLEAR')
              : translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.ALL_SELECT')}
          </Option>
        )}
        {data?.length &&
          data?.map((option) => (
            <Option
              value={valuekey ? option?.[valuekey] : option?.id ? option?.id : `${option}`}
              custom={customkey && option?.[customkey] ? option?.[customkey] : ''}
              key={valuekey ? option?.[valuekey] : option.id ? option?.id : `${option}`}
            >
              {isTranslated && displaykey
                ? translate(`GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.${option?.[displaykey]}`)
                : displaykey === 'codename'
                ? `${option?.code} - ${option?.name}`
                : displaykey
                ? option?.[displaykey]
                : option?.name
                ? option?.name
                : option}
            </Option>
          ))}
      </SelectAntd>
    </FormItem>
  );
}

export default Select;
