// Import React
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SockJsClient from 'react-stomp';

// Import Ant
import { Menu, Badge, Dropdown, Layout, Tabs, notification } from 'antd';

// Import Store
import { RootState } from 'src/store/store';
import { announcementHeaderList } from 'src/store/actions/announcement/announcementHeaderList';
import { announcementReadUpdate } from 'src/store/actions/announcement/announcementReadUpdate';
import { notificationHeaderList } from 'src/store/actions/notifications/notificationHeaderList';
import { notificationHeaderRead } from 'src/store/actions/notifications/notificationHeaderRead';
import { getIsVisibleOrderPopUp } from 'src/store/actions/orders/getIsVisibleOrderPopUp';
import { systemHours } from 'src/store/actions/systemHours/systemHours';

// Import Utils
import { translate } from 'src/common/utils/translateUtil';
import { getPageTitle } from 'src/common/utils/getPageTitle';
import { convertToMoment, stampToDate } from 'src/common/utils/dateTimeUtils';

// Import Contants
import { ICON_LIST } from 'src/common/constants/icon/iconList';
import { ICON_STATUS } from 'src/common/constants/icon/iconStatus';
import { STATUS } from 'src/common/constants/status/status';
import { CUSTOM_ICON_SIZES } from 'src/common/constants/icon/iconSizes';
import { CUSTOM_ICON_LIST } from 'src/common/constants/icon/customIconList';
import { BUTTON_SIZES } from 'src/common/constants/sizes/buttonSizes';
import { only_pk } from 'src/common/constants/roles-rights/roles';
import { VGP_ORDER_RIGHT } from 'src/common/constants/roles-rights/rights';

// Import Assets
import epiasLogo from 'src/assets/img/epias-yatay.svg';

// Import Components
import InfoBox from 'src/components-new/box/InfoBox';
import Icon from 'src/components-new/icon/Icon';
import Button from 'src/components-new/cta/button/Button';
import Checkbox from 'src/components-new/form-elements/checkbox/Checkbox';
import TranslatedText from 'src/components-new/translated-text/TranslatedText';
import CustomIcon from 'src/components-new/icon/CustomIcon';
import CheckAuth from 'src/components-new/authentication/check-auth/CheckAuth';

// Desctruct Components
const { Header } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;

function AppHeader(props) {
  // Descturct Props
  const { authUserSuccessStates } = props;

  // Store Variables
  const dispatch = useDispatch();
  const announcementHeaderListStates = useSelector((state: RootState) => state?.globalReducer?.announcementHeaderList);
  const notificationHeaderListStates = useSelector(
    (state: RootState) => state.globalReducer?.notificationHeaderList?.success?.data?.data
  );
  const authUserStates = useSelector((state: RootState) => state.authUser);
  const userOrgId = authUserStates?.success?.data?.detail?.userInfo?.organization?.etsoCode;
  const isVisibleOrderCreatePopUpStates = useSelector((state: RootState) => state?.getIsVisibleOrderPopUp?.success);
  const systemHoursStates = useSelector((state: RootState) => state?.globalReducer?.systemHours);

  //Variables
  const { i18n } = useTranslation();
  const history = useHistory();
  const [headerCounter, setHeaderCounter] = useState(0);
  const [headerTabsVisible, setHeaderTabsVisible] = useState(false);
  const [countdownState, setCountdownState] = useState<string | any>('...');

  // Notification List Clear Action
  const clearNotifications = () => {
    dispatch(notificationHeaderRead());
  };

  // Count Header Notification and Announcement
  const handleHeaderCounter = () => {
    let counter = 0;
    const announcementCount = announcementHeaderListStates?.success?.data?.data?.length | 0;

    const notificationCount = notificationHeaderListStates?.length | 0;

    counter = announcementCount + notificationCount;

    setHeaderCounter(counter);
  };

  // Announcements On Read Action
  const onRead = (e, id) => {
    e?.target?.checked && dispatch(announcementReadUpdate([id]));
  };

  const onLogout = () => {
    localStorage.setItem('counter', `${0}`);
    localStorage.setItem('LastPageUrl', '/');
    window.location.href = `${process.env.REACT_APP_CAS_LOGOUT_URL}`;
  };

  // Announcements and Notifications Tab Menu
  const announcementAndNotificationTabs = (
    <div className="headertabs-wrapper">
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            translate('GLOBAL.LAYOUT.HEADER.ANNOUNCEMENTS_NOTIFICATIONS.TABS.ANNOUNCEMENTS') +
            ` (${announcementHeaderListStates?.success?.data?.data?.length | 0})`
          }
          key="1"
          id="announcement_and_notification_header_tab_1"
        >
          <div className="tabs-container">
            {announcementHeaderListStates?.success?.data?.data?.map((announcement) => (
              <div className="notification-item" key={announcement?.id}>
                <strong className="date">{announcement?.publishedDate}</strong>
                <strong className="title">{announcement?.subject}</strong>
                <p>{announcement?.description}</p>
                <Checkbox
                  name="read_it"
                  onChange={(e) => onRead(e, announcement?.id)}
                  text="GLOBAL.FORM_ELEMENTS.LABELS.CHECK_READ"
                />
              </div>
            ))}
          </div>
          <div className="notification-drop-down-footer">
            <Button
              block={true}
              status={STATUS.BRAND_3}
              label="GLOBAL.FORM_ELEMENTS.CTA.ALL_ANNOUNCEMENTS"
              onClick={() => history.push('/duyurular')}
              name="announcement_all_cta"
            />
          </div>
        </TabPane>

        <TabPane
          tab={
            translate('GLOBAL.LAYOUT.HEADER.ANNOUNCEMENTS_NOTIFICATIONS.TABS.NOTIFICATIONS') +
            ` (${notificationHeaderListStates?.length | 0})`
          }
          key="2"
          id="announcement_and_notification_header_tab_2"
        >
          <div className="tabs-container">
            <ul>
              {notificationHeaderListStates?.map((notification, index) => (
                <li key={`notificationKey${index}`} className="notification-item">
                  {notification?.notificatioContent}
                </li>
              ))}
            </ul>
          </div>
          <div className="notification-drop-down-footer">
            <Button
              block={true}
              status={STATUS.BRAND_3}
              label="GLOBAL.FORM_ELEMENTS.CTA.CLEAR_NOTIFICATIONS"
              onClick={() => clearNotifications()}
              name="notifications_clear_cta"
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );

  const handleOpenOrderPopUp = () => {
    dispatch(getIsVisibleOrderPopUp(!isVisibleOrderCreatePopUpStates));
  };

  // Fetch Announcements List
  useEffect(() => {
    dispatch(announcementHeaderList());
    dispatch(notificationHeaderList());
    dispatch(systemHours());
  }, []);

  // Run Header Counter
  useEffect(() => {
    handleHeaderCounter();
  }, [announcementHeaderListStates, notificationHeaderListStates]);

  // System Timer
  useEffect(() => {
    let date: any = systemHoursStates?.success?.data && stampToDate(systemHoursStates?.success?.data);

    // Update the clock every 1 second
    const timer = setInterval(function () {
      date = date && new Date(date?.getTime() + 1000);

      const result = date && convertToMoment(date)?.utcOffset('+0300').format('HH:mm:ss');

      result ? setCountdownState(result) : setCountdownState('...');
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [systemHoursStates?.success]);

  useEffect(() => {
    localStorage.setItem('language', i18n.language);
  }, [i18n.language]);

  return (
    <Header className="appHeader">
      <SockJsClient
        url={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_NOTIFIER_ADMIN_SERVICE}/notifier-application`}
        topics={[`/topic/notification/${userOrgId}`]}
        onConnect={() => {
          console.log('%c**************** NOTIFICATION Connected ****************', 'color:green');
        }}
        onDisconnect={() => {
          console.log('%c----- NOTIFICATION Disconnected ------', 'color:red');
        }}
        onMessage={(msg) => {
          dispatch(notificationHeaderList());

          notification.success({
            message: null,
            description: msg,
            placement: 'bottomLeft',
            key: 'push-notifications',
            duration: 5
          });
        }}
      />
      <SockJsClient
        url={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_NOTIFIER_ADMIN_SERVICE}/notifier-application`}
        topics={[`/topic/notification/announcement`]}
        onConnect={() => {
          console.log('%c**************** ANNOUNCEMENT Connected ****************', 'color:green');
        }}
        onDisconnect={() => {
          console.log('%c----- ANNOUNCEMENT Disconnected ------', 'color:red');
        }}
        onMessage={(msg) => {
          dispatch(announcementHeaderList());
          console.log('%c ANNOUNCEMENT SOCKET Message: ', 'color:blue', msg);
          notification.success({
            message: msg?.announcement?.subject,
            description: msg?.announcement?.description,
            placement: 'bottomLeft',
            key: `${msg?.announcement?.id}_push-announcements`,
            duration: 5
          });
        }}
      />
      <Link to="/" id="logo_link">
        <img src={epiasLogo} height="40px" alt="Epiaş Logo" />
      </Link>

      {getPageTitle() === 'PAGE_TITLES.HOME' && (
        <CheckAuth hasProfile={only_pk} hasRight={VGP_ORDER_RIGHT}>
          <div className="orderButtonControls">
            {/* ORDER BUTTON */}
            <Button
              status={STATUS.WHITE}
              sizes={BUTTON_SIZES.SM}
              name="order_crate_popup_cta"
              rightIcon={<Icon icon={ICON_LIST.TH} size="sm" status={ICON_STATUS.DANGER} />}
              onClick={handleOpenOrderPopUp}
            />
          </div>
        </CheckAuth>
      )}

      <div className="headerControls">
        {/* INFO BOXES */}
        <InfoBox
          title="GLOBAL.LAYOUT.HEADER.INFOS.PRICE"
          value={translate('GLOBAL.LAYOUT.HEADER.INFOS.SM3TL')}
          dark={true}
        />
        <InfoBox
          title="GLOBAL.LAYOUT.HEADER.INFOS.QUANTITY"
          value={translate('GLOBAL.LAYOUT.HEADER.INFOS.SM3DAY')}
          dark={true}
        />

        <InfoBox title="GLOBAL.LAYOUT.HEADER.INFOS.SYSTEM_CLOCK" value={countdownState} dark={true} />

        {/*  NOTIFICATIONS */}
        <div className="notifications-drop-down-wrapper" id="notificationsDropdown_in_cta">
          <Dropdown
            overlay={announcementAndNotificationTabs}
            trigger={['click']}
            visible={headerTabsVisible}
            onVisibleChange={() => setHeaderTabsVisible((state) => !state)}
          >
            <Badge count={headerCounter || 0} size="small">
              <CustomIcon
                name={CUSTOM_ICON_LIST.BELL}
                size={CUSTOM_ICON_SIZES.MEDIUM}
                status={headerCounter ? ICON_STATUS.DANGER : ICON_STATUS.SECONDARY}
              />
            </Badge>
          </Dropdown>
        </div>

        {/* LANGUAGE CONTROLS */}
        <div className="languageControls">
          <Menu mode="vertical" id="lang_menu">
            <SubMenu
              key="settings"
              icon={
                <CustomIcon
                  name={i18n.language === 'tr' ? CUSTOM_ICON_LIST.TR : CUSTOM_ICON_LIST.UK}
                  size={CUSTOM_ICON_SIZES.MEDIUM}
                />
              }
            >
              <Menu.Item onClick={() => i18n.changeLanguage('tr')} key="set:1" id="lang_tr">
                <div className="flagItem">
                  <CustomIcon name={CUSTOM_ICON_LIST.TR} size={CUSTOM_ICON_SIZES.MEDIUM} />
                  <TranslatedText label="GLOBAL.LAYOUT.HEADER.LANGUAGES.TR" />
                </div>
              </Menu.Item>
              <Menu.Item onClick={() => i18n.changeLanguage('en')} key="set:2" id="lang_uk">
                <div className="flagItem">
                  <CustomIcon name={CUSTOM_ICON_LIST.UK} size={CUSTOM_ICON_SIZES.MEDIUM} />
                  <TranslatedText label="GLOBAL.LAYOUT.HEADER.LANGUAGES.EN" />
                </div>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>

        {/* USER MENU */}
        <Menu mode="horizontal" className="headMenu" id="user_menu">
          <SubMenu key="user" icon={<Icon icon={ICON_LIST.USER_CIRCLE} status={ICON_STATUS.SECONDARY} size="2x" />}>
            <Menu.Item key="user:1" id="user_menu_user_name">
              {authUserSuccessStates ? authUserSuccessStates?.data?.detail?.userInfo?.username : 'Bilinmeyen Kullanıcı'}
            </Menu.Item>
            <Menu.Item key="user:2" id="user_menu_user_limits">
              <TranslatedText label="GLOBAL.LAYOUT.HEADER.PROFILE.USER_LIMITS" />
            </Menu.Item>
            <Menu.Item key="user:3" id="user_menu_user_options">
              <TranslatedText label="GLOBAL.LAYOUT.HEADER.PROFILE.OPTIONS" />
            </Menu.Item>
            <Menu.Item key="user:4" id="user_menu_vgp_keys">
              <TranslatedText label="GLOBAL.LAYOUT.HEADER.PROFILE.VGP_EKYS" />
            </Menu.Item>
            <Menu.Item key="user:5" id="user_menu_user_guide">
              <a
                href="https://www.epias.com.tr/vadeli-dogal-gaz-piyasasi/vgp-kullanim-kilavuzu/"
                target="_blank"
                rel="noreferrer"
              >
                <TranslatedText label="GLOBAL.LAYOUT.HEADER.PROFILE.USER_GUIDE" />
              </a>
            </Menu.Item>
            <Menu.Item key="user:6" id="user_menu_about">
              <a href="http://vgpnedir.com/" target="_blank" rel="noreferrer">
                <TranslatedText label="GLOBAL.LAYOUT.HEADER.PROFILE.ABOUT" />
              </a>
            </Menu.Item>
            <Menu.Item key="user:7" id="user_menu_logout">
              <TranslatedText label="GLOBAL.LAYOUT.HEADER.PROFILE.LOGOUT" onClick={() => onLogout()} />
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </Header>
  );
}

export default AppHeader;
