import * as types from 'src/store/actions/types';
import { notificationHeaderList } from 'src/store/actions/notifications/notificationHeaderList';
import { api } from 'src/store/actions/api';
import { SERVICES } from 'src/store/constants/serviceConstants';

// Başka bir servis ile çalışacak aksiyon burada tanımlanır.
// Bu aksiyon tetiklenir tetiklenmez reducer'a ilgili typelar gönderilir ve store update edilir.
export const notificationHeaderRead = () => (dispatch) => {
  dispatch(
    api('get', SERVICES.NOTIFIER_ADMIN_SERVICE, `/notifier/app/read`, undefined, types.NOTIFICATION_READ, [
      notificationHeaderList()
    ])
  );
};
