// Import Constants
import { STATUS } from 'src/common/constants/status/status';
import { BUTTON_SIZES } from 'src/common/constants/sizes/buttonSizes';

// Import Store
import { SESSION_TIMEOUT_MIN } from 'src/common/constants/user/authConstants';

// Import Components
import Modal from 'src/components-new/modal/Modal';
import Button from 'src/components-new/cta/button/Button';
import TranslatedText from 'src/components-new/translated-text/TranslatedText';

interface LogoutCatchModalProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

function LogoutCatchModal(props: LogoutCatchModalProps) {
  // Desctruct Props
  const { visible, setVisible } = props;

  // Store Variables
  // Functions
  const handleSuccess = () => {
    setVisible(false);
    localStorage.setItem('counter', `${SESSION_TIMEOUT_MIN}`);
  };
  const handleClose = () => {
    setVisible(false);
    localStorage.setItem('counter', `${0}`);
    window.location.href = `${process.env.REACT_APP_CAS_LOGOUT_URL}`;
  };

  return (
    <Modal
      title="GLOBAL.COMPONENTS.MODAL.TITLES.LOGOUT_CATCH"
      wrapClassName="logout_catch"
      visible={visible}
      closable={false}
      footer={[
        <Button
          name="cancel_cta"
          key="cancel"
          label="GLOBAL.FORM_ELEMENTS.CTA.LOGOUT"
          status={STATUS.DANGER}
          sizes={BUTTON_SIZES.MD}
          onClick={() => handleClose()}
          marginright={10}
        />,
        <Button
          name="success_cta"
          key="success"
          label="GLOBAL.FORM_ELEMENTS.CTA.STAY_LOGIN"
          status={STATUS.SUCCESS}
          sizes={BUTTON_SIZES.MD}
          onClick={() => handleSuccess()}
        />
      ]}
    >
      <p>
        <TranslatedText label="GLOBAL.PAGES.LAYOUT.LOGOUT_CATCH_MODAL.DESCRIPTION" />
      </p>
    </Modal>
  );
}

export default LogoutCatchModal;
