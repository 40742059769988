// Import React
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

// Import Utils
import AuthControl from './components-new/authentication/auth-control/AuthControl';

// Import Components
import App from './App';
import './common/locales/i18n';

// import reportWebVitals from './reportWebVitals';
import store from './store/store';

ReactDOM.render(
  <StrictMode>
    {/* Provider aracılığı ile store'da tuttuğumuz statlerimize applicationın bütün componentlerinden erişebiliriz. */}
    <Provider store={store}>
      {/* Client url isteği yaptığında ilk olarak buradaki browserrouter dan geçer ve gerekli routing yapılır. */}
      <BrowserRouter>
        <AuthControl>
          <App />
        </AuthControl>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
