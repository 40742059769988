import {
  faBars,
  faLongArrowAltRight,
  faArrowRight,
  faBell,
  faCog,
  faUser,
  faUserCircle,
  faAddressCard,
  faChartBar,
  faHome,
  faBriefcase,
  faFileAlt,
  faCoins,
  faBullhorn,
  faScroll,
  faUsersCog,
  faEdit,
  faWindowClose,
  faCheckSquare,
  faFileExcel,
  faFileExport,
  faFileDownload,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faSearch,
  faChartArea,
  faEye,
  faCopy,
  faCommentDots,
  faPlus,
  faUpload,
  faExclamationCircle,
  faExchangeAlt,
  faFlag,
  faInfoCircle,
  faSlidersH,
  faArrowLeft,
  faCheck,
  faAngleRight,
  faSignOutAlt,
  faCalculator,
  faTh
} from '@fortawesome/free-solid-svg-icons';
// import {  } from '@fortawesome/free-brands-svg-icons';
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons';

export const ICON_LIST = {
  BARS: faBars,
  LONG_ARROW_RIGHT: faLongArrowAltRight,
  ARROW_RIGHT: faArrowRight,
  ARROW_LEFT: faArrowLeft,
  BELL: faBell,
  COG: faCog,
  USER: faUser,
  USER_CIRCLE: faUserCircle,
  ADDRESS_CARD: faAddressCard,
  CHART_BAR: faChartBar,
  CHART_AREA: faChartArea,
  HOME: faHome,
  BRIEFCASE: faBriefcase,
  FILE_ALT: faFileAlt,
  COINS: faCoins,
  BULLHORN: faBullhorn,
  SCROLL: faScroll,
  USERS_COG: faUsersCog,
  EDIT: faEdit,
  CLOSE: faWindowClose,
  CHECK: faCheckSquare,
  CHECK_DEFAULT: faCheck,
  EXCEL: faFileExcel,
  EXPORT: faFileExport,
  DOWNLOAD: faFileDownload,
  CARET_DOWN: faCaretDown,
  CARET_UP: faCaretUp,
  CARET_LEFT: faCaretLeft,
  CARET_RIGHT: faCaretRight,
  SEARCH: faSearch,
  EYE: faEye,
  COPY: faCopy,
  COMMENTS: faCommentDots,
  PLUS: faPlus,
  MINUS_SQUARE: faMinusSquare,
  UPLOAD: faUpload,
  EXCLAMATION: faExclamationCircle,
  SWAP: faExchangeAlt,
  FLAG: faFlag,
  INFO: faInfoCircle,
  SLIDERS: faSlidersH,
  ANGEL_RIGHT: faAngleRight,
  LOGOUT: faSignOutAlt,
  CALCULATOR: faCalculator,
  TH: faTh
};
