import * as types from 'src/store/actions/types';

const initialState = {
  isLoading: false,
  success: null,
  failed: null
};

export default function orderSearch(state: any = initialState, action) {
  switch (action.type) {
    case types.ORDER_SEARCH_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case types.ORDER_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
        failed: null
      };
    case types.ORDER_SEARCH_UPDATE:
      return {
        ...state,
        success: {
          ...state.success,
          data: {
            ...state.success.data,
            data: {
              ...state.success.data.data,
              data: action.payload
            }
          }
        }
      };
    case types.ORDER_SEARCH_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload
      };
    default:
      return state;
  }
}
