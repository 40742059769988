import * as React from 'react';

function SvgBell(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="1em" height="1em" {...props}>
      <path
        d="M464.14 11.04C256.89 25.61 78.06 174.07 25.69 375.38c-20.5 79.04-21 166.98-.99 245.28C37.54 672.29 62 726.88 91.64 771.09c157.84 236.14 478.71 290.24 705.46 118.81 25.94-19.51 73.12-66.69 92.63-92.63 146.48-193.66 130.92-462.41-36.56-636.55C751.66 55.01 610.61.91 464.14 11.04zm50.88 262.57c4.69 3.21 9.14 8.89 10.37 13.09 2.22 7.16 3.21 7.9 16.3 9.14 42.49 3.95 79.29 33.59 97.08 77.81 5.43 13.59 5.68 17.54 6.92 114.12.99 55.08 1.73 100.29 1.97 100.54 13.09 15.07 16.55 18.03 21.99 18.03 7.66 0 15.56 3.95 21 10.87 3.21 3.71 4.45 9.88 4.45 22.97v18.03H307.3v-17.54c0-16.55.49-18.03 8.4-25.94 5.68-5.68 10.62-8.4 15.56-8.4 8.15 0 15.81-6.17 22.23-18.03 3.95-7.17 4.69-18.53 5.68-106.71 1.48-108.19 1.48-107.45 17.54-132.4 19.27-30.38 58.3-54.1 88.68-54.1 8.4 0 9.39-.74 13.09-9.88 2.72-7.16 6.18-11.12 12.1-13.59 11.1-4.92 14.81-4.68 24.44 1.99zm36.8 414.24c0 7.41-6.67 22.48-13.09 29.15-14.82 16.06-41.25 20.01-61.26 9.63-11.86-6.42-23.22-21.99-25.94-35.82l-1.48-7.91h50.88c49.41.01 50.89.26 50.89 4.95z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBell;
