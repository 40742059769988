import * as types from 'src/store/actions/types';

const initialState = {
  isLoading: false,
  success: null,
  failed: null,
  apply_failed: null
};

export default function getPositionLimit(state = initialState, action) {
  switch (action.type) {
    case types.GET_POSITION_LIMIT_LOADING:
      return {
        ...state,
        isLoading: true,
        failed: null,
        apply_failed: null
      };
    case types.GET_POSITION_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
        failed: null,
        apply_failed: null
      };
    case types.GET_POSITION_LIMIT_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload,
        apply_failed: null
      };
    case types.APPLY_POSITION_LIMIT_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: null,
        apply_failed: action.payload
      };
    default:
      return state;
  }
}
