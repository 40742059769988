import * as types from 'src/store/actions/types';
import { announcementHeaderList } from 'src/store/actions/announcement/announcementHeaderList';
import { announcementList } from 'src/store/actions/announcement/announcementList';
import { api } from 'src/store/actions/api';
import { SERVICES } from 'src/store/constants/serviceConstants';

export const announcementReadUpdate = (ids) => (dispatch) => {
  dispatch(
    api('put', SERVICES.NOTIFIER_ADMIN_SERVICE, `/api/announcement/read`, ids, types.ANNOUNCEMENT_READ_UPDATE, [
      announcementHeaderList(),
      announcementList()
    ])
  );
};
