// Constant Date - Time Formats
export const dateFormat = 'DD.MM.YYYY';
export const dateTimeFormat = 'DD.MM.YYYY HH:mm';
export const dateTimeSecondFormat = 'DD.MM.YYYY HH:mm:ss';
export const dateTimeSecondMiliFormat = 'DD.MM.YYYY HH:mm:ss:SSS';
export const dateHour = 'HH';
export const timeFormat = 'HH:mm';
export const timeSecondFormat = 'HH:mm:ss';
export const monthYearFormat = 'YYYY.MM';
export const monthFormat = 'MM';
export const monthFormatToBackend = 'YYYY-MM';
//
export const dateFormatBackend = 'YYYY-MM-DD';
export const dateFormatForOrderCreateUtil = 'DD-MM-YYYY';
export const dateTimeFormatBackend = 'YYYY-MM-DD-HH-mm';
export const dateTimeMiliSecondFormatBackend = 'YYYY-MM-DD-HH-mm-ss-SSS';
//
export const dateTimeSecondFormatFileName = 'YYYYMMDDHHmm';
