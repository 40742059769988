// Import Constants
import { TEXT_WEIGHT } from 'src/common/constants/text/textWeight';

// Import Assets
import 'src/assets/styles/_infoBox.scss';

// Import Components
import TranslatedText from 'src/components-new/translated-text/TranslatedText';

interface InfoBoxProps {
  title: string;
  value: string;
  dark?: boolean;
}

export default function InfoBox(props: InfoBoxProps) {
  // Destruct Props
  const { title, value, dark } = props;

  return (
    <div
      className={
        dark
          ? 'info-box-contents-dark ant-statistic margin_left_5 margin_right_5'
          : 'info-box-contents ant-statistic margin_left_5 margin_right_5'
      }
    >
      <div className="ant-statistic-title">
        <TranslatedText label={title} weight={TEXT_WEIGHT.SEMIBOLD} />
      </div>
      <div className="ant-statistic-content">{value}</div>
    </div>
  );
}
