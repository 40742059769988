import { baseApi } from 'src/store/actions/baseApi';
import { SESSION_TIMEOUT_MIN } from 'src/common/constants/user/authConstants';
import { SERVICES } from 'src/store/constants/serviceConstants';
import { STORE_CONFIG } from 'src/store/storeConfig';
import {
  errorDecoderPushNotification,
  successDecoderPushNotification,
  defaultErrorPushNotification
} from 'src/common/utils/errorDecoder';
import i18n from 'src/common/locales/i18n';

export const api = (
  method: 'get' | 'post' | 'put' | 'delete',
  service: SERVICES,
  url: string,
  params: Record<string, unknown> | string | FormData | undefined,
  requestName: string,
  triggerActions?: Array<void | ((dispatch: any) => void) | Record<string, unknown>> | undefined
) => (dispatch) => {
  dispatch({ type: `${requestName}_LOADING`, requestName });

  // Reset Session Timeout
  localStorage.setItem('counter', `${SESSION_TIMEOUT_MIN}`);

  // Notification Mapping
  const notificationStatusObject: Record<string, boolean | string> = STORE_CONFIG?.[requestName];

  const pageName = localStorage.getItem('PageName');
  const moduleName = localStorage.getItem('ModuleName');

  baseApi({
    method,
    url: `${service}${url}`,
    headers: {
      Authorization: `Bearer ${sessionStorage?.getItem('jwtToken')}`,
      PageName: pageName,
      ModuleName: moduleName,
      UserLanguage: `${i18n?.language}`
    },
    data: method === 'get' ? undefined : params,
    params: method === 'get' ? params : undefined
  })
    .then((res) => {
      dispatch({
        type: `${requestName}_SUCCESS`,
        payload: res,
        requestName
      });
    })
    .then(() => {
      if (triggerActions) {
        for (let i = 0; i < triggerActions.length; i++) {
          dispatch(triggerActions[i]);
        }
      }
      if (notificationStatusObject?.successMessage) {
        successDecoderPushNotification('SUCCESS', `${requestName}_SUCCESS`);
      }
    })
    .catch((err) => {
      dispatch({
        type: `${requestName}_FAIL`,
        payload: err,
        requestName
      });
      if (notificationStatusObject?.errorMessage) {
        const errorCode = err?.response?.data?.errors?.[0]?.message;
        const errorMessage = err?.response?.data?.errors?.[0]?.field;
        const errorKey = `${requestName}_FAIL`;

        if (errorCode === 'SHOW_MESSAGE') {
          if (errorMessage) {
            defaultErrorPushNotification(errorMessage, errorKey);
          } else {
            defaultErrorPushNotification(undefined, errorKey);
          }
        } else {
          errorDecoderPushNotification(errorCode, errorKey);
        }
      }
    });
};
