// Import Icons
import { ArrowLeft, ArrowRight, Edit, Tr, Uk, Bell } from 'src/components-new/Icons';

export const CUSTOM_ICON_LIST = {
  ARROW_LEFT: ArrowLeft(),
  ARROW_RIGHT: ArrowRight(),
  EDIT: Edit(),
  TR: Tr(),
  UK: Uk(),
  BELL: Bell()
};
