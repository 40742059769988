// Import React
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

// Import Components
import { Button as ButtonAntd } from 'antd';
import { ButtonProps as ButtonPropsAntd } from 'antd/lib/button';

// Import Constants
import { BUTTON_SIZES } from 'src/common/constants/sizes/buttonSizes';
import { STATUS } from 'src/common/constants/status/status';
import { MARGIN_SIZES } from 'src/common/constants/sizes/marginSizes';

//Import Components
import TranslatedText from 'src/components-new/translated-text/TranslatedText';
import FormItem from 'src/components-new/form-elements/form-item/FormItem';

// Import Styles
import 'src/assets/styles/buttons.scss';

export interface ButtonProps extends Omit<ButtonPropsAntd, 'size'> {
  status?: STATUS | undefined;
  label?: string | undefined;
  customLabel?: string | React.ReactElement | ReactNode | undefined;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  className?: string | undefined;
  sizes?: BUTTON_SIZES | undefined;
  block?: boolean;
  marginright?: MARGIN_SIZES;
  marginleft?: MARGIN_SIZES;
  margintop?: MARGIN_SIZES;
  marginbottom?: MARGIN_SIZES;
  isform?: boolean;
  name: string;
  push?: string;
  disabled?: boolean;
}

function Button(props: ButtonProps) {
  // Desctruct Props
  const {
    label,
    customLabel,
    status,
    icon,
    rightIcon,
    className = '',
    sizes,
    block,
    marginright,
    marginleft,
    margintop,
    marginbottom,
    isform,
    name,
    push,
    onClick,
    disabled
  } = props;

  // Variables
  const history = useHistory();

  const buttonTemplate = (
    <div className={block ? `full-wrap` : 'inline-wrap'}>
      <ButtonAntd
        {...props}
        disabled={disabled}
        id={name}
        onClick={push ? () => history?.push(push) : onClick}
        className={
          status
            ? `btn-${status} ${className} ${sizes} 
          margin_right_${marginright} margin_left_${marginleft} margin_top_${margintop} margin_bottom_${marginbottom}`
            : `${className} 
          margin_right_${marginright} margin_left_${marginleft} margin_top_${margintop} margin_bottom_${marginbottom}`
        }
        icon={
          !rightIcon && icon && <span className={`btn-icon ${(label || customLabel) && 'margin_right_5'}`}>{icon}</span>
        }
      >
        {label ? <TranslatedText label={label} /> : ''}
        {customLabel && <span>{customLabel}</span>}
        {rightIcon && <span className={`btn-icon ${label && 'margin_left_5'}`}>{rightIcon}</span>}
      </ButtonAntd>
    </div>
  );

  return <>{isform ? <FormItem name={name}>{buttonTemplate}</FormItem> : buttonTemplate}</>;
}
export default Button;

Button.defaultProps = {
  block: false,
  sizes: BUTTON_SIZES.MD
};
