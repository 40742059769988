import * as types from 'src/store/actions/types';

const initialState = {
  isLoading: false,
  success: null,
  import_epdk_fail: null,
  import_market_position_limit_fail: null,
  failed: null
};

export default function marketPositionLimit(state = initialState, action) {
  switch (action.type) {
    case types.MARKET_POSITION_LIMIT_LOADING:
      return {
        ...state,
        isLoading: true,
        failed: null,
        import_epdk_fail: null,
        import_market_position_limit_fail: null
      };
    case types.MARKET_POSITION_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
        failed: null,
        import_epdk_fail: null,
        import_market_position_limit_fail: null
      };
    case types.MARKET_POSITION_LIMIT_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload,
        import_epdk_fail: null,
        import_market_position_limit_fail: null
      };
    case types.IMPORT_EPDK_FAIL:
      return {
        ...state,
        isLoading: false,
        failed: null,
        import_epdk_fail: action.payload
      };
    default:
      return state;
  }
}
