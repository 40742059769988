import * as types from 'src/store/actions/types';
import { announcementList } from 'src/store/actions/announcement/announcementList';
import { api } from 'src/store/actions/api';
import { SERVICES } from 'src/store/constants/serviceConstants';

export const announcementHeaderList = () => (dispatch) => {
  dispatch(
    api(
      'get',
      SERVICES.NOTIFIER_ADMIN_SERVICE,
      `/api/announcement/find-all`,
      undefined,
      types.ANNOUNCEMENT_HEADER_LIST,
      [announcementList()]
    )
  );
};
