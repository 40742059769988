import * as types from 'src/store/actions/types';

const initialState = {
  isLoading: false,
  success: [],
  failed: null
};

export default function createBatchOrder(state: any = initialState, action) {
  switch (action.type) {
    case types.CREATE_BATCH_ORDER_LOADING:
      return {
        ...state,
        isLoading: true,
        success: [],
        failed: null
      };
    case types.CREATE_BATCH_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: action.payload,
        failed: null
      };
    case types.CREATE_BATCH_ORDER_UPDATE_BATCH:
      return {
        ...state,
        isLoading: false,
        success: {
          ...state?.success,
          data: {
            ...state?.success?.data,
            data: action.payload
          }
        }
      };
    case types.CREATE_BATCH_ORDER_FAIL:
      return {
        ...state,
        isLoading: false,
        success: null,
        failed: action.payload
      };
    default:
      return state;
  }
}
