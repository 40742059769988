// Import Constants
import { TEXT_TRASFORM } from 'src/common/constants/text/textTransform';

// Import Utils
import { getPageTitle } from 'src/common/utils/getPageTitle';

// Import Components
import TranslatedText from 'src/components-new/translated-text/TranslatedText';

// Import Assets
import 'src/assets/styles/_title.scss';
export interface PageTitleProps {
  icon?: React.ReactElement;
  textTransform?: TEXT_TRASFORM;
  subTitle?: string;
  subTitleValue?: string | number | React.ReactElement;
}

function PageTitle(props: PageTitleProps) {
  // Desctruct Props
  const { icon, textTransform, subTitle, subTitleValue } = props;

  const getTitle = getPageTitle();

  return getTitle !== 'PAGE_TITLES.HOME' ? (
    <div className="page-title">
      <h1 className="title">
        {icon}
        {<TranslatedText label={getTitle} textTransform={textTransform} />}
      </h1>
      {(subTitle?.length || subTitleValue) && (
        <span className="sub-title">
          <TranslatedText label={subTitle} /> {subTitleValue}
        </span>
      )}
    </div>
  ) : null;
}

export default PageTitle;
