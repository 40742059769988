import * as React from 'react';

function SvgEdit(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 21 21" {...props}>
      <g stroke="currentColor" fill="none" fillRule="evenodd">
        <path d="M7.274 14a.32.32 0 01-.228-.095c-.083-.082-.114-.203-.083-.315l.715-2.554a.317.317 0 01.083-.14l9.556-9.558c.216-.216.507-.339.798-.339.265 0 .51.1.692.28l.864.865a.968.968 0 01.279.75 1.14 1.14 0 01-.337.739l-9.557 9.557a.317.317 0 01-.141.083l-2.554.715a.32.32 0 01-.087.012zM16 2.965l2 2" />
        <path strokeLinecap="round" d="M19 7.965v10a2 2 0 01-2 2H3a2 2 0 01-2-2v-14a2 2 0 012-2h10" />
      </g>
    </svg>
  );
}

export default SvgEdit;
