// Import Constants
import { STATUS } from 'src/common/constants/status/status';

// Import Assets
import notFoundImage from 'src/assets/img/err_404.svg';

// Import Components
import Button from 'src/components-new/cta/button/Button';
import TranslatedText from 'src/components-new/translated-text/TranslatedText';

function InvalidAuthorization() {
  const onLogout = () => {
    localStorage.setItem('counter', `${0}`);
    localStorage.setItem('LastPageUrl', '/');
    window.location.href = `${process.env.REACT_APP_CAS_LOGOUT_URL}`;
  };

  return (
    <div className="errorPageWrapper">
      <img src={notFoundImage} alt="404 Page Not Found" />
      <p className="margin_bottom_10">
        <TranslatedText label="GLOBAL.PAGES.INVALID_AUTHORIZATION.DESCRIPTION" />
      </p>

      <Button
        name="back_to_logout_cta"
        status={STATUS.PRIMARY}
        htmlType="submit"
        onClick={() => onLogout()}
        label="GLOBAL.FORM_ELEMENTS.CTA.BACK_TO_LOGIN"
      />
    </div>
  );
}

export default InvalidAuthorization;
