import * as React from 'react';

function SvgArrowLeft(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0h48v1H0z" fill="#063855" fillRule="evenodd" />
    </svg>
  );
}

export default SvgArrowLeft;
