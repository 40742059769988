import axios from 'axios';
import { SESSION_TIMEOUT_MIN } from 'src/common/constants/user/authConstants';

import * as types from 'src/store/actions/types';

// Import Utils
import { errorDecoderPushNotification } from 'src/common/utils/errorDecoder';

const CAS_LOGIN_URL = process.env.REACT_APP_CAS_LOGIN_URL;
const RETURN_URL = process.env.REACT_APP_RETURN_URL;

export const authUser = (dispatch, ticket) => {
  dispatch({ type: types.AUTH_LOADING });

  // Reset Session Timeout
  localStorage.setItem('counter', `${SESSION_TIMEOUT_MIN}`);

  const config = {
    withCredentials: false
  };

  axios
    .get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_REGISTRATION_SERVICE}/auth/validateTicket/${ticket}`,
      config
    )
    .then((res) => {
      dispatch({
        type: types.AUTH_SUCCESS,
        payload: res
      });

      const url = window.location.toString();
      if (url.indexOf('?') > 0) {
        const cleanUrl = url.substring(0, url.indexOf('?'));
        window.history.replaceState({}, document.title, cleanUrl);
      }
    })
    .catch((err) => {
      dispatch({
        type: types.AUTH_FAIL,
        payload: err
      });
      localStorage.setItem('LastPageUrl', '/');

      if (err?.response?.data?.errorCode === 'USER_NOT_FOUND') {
        errorDecoderPushNotification(err?.response?.data?.errorCode, 'user_not_found');
        window.location.href = `${process.env.REACT_APP_RETURN_URL}/kullanici-bulunamadi`;
      } else if (err?.response?.data?.errorCode === 'INVALID_AUTHORIZATION') {
        errorDecoderPushNotification(err?.response?.data?.errorCode, 'invalid_authorization');
        window.location.href = `${process.env.REACT_APP_RETURN_URL}/yetkisiz-kullanici`;
      } else if (err?.response?.data?.errorCode === 'USER_ORGANISATION_NOT_FOUND') {
        errorDecoderPushNotification(err?.response?.data?.errorCode, 'organisation_not_found');
        window.location.href = `${process.env.REACT_APP_RETURN_URL}/gecersiz-organizasyon`;
      } else if (err?.response?.data?.errorCode === 'GENERAL_ERROR') {
        errorDecoderPushNotification(err?.response?.data?.errorCode, 'general_exeption');
        window.location.href = `${process.env.REACT_APP_RETURN_URL}/kullanici-bulunamadi`;
      } else {
        window.location.href = `${CAS_LOGIN_URL}?service=${RETURN_URL}`;
      }
    });
};
