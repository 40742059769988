import i18n from 'src/common/locales/i18n';

export function translate(path: string, params?: string[]) {
  let result = i18n.t(path);

  if (params) {
    params?.forEach((param, paramIndex) => {
      result = result?.toString()?.replace(`{${paramIndex + 1}}`, param);
    });
  }

  return `${result}`;
}
