// Import React
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import Store
import { transactionHistoryPageChange } from 'src/store/actions/markets_backend_service/transaction-history/transactionHistoryPageChange';

// Import Constants
import { MENU } from 'src/layouts/sidebar/menu';
import { LOADING_TYPES } from 'src/common/constants/loading/loadingTypes';

// Import Components
import Loading from 'src/components-new/loading/Loading';

function TransactionHistoryController({ children }) {
  // Page and Module
  const dispatch = useDispatch();
  const location = useLocation();

  const [changeState, setChangeState] = useState(true);

  let moduleName = '';
  let pageName = '';

  const pageAndModuleNameSend = () => {
    const pathhName = location?.pathname;

    MENU?.map((menuItem) => {
      // hasnot children
      if (!menuItem?.children) {
        if (pathhName === menuItem?.url) {
          moduleName = menuItem?.parentenum;
          pageName = menuItem?.enum;
        }
      } else {
        // has children
        menuItem?.children?.map((childItem) => {
          if (pathhName === childItem?.url) {
            moduleName = menuItem?.enum;
            pageName = childItem?.enum;
          }
        });
      }
    });

    localStorage.setItem('PageName', `${pageName}`);
    localStorage.setItem('ModuleName', `${moduleName}`);
  };

  useEffect(() => {
    setChangeState(false);
  }, [pageName || moduleName]);

  useEffect(() => {
    pageAndModuleNameSend();
    dispatch(transactionHistoryPageChange(moduleName, pageName));
  }, [location.pathname]);

  if (changeState) {
    return <Loading loading={true} loadingType={LOADING_TYPES.FULLSCREEN} />;
  } else {
    return !changeState && children;
  }
}

export default TransactionHistoryController;
